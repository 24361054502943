import { useMemo } from 'react'
import {
    Avatar,
    AvatarGroup,
    Icon,
} from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { useGetTeamsQuery } from '../../apis/org-chart'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'

export const Teams = () => {
    const { data: teams = [], isLoading: isLoadingTeams } = useGetTeamsQuery()

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Members',
            Cell: ({ row }) => (
                <AvatarGroup size='sm' spacing={-1} max={6}>
                    {row.original.members.map((member) => (
                        <Avatar key={member.user.id} name={member.user.first_name + ' ' + member.user.last_name} src={member.user.slack_avatar_url} />
                    ))}
                </AvatarGroup>
            ),
            id: 'members',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ], [])

  return (
    isLoadingTeams ? (
        <LoadingSpinner />
    ) : (
        <DataTable
            data={teams}
            columns={columns}
            title='Teams'
            description='Teams are sourced from GitHub, and membership is automatically synced from GitHub to the DevOps Portal. Team leads,
            however, are unique to the DevOps Portal. Team leads approve access to any resources that team owns.'
            sortByField='name'
            sortDesc={false}
        />
    )
  )
}
