import { createApi } from '@reduxjs/toolkit/query/react'
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from './retry'

export const apiKeyPortalApi = createApi({
  reducerPath: 'apiKeyPortalApi',
  baseQuery: baseQueryWithUnAuthRetry(config.apiKeyPortalApiUrl),
  tagTypes: ['ApiKey'],
  endpoints: (builder) => ({
    listAPIKeys: builder.query({
      query: () => '/api-keys',
      providesTags: ['ApiKey']
    }),
    getAPIKeyById: builder.query({
      query: ({ id, keyid }) => `/consumers/${id}/api-keys/${keyid}`,
      providesTags: (result, error, arg) => [{ type: 'ApiKey', id: arg }]
    }),
    createAPIKey: builder.mutation({
      query: ({ id, body }) => ({
        url: `/consumers/${id}/api-keys`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ApiKey' },
        { type: 'Consumer', id }
      ]
    }),
    deleteAPIKey: builder.mutation({
      query: ({ id, keyid }) => ({
        url: `/consumers/${id}/api-keys/${keyid}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ApiKey' },
        { type: 'Consumer', id }
      ]
    }),
    listConsumers: builder.query({
      query: () => '/consumers',
      providesTags: ['Consumer']
    }),
    getConsumerById: builder.query({
      query: (id) => `/consumers/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Consumer', id: arg }]
    }),
    createConsumer: builder.mutation({
      query: (body) => ({
        url: '/consumers',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Consumer']
    }),
    deleteConsumer: builder.mutation({
      query: (id) => ({
        url: `/consumers/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Consumer']
    }),
    createACL: builder.mutation({
      query: ({ id, body }) => ({
        url: `/consumers/${id}/acls`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ACL' },
        { type: 'Consumer', id }
      ]
    }),
    deleteACL: builder.mutation({
      query: ({ id, aclid }) => ({
        url: `/consumers/${id}/acls/${aclid}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ACL' },
        { type: 'Consumer', id }
      ]
    }),
    updateRequestTransformer: builder.mutation({
      query: ({ id, body }) => ({
        url: `/consumers/${id}/transformer`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['Consumer']
    }),
    listClusters: builder.query({
      query: () => '/clusters',
      providesTags: ['Clusters']
    })
  })
})

export const {
  useListAPIKeysQuery,
  useGetAPIKeyByIdQuery,
  useCreateAPIKeyMutation,
  useDeleteAPIKeyMutation,
  useListConsumersQuery,
  useGetConsumerByIdQuery,
  useCreateConsumerMutation,
  useDeleteConsumerMutation,
  useCreateACLMutation,
  useDeleteACLMutation,
  useUpdateRequestTransformerMutation,
  useListClustersQuery,
} = apiKeyPortalApi
