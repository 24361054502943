import { useState, useMemo, useCallback } from 'react'
import { useGetAllRequestsQuery, useGetCredentialsMutation } from '../../apis/db-access-provider'
import { useGetNamespacesQuery, useGetRequestsQuery as useGetVaultRequestsQuery } from '../../apis/vault-access-provider'
import { useCancelRequestMutation } from '../../apis/approval-engine'
import { useGetDatabasesQuery, useGetInstancesQuery } from '../../apis/database-builder'
import {
    Button,
    ButtonGroup,
    HStack,
    Icon,
    IconButton,
    Stack,
    Text,
    Tooltip,
    VStack,
    useToast,
    useColorModeValue,
    Code,
} from '@chakra-ui/react'
import { FiInfo, FiKey, FiXCircle } from 'react-icons/fi'
import { NewCredentialsModal } from '../../components/NewCredentialsModal'
import { PgPassModal } from '../../components/PgPassModal'
import { Link } from 'react-router-dom'
import { DataTable } from '../../components/DataTable'
import { iconPropsFromStatus } from '../../utils/status'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { AreYouSure } from '../../components/AreYouSure'


const request_type_database = 'Database'
const request_type_vault = 'Vault'

export const MyAccess = () => {
  const toast = useToast()
  const { data: dbAccess = [], isLoading: isLoadingDBAccess, refetch: refetchDBAccess } = useGetAllRequestsQuery()
  const { data: vaultAccess = [], isLoading: isLoadingVaultAccess, refetch: refetchVaultAccess } = useGetVaultRequestsQuery()
  const [getCredentials, { data: credentials = {} }] = useGetCredentialsMutation()
  const [deleteAccessRequest] = useCancelRequestMutation()

  const { data: namespaces = [], isLoading: isLoadingNamespaces } = useGetNamespacesQuery()
  const { data: databases = [], isLoading: isLoadingDatabases } = useGetDatabasesQuery()
  const { data: instances = [], isLoading: isLoadingInstances } = useGetInstancesQuery()

  const [showCredentialsModal, setShowCredentialsModal] = useState(false)
  const [credentialsLoading, setCredentialsLoading] = useState('')
  const [pgpassCreds, setPgpassCreds] = useState({ message: '', failed: [], ready: false })
  const [pgpassLoading, setPgpassLoading] = useState(false)
  const [cancelling, setCancelling] = useState('')

  const [areYouSureData, setAreYouSureData] = useState(null)

  const buttonColorScheme = useColorModeValue('blue', 'green')

  const instancesMap = useMemo(() => {
    const instancesMap = {}
    instances.forEach(inst => {
      instancesMap[inst.id] = inst
    })
    return instancesMap
  }, [instances])

  const databasesMap = useMemo(() => {
    const databasesMap = {}
    databases.forEach(db => {
      databasesMap[db.id] = db
    })
    return databasesMap
  }, [databases])

  const namespacesMap = useMemo(() => {
    const namespacesMap = {}
    namespaces.forEach(ns => {
      namespacesMap[ns.id] = ns
    })
    return namespacesMap
  }, [namespaces])

  const access = useMemo(() => {
    const all = []
    dbAccess.forEach((d) => all.push({ request_type: request_type_database, ...d }))
    vaultAccess.forEach((v) => all.push({ request_type: request_type_vault, ...v }))
    return all
  }, [dbAccess, vaultAccess])

  const getCredentialsForAccessRequest = useCallback(async (reqID) => {
    setCredentialsLoading(reqID)
    const resp = await getCredentials(reqID)
    if (resp.error) {
      toast({
        title: 'Failed to generate new credentials.',
        description: resp.error.data.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
      setShowCredentialsModal(false)
    } else {
      setShowCredentialsModal(true)
    }
    setCredentialsLoading('')
  }, [getCredentials, toast])

  const generatePGPASSFile = async () => {
    setPgpassLoading(true)
    let msg = ''
    const failed = []
    for (const i in access) {
      if (access[i].request_type === request_type_database && access[i].status === 'approved') {
        try {
          const creds = await getCredentials(access[i].id)
          if (creds.error && creds.error.length > 0) {
            failed.push({ dbName: databasesMap[access[i].database_id].name, ...creds })
          } else if (creds.data && !creds.data.username) {
            failed.push({ dbName: databasesMap[access[i].database_id].name, error: 'Unexpected error occured generating credentials' })
          } else {
            if (msg.length > 0) {
              msg += '\n'
            }
            msg += `localhost:5432:${databasesMap[access[i].database_id].name}:${creds.data.username}:`
          }
        } catch (e) {
          failed.push({ dbName: databasesMap[access[i].database_id].name, error: 'Unexpected error occured generating credentials' })
        }
      }
    }
    setPgpassCreds({ message: msg, failed: failed, ready: true })
    setPgpassLoading(false)
  }

  const resetPgpassCreds = useCallback(async () => {
    setPgpassCreds({ message: '', failed: [], ready: false })
  }, [])

  const cancelAccessRequest = useCallback(async (approvalID) => {
    setCancelling(approvalID)
    const resp = await deleteAccessRequest({id: approvalID})
    if (resp.error) {
      toast({
        title: 'Failed to cancel your access request.',
        description: resp.error.data.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      setAreYouSureData(null)
      toast({
        title: 'Successfully cancelled your access request.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
    }
    setCancelling('')
    refetchDBAccess()
    refetchVaultAccess()
  }, [deleteAccessRequest, toast, refetchDBAccess, refetchVaultAccess])

  const columns = useMemo(
    () => [
        {
            Header: 'Type',
            accessor: 'request_type',
        },
        {
            Header: (
                <Tooltip label="Hover over items in this column to see more information">
                  <HStack><Text>Resource</Text> <FiInfo /></HStack>
                </Tooltip>
            ),
            accessor: 'resource',
            Cell: ({ row }) => (
              <Tooltip label={`Reason: ${row.original.business_justification}`}>
                {row.original.request_type === request_type_database ? (
                  `${row.original.role} access to ${databasesMap[row.original.database_id]?.name || 'Unknown'}`
                ) : row.original.request_type === request_type_vault ? (
                  `${namespacesMap[row.original.namespace_id].name} namespace`
                ) : (
                  ''
                )}
              </Tooltip>
            ),
            id: 'resource',
        },
        {
            Header: 'Details',
            accessor: (row, _) => {
              if (row.request_type === request_type_database) {
                const instName = instancesMap[databasesMap[row.database_id]?.instance_id]?.name || 'database is not ready'
                const proj = databasesMap[row.database_id]?.project || 'Unknown'
                return `${instName} instance in ${proj}`
              } else {
                return ''
              }
            },
            id: 'details',
        },
        {
            Header: 'Valid Through',
            accessor: 'expiration_date',
            Cell: ({ row }) => (
                <Text>{new Date(row.original.expiration_date * 1000).toDateString()}</Text>
            ),
            id: 'expiration_date',
        },
        {
            Header: 'Requested Date',
            accessor: 'requested_date',
            show: false,
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => (
                <HStack>
                    <Icon {...iconPropsFromStatus(row.original.status)} />
                    <Text>{row.original.status}</Text>
                </HStack>
            ),
            id: 'status',
        },
        {
            Header: 'Actions',
            Cell: ({ row }) => (
                row.original.request_type === request_type_database && row.original.status === 'approved' ? (
                    <HStack>
                        <Tooltip label='Reconfigure access'>
                            <IconButton isLoading={credentialsLoading === row.original.id} isDisabled={pgpassLoading || credentialsLoading !== ''} variant='ghost' colorScheme={buttonColorScheme} aria-label='Reconfigure access' icon={<FiKey />} onClick={() => getCredentialsForAccessRequest(row.original.id)} />
                        </Tooltip>
                        <Tooltip label='Revoke access'>
                            <IconButton isLoading={cancelling === row.original.approval_id} isDisabled={credentialsLoading !== ''} variant='ghost' colorScheme="red" aria-label='Revoke access' icon={<FiXCircle />} onClick={() => setAreYouSureData({approval_id: row.original.approval_id, name: databasesMap[row.original.database_id]?.name, role: row.original.role})} />
                        </Tooltip>
                    </HStack>
                ) : row.original.status === 'pending' && (
                    <HStack>
                        <Tooltip label='Cancel access request'>
                            <IconButton isLoading={cancelling === row.original.approval_id} isDisabled={credentialsLoading !== ''} variant='ghost' colorScheme='red' aria-label='Cancel access request' icon={<FiXCircle />} onClick={() => cancelAccessRequest(row.original.approval_id)} />
                        </Tooltip>
                    </HStack>
                )
            ),
            id: 'actions',
        }
    ],
  [cancelAccessRequest, cancelling, credentialsLoading, databasesMap, namespacesMap, getCredentialsForAccessRequest, instancesMap, pgpassLoading, buttonColorScheme])

  return (
    <Stack spacing="5">
        <Stack px={{ base: '4', md: '6' }} py="5">
            <Text fontSize="2xl" fontWeight="medium">
                My Access
            </Text>
            <VStack align='flex-start' spacing="5">
                <ButtonGroup colorScheme={buttonColorScheme}>
                    <Button as={Link} to={`new`}>Request New Access</Button>
                    <Button isLoading={pgpassLoading} onClick={generatePGPASSFile}>Generate PGPASS file</Button>
                </ButtonGroup>
            </VStack>
        </Stack>

        {isLoadingDBAccess || isLoadingVaultAccess || isLoadingNamespaces || isLoadingDatabases || isLoadingInstances ? (
            <LoadingSpinner />
        ) : (
            <DataTable
                data={access}
                columns={columns}
                enableSearch={false}
                sortByField='requested_date'
                enableRowNavigation={false}
                hiddenColumns={['requested_date']} // the column has to exist to sort on it, but we dont want to show this one
            />
        )}

      <NewCredentialsModal credentials={credentials} isOpen={showCredentialsModal} onClose={() => setShowCredentialsModal(false)} />
      <PgPassModal credentials={pgpassCreds} isOpen={pgpassCreds.ready} onClose={() => resetPgpassCreds()} />
      <AreYouSure
        content={<Text>Are you sure you want to revoke your <Code>{areYouSureData?.role}</Code> access to the <Code>{areYouSureData?.name}</Code> database?</Text>}
        submitting={cancelling}
        onConfirm={() => cancelAccessRequest(areYouSureData?.approval_id)}
        isOpen={areYouSureData !== null}
        onClose={() => setAreYouSureData(null)}
        confirmButtonText='Revoke'
      />
    </Stack>
  )
}
