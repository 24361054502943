import { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  HStack,
  Link,
  TableContainer,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
  Td,
  Text,
  Tooltip,
  Stack,
  Spinner,
  Code,
  Button,
} from '@chakra-ui/react'
import { ExternalLinkIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { Duration } from '../../utils/dateUtils'
import { useGetRunnersQuery } from '../../apis/gha-queue'
import { FiCheckCircle } from 'react-icons/fi'

const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'medium' })

export const GitHubActions = () => {
  const { data: runners = [], isFetching } = useGetRunnersQuery(null, { pollingInterval:  5000 })

  // now is sort of a hack to get the page to only rerender once every second
  // rather than len(data) number of times per second simultaneously
  const [now, setNow] = useState(Math.floor(new Date().getTime() / 1000))

  useEffect(() => {
    // every second, update the timestamps on the page
    const interval = setInterval(() => {
      setNow(Math.floor(new Date().getTime() / 1000))
    }, 1000)
    return () => clearInterval(interval)
  }, [setNow])

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
        <Stack spacing="5">
            <Text fontSize="2xl" fontWeight="medium">GitHub Action Runners </Text>
            <HStack>
                {isFetching ? (
                    <Spinner size='sm' />
                ) : (
                    <FiCheckCircle />
                )}
                <Text>{runners.length} autoscaling runners online</Text>
            </HStack>
            <Text>Our GitHub runners run on autoscaling infrastructure. You can use these runners by specifying <Code>runs-on: figure-linux-standard</Code>.
                Check out the <Button as={Link} variant="text" to="https://www.notion.so/figuretech/New-GitHub-Action-Runners-dea588c903e949f0958469492f4da882?pvs=4" target="_blank" rel="noopener noreferrer" rightIcon={<ExternalLinkIcon />}>Notion doc</Button> for general info,
                 or our <Button as={Link} variant="text" to="https://docs.devops.figure.com/internal-docs/github-actions-runners" target="_blank" rel="noopener noreferrer" rightIcon={<ExternalLinkIcon />}>internal docs</Button> for a deep dive on our architecture.
            </Text>
            <Text>To view additional metrics, check out our <Button as={Link} variant="text" to="https://app.datadoghq.com/dashboard/4m2-swt-5cx/gh-arc?refresh_mode=sliding&view=spans&from_ts=1707152465347&to_ts=1707166865347&live=true" target="_blank" rel="noopener noreferrer" rightIcon={<ExternalLinkIcon />}>DataDog Dashboard</Button>.
            </Text>
        </Stack>
        <Box mt="5" mx={{ base: '-4', md: '-6' }}>
            <TableContainer>
                <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Runner</Th>
                        <Th>Phase</Th>
                        <Th>Repo</Th>
                        <Th>Job</Th>
                        <Th>Run Time</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {runners.map((runner) => (
                        <Tr key={runner.id}>
                            <Td><Tooltip label={`${runner.cpu}cpu, ${runner.memory}, version ${runner.image.split(':')[1]}`}>{runner.name}</Tooltip></Td>
                            <Td>{runner.phase}</Td>
                            <Td>{runner.repo.split('/', 2)[1]}</Td>
                            <Td>
                            {runner.phase === 'Pending' ?
                                <Spinner size='sm' />
                            : ''}
                            {runner.phase === 'Running' ?
                                <Link href={`https://github.com/${runner.org}/${runner.repo.split('/', 2)[1]}/actions/runs/${runner.run_id}`} isExternal>
                                {runner.job.slice(0, 30)}{(runner.job.length > 30 ? '...' : '')} <ExternalLinkIcon />
                                </Link>
                                : ''}
                            </Td>
                            <Td>
                            {runner.phase === 'Running' ?
                                <Flex>
                                    <Duration now={now} startTime={runner.started_at} endTime={0} />
                                        &nbsp;
                                    <Tooltip label={`Started at ${formatter.format(new Date(runner.started_at * 1000))}`}><InfoOutlineIcon /></Tooltip>
                                </Flex>
                                : ''}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            </TableContainer>
        </Box>
    </Box>
  )
}
