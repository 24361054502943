import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Flex,
    Grid,
    GridItem,
    Icon,
    Text,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useGetBackupByIdQuery, useGetDatabaseByIdQuery, useGetInstanceByIdQuery} from "../../apis/database-builder";
import { FiChevronRight } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { LoadingSpinner } from '../../components/LoadingSpinner'

export const BackupDetails = () => {
  const { id } = useParams()
  const { data: backup = {}, isLoading: isLoadingBackup } = useGetBackupByIdQuery(id)
  const { data: database = {}, isLoading: isLoadingDatabase } = useGetDatabaseByIdQuery(backup.database_id, { skip: backup.database_id === undefined })
  const { data: instance = {}, isLoading: isLoadingInstance } = useGetInstanceByIdQuery(database.instance_id, { skip: database.instance_id === undefined })

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Backups</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex mt={5} justifyContent='space-between'>
        <Text fontSize="2xl" fontWeight="medium">
          {isLoadingBackup ? 'Details' : database.name}
        </Text>
      </Flex>

      {isLoadingBackup || isLoadingDatabase || isLoadingInstance ? (
        <LoadingSpinner />
      ) : (
        <Box mt={5}>
          <Grid templateColumns='repeat(2, 1fr)' gap={4}>
            <GridItem as='b'><Text align='left' pr={5}>Backup Status</Text></GridItem><GridItem><Text><Icon {...iconPropsFromStatus(backup.status)} /> {backup.status}</Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Backup ID</Text></GridItem><GridItem><Text><code>{backup.id}</code></Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Database Name</Text></GridItem><GridItem><Text>{database.name}</Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Instance Name</Text></GridItem><GridItem><Text>{instance.name}</Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Requester</Text></GridItem><GridItem><Text>{backup.requester_id}</Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Project</Text></GridItem><GridItem><Text>{database.project}</Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Environment</Text></GridItem><GridItem><Text>{instance.environment}</Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Started At</Text></GridItem><GridItem><Text>{new Date(backup.started_at * 1000).toLocaleString()}</Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Completed At</Text></GridItem><GridItem><Text>{backup.completed_at? new Date(backup.completed_at * 1000).toLocaleString() : ""}</Text></GridItem>
            <GridItem as='b'><Text align='left' pr={5}>Backup URI</Text></GridItem><GridItem><Text><code>{backup.uri}</code></Text></GridItem>
          </Grid>
        </Box>
      )}

    </Box>
  )
}
