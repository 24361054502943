import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    CircularProgress,
    CircularProgressLabel,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    List,
    ListItem,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useGetDatabasesByInstanceIdQuery, useGetInstanceByIdQuery } from '../../apis/database-builder'
import { FiAlertTriangle, FiChevronRight } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { DataTable } from '../../components/DataTable'
import { useMemo } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'

export const InstanceDetails = () => {
  const { id } = useParams()
  const { data: instance = {}, isLoading: isLoadingInstance } = useGetInstanceByIdQuery(id)
  const { data: databases = [], isLoading: isLoadingDatabases } = useGetDatabasesByInstanceIdQuery(id)
  const maxCapacity = 1
  const capacity = Math.round(databases.length / maxCapacity * 100)

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'App',
            accessor: 'app_name',
        },
        {
          Header: 'Project',
          accessor: 'project',
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ row }) => {
              return <HStack>
                  <Icon {...iconPropsFromStatus(row.original.status)} />
                  <Text>{row.original.status}</Text>
                  {row.original.error && (
                  <Tooltip label='An error occurred processing this request. Click for more details'>
                      <Box>
                          <FiAlertTriangle color='yellow' />
                      </Box>
                  </Tooltip>
                  )}
              </HStack>
          },
          id: 'status',
        },
    ],
  [])

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Instances</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex mt={5} justifyContent='space-between'>
        <Text fontSize="2xl" fontWeight="medium">
          {isLoadingInstance ? 'Details' : instance.name}
        </Text>
      </Flex>

      {isLoadingInstance || isLoadingDatabases ? (
        <LoadingSpinner />
      ) : (
        <Box mt={5}>
          <Grid templateColumns='repeat(2, 1fr)'>
            <Grid templateColumns='repeat(2, 1fr)'>
              <GridItem as='b'><Text align='right' pr={5}>Instance Status</Text></GridItem><GridItem><Text><Icon {...iconPropsFromStatus(instance.status)} /> {instance.status}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Project</Text></GridItem><GridItem><Text>{instance.project}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Environment</Text></GridItem><GridItem><Text>{instance.environment}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Region</Text></GridItem><GridItem><Text>{instance.region}</Text></GridItem>
            </Grid>
            {!isLoadingDatabases && (
              <VStack>
                <Text><b>Capacity</b></Text>
                <CircularProgress value={capacity} color='green.500' size='100px'>
                  <CircularProgressLabel>{databases.length} / {maxCapacity}</CircularProgressLabel>
                </CircularProgress>
              </VStack>
            )}
          </Grid>

          <List mt={5} spacing={5}>
            <ListItem>
              <Text fontWeight="medium">Databases on this instance</Text>
            </ListItem>
            <ListItem mx={{ base: '-4', md: '-6' }}>
              <DataTable
                data={databases}
                columns={columns}
                sortByField='name'
                sortDesc={false}
                enableSearch={false}
                enableRowNavigation={false}
              />
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  )
}
