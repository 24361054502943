import {
  Alert,
  AlertIcon,
  Button,
  Code,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tag,
  Text,
  VStack,
  useClipboard,
  useColorModeValue,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { FiClipboard, FiCheckCircle } from 'react-icons/fi'
import { useMemo } from 'react'

export const PgPassModal = ({ credentials, isOpen, onClose }) => {
  const { setValue, hasCopied, onCopy } = useClipboard(credentials.message)

  useMemo(() => {
    setValue(credentials.message)
  }, [setValue, credentials])

  const buttonColorScheme = useColorModeValue('blue', 'green')

  return (
    <Modal size='6xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Credentials Generated Successfully</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems='flex-start' spacing='15px'>
            <Text>
              Here are your credentials in <Tag>.pgpass</Tag> file format for all databases that you have access to.
            </Text>

            <Code maxWidth='100%'>
              {credentials.message.split('\n').map((line, i) => (
                <Text key={'cred-' + i}>{line}</Text>
              ))}
            </Code>
            <Button variant='text' colorScheme={buttonColorScheme} onClick={onCopy}>
              {hasCopied ? (
                <><FiCheckCircle /> &nbsp; Copied</>
              ) : (
                <><FiClipboard /> &nbsp; Copy to clipboard</>
              )}
            </Button>

            {credentials.failed.length > 0 && (
              <>
                <Alert status='warning'>
                  <AlertIcon />
                  Credentials for the following databases could not be generated. Please try again later or report the failure to #devops
                </Alert>
                {credentials.failed.map((creds, i) => (
                  <Text key={'failure:' + i}>
                    {creds.dbName}: {creds.error}
                  </Text>
                ))}
              </>
            )}

            <Divider />
            <Text fontSize='xs'>Is this your first time connecting? Or do you need <Code>cloud_sql_proxy</Code> installation instructions? Check out
              the full guide at{' '}
              <Button
                size='xs' variant='text' as='a'
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.notion.so/figuretech/Use-CloudSQL-Proxy-to-Establish-Database-Connections-885195912ffe448eb92e7bfd2090b4b3'
              >
                Databases, CloudSQL Proxy, and You <ExternalLinkIcon mx='2px' />
              </Button>
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
