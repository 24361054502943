import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Link,
    SimpleGrid,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react'
import { useOktaAuth } from '@okta/okta-react'
import { useGetAllRequestsQuery as useGetDatabaseRequestsQuery } from '../../apis/db-access-provider'
import { useGetUserByIdQuery } from '../../apis/org-chart'
import { useGetRequestsQuery as useGetVaultRequestsQuery } from '../../apis/vault-access-provider'
import { useGetRequestsQuery } from '../../apis/approval-engine'
import { CardStat } from '../../components/CardStat'
import { RecentBlogPosts } from '../../components/RecentBlogPosts'
import { useGetTeamsQuery } from '../../apis/org-chart'

export const Dashboard = () => {
    const { data: dbAccess = [], isLoading: isLoadingDbAccess } = useGetDatabaseRequestsQuery()
    const { data: vaultAccess = [], isLoading: isLoadingVaultAccess } = useGetVaultRequestsQuery()

    const { data: me = {}, isLoading: isLoadingUser } = useGetUserByIdQuery('me')
    const { data: teams = [], isLoading: isLoadingTeams } = useGetTeamsQuery()

    const { data: pendingRequests = [], isLoading: isLoadingPendingRequests } = useGetRequestsQuery({}, { skip: isLoadingUser })

    const { authState } = useOktaAuth()
    const weekday = new Date().toLocaleDateString('default', { weekday: 'long' })

    return (
        <Box width="full" overflowY="auto">
            <Container py="8" flex="1">
                <Stack
                    spacing={{
                        base: '8',
                        lg: '6',
                    }}
                >
                    <Stack
                        spacing="4"
                        direction={{
                            base: 'column',
                            lg: 'row',
                        }}
                        justify="space-between"
                        align={{
                            base: 'start',
                            lg: 'center',
                        }}
                    >
                        <Stack spacing="1">
                        <Heading
                            size={useBreakpointValue({
                                base: 'xs',
                                lg: 'sm',
                            })}
                            fontWeight="medium"
                        >
                            Happy {weekday === 'Thursday' ? 'Friday Junior' : weekday}{authState.idToken && ' ' + authState.idToken.claims.name.split(' ')[0]}!
                        </Heading>
                        </Stack>
                        <HStack spacing="3">
                            <Button variant="text" as={Link} href='https://app.shortcut.com/figure/stories/new?template_id=5cae089c-0c6e-41c5-a702-2b135c5d6b59' target='_blank' rel='noopener noreferrer'>Create a helpdesk ticket <ExternalLinkIcon mx='2px' /></Button>
                            <Button variant="text" as={Link} href='https://app.shortcut.com/figure/stories/new?template_id=5e9e2aea-4d40-4bcf-8c39-21bec01ec19f' target='_blank' rel='noopener noreferrer'>Create a feature request <ExternalLinkIcon mx='2px' /></Button>
                        </HStack>
                    </Stack>
                    <Stack
                        spacing={{
                            base: '5',
                            lg: '6',
                        }}
                    >
                        <SimpleGrid
                            columns={{
                                base: 1,
                                // If the user is a member of the DevOps team, show 4 stats, otherwise show 3
                                md: teams.some(team => team.name === 'DevOps' && team.members.some(member => member.user.id === me.id)) ? 4 : 3,
                            }}
                            gap="6"
                        >
                            <CardStat label='Active Database Access' value={dbAccess.filter((d) => d.status === 'approved').length} isLoading={isLoadingDbAccess} to='/access' />
                            <CardStat label='Active Vault Access' value={vaultAccess.filter((v) => v.status === 'approved').length} isLoading={isLoadingVaultAccess} to='/access' />

                            {/* Currently only the DevOps team can approved database creation requests, so filter that from access requests and show another stat for the devops team */}
                            <CardStat label='Access Requests Awaiting Review' value={pendingRequests.filter(r => me?.id in r.current_decisions && r.status === 'pending' && r.type.includes('Access')).length} isLoading={isLoadingUser || isLoadingPendingRequests} to='/access/team' />
                            {teams.some(team => team.name === 'DevOps' && team.members.some(member => member.user.id === me.id)) && (
                                <CardStat label='Database Creation Requests' value={pendingRequests.filter(r => me?.id in r.current_decisions && r.status === 'pending' && r.type.includes('Database Create')).length} isLoading={isLoadingUser || isLoadingPendingRequests || isLoadingTeams} to='/admin/approvals' />
                            )}
                        </SimpleGrid>
                    </Stack>
                    <RecentBlogPosts minH="sm" />
                </Stack>
            </Container>
        </Box>
    )
}
