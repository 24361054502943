import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Stack,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const RoutingCard = ({ innerRef, ...props }) => {
  return (
    <Formik initialValues={{ "name": "", "externalPath": "", "internalPath": "", "plugins": ["jwt"] }} innerRef={innerRef}>
        <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
            <Stack
                spacing="5"
                px={{ base: "4", md: "6" }}
                py={{ base: "5", md: "6" }}
            >
                <Field name="name">
                    {({ field }) => (
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input {...field} type="text" />
                            <FormHelperText color="fg.subtle">Give this route a name. It must be 63 characters or less, and be unique for an entire namespace.</FormHelperText>
                        </FormControl>
                    )}
                </Field>

                <Field name="externalPath">
                    {({ field }) => (
                        <FormControl>
                            <FormLabel>External Path</FormLabel>
                            <Input {...field} type="text" />
                            <FormHelperText color="fg.subtle">This is the path that you want to expose to the public internet. This must be <strong>globally</strong> unique per cluster.</FormHelperText>
                        </FormControl>
                    )}
                </Field>

                <Field name="internalPath">
                    {({ field }) => (
                        <FormControl>
                            <FormLabel>Internal Path</FormLabel>
                            <Input {...field} type="text" />
                            <FormHelperText color="fg.subtle">This is the internal path that your app responds to that corresponds to the external path above.
                                This is usually the same as external path, with a prefix removed. See the documentation for more information.
                            </FormHelperText>
                        </FormControl>
                    )}
                </Field>

                <FormControl>
                    <FormLabel>Plugins</FormLabel>
                    <Stack spacing={[1, 5]} direction={['column', 'row']}>
                        <Field name="plugins">
                            {({ field }) => (
                                <Checkbox {...field} value="jwt" isChecked={field.value.includes("jwt")}>
                                    JWT
                                </Checkbox>
                            )}
                        </Field>
                        <Field name="plugins">
                            {({ field }) => (
                                <Checkbox {...field} value="ip-restriction" isChecked={field.value.includes("ip-restriction")}>
                                    IP Restriction
                                </Checkbox>
                            )}
                        </Field>
                    </Stack>
                    <FormHelperText color="fg.subtle">Choose which plugins apply to this route. You can customize these later.</FormHelperText>
                </FormControl>
            </Stack>
        </Box>
    </Formik>
  )
}
