import { createApi } from '@reduxjs/toolkit/query/react'
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from './retry'

export const topsServiceApi = createApi({
  reducerPath: 'topsServiceApi',
  baseQuery: baseQueryWithUnAuthRetry(config.topsServiceApiUrl),
  tagTypes: ['Deployment', 'DeploymentCluster', 'NexusAccessRequest'],
  endpoints: (builder) => ({
    // deployments
    getDeployments: builder.query({
      query: () => '/deployments',
      providesTags: (result = [], error, arg) => [
        'Deployment',
        ...result.map(({ id }) => ({ type: 'Deployment', id }))
      ]
    }),
    searchDeployments: builder.query({
      query: ({ ...body }) => ({
        url: '/deployments',
        method: 'POST',
        body: body
      }),
      providesTags: (result = [], error, arg) => [
        'Deployment',
        ...result.map(({ id }) => ({ type: 'Deployment', id }))
      ]
    }),
    getDeploymentClusters: builder.query({
      query: () => '/deployments/clusters',
      providesTags: (result = [], error, arg) => ['DeploymentCluster']
    }),
    scaleDeployment: builder.mutation({
      query: ({ id, replicas, ...body }) => ({
        url: `/deployments/${id}/scale/${replicas}`,
        method: 'POST',
        body: body
      })
    }),
    redeployDeployment: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/deployments/${id}/redeploy`,
        method: 'POST',
        body: body
      })
    }),
    rollbackDeployment: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/deployments/${id}/rollback`,
        method: 'POST',
        body: body
      })
    }),
    convertDeployment: builder.mutation({
      query: (body) => ({
        url: `/deployments/convert`,
        method: 'POST',
        body: body
      })
    }),

    // nexus
    createNexusAccessRequest: builder.mutation({
      query: ({ ...body }) => ({
        url: '/access/nexus',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['NexusAccessRequest']
    })
  })
})

export const {
  useGetDeploymentsQuery,
  useSearchDeploymentsQuery,
  useGetDeploymentClustersQuery,
  useRedeployDeploymentMutation,
  useScaleDeploymentMutation,
  useRollbackDeploymentMutation,
  useConvertDeploymentMutation,

  useCreateNexusAccessRequestMutation
} = topsServiceApi
