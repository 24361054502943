import { CopyIcon } from '@chakra-ui/icons';
import {
    Button,
    Flex,
    HStack,
    Icon,
    Stack,
    Text,
    useClipboard,
    useColorModeValue,
} from '@chakra-ui/react'
import { FiAlertTriangle, FiCheckCircle, FiChevronRight, FiXCircle } from 'react-icons/fi';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import dracula from 'react-syntax-highlighter/dist/esm/styles/hljs/dracula';
import github from 'react-syntax-highlighter/dist/esm/styles/hljs/github';

export const ManifestTab = ({
    yamlString = "",
    warnings = [],
    errors = [],
  }) => {
  // Add the yaml-lanauge-server comment to displayed and copied yaml file for IDE schema validation
  const commentedYamlString = "# yaml-language-server: $schema=https://storage.googleapis.com/figure-public-content/helm-charts/values.schema.json\n" + yamlString;
  const { onCopy, hasCopied } = useClipboard(commentedYamlString)
  const syntaxTheme = useColorModeValue(github, dracula)

  return (
    <Stack spacing="5">
        {errors.length > 0  ? (
            <>
                <HStack><Icon as={FiXCircle} color="red" boxSize="5" /> <Text as="b" fontSize="xl">Conversion failed</Text></HStack>

                {errors.map((error, i) => (
                    <HStack key={`error-${i}`}><Icon as={FiChevronRight} color="red" /> <Text>{error}</Text></HStack>
                ))}
            </>
        ) : warnings.length > 0 ? (
            <>
                <HStack><Icon as={FiAlertTriangle} color="orange" boxSize="5" /> <Text as="b" fontSize="xl">Conversion succeeded with warnings</Text></HStack>

                {warnings.map((warning, i) => (
                    <HStack key={`warning-${i}`}><Icon as={FiChevronRight} color="orange" /> <Text>{warning}</Text></HStack>
                ))}

                <Flex><Button leftIcon={<CopyIcon />} onClick={onCopy}>{hasCopied ? "Copied!" : "Copy to Clipboard"}</Button></Flex>
            </>
        ) : (
            <>
                <HStack><Icon as={FiCheckCircle} color="green" boxSize="5" /> <Text as="b" fontSize="xl">Conversion successful</Text></HStack>
                <Flex><Button leftIcon={<CopyIcon />} onClick={onCopy}>{hasCopied ? "Copied!" : "Copy to Clipboard"}</Button></Flex>
            </>
        )}
        <SyntaxHighlighter language="yaml" style={syntaxTheme}>
            {commentedYamlString || "# No data"}
        </SyntaxHighlighter>
    </Stack>
    )
}
