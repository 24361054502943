import { useRef, useState } from 'react'
import {
    Box,
    Button,
    Center,
    HStack,
    Icon,
    Square,
    Text,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react'
import { FiFile, FiUploadCloud, FiCornerRightDown } from 'react-icons/fi'

export const Dropzone = ({selectedFile, setSelectedFile, ...props}) => {
    const inputRef = useRef();

    const [isFilePicked, setIsFilePicked] = useState(false)
    const [dragActive, setDragActive] = useState(false)

    const fileChosen = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0])
            setIsFilePicked(true)
        }
    }

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(e.type !== "dragleave")
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setSelectedFile(e.dataTransfer.files[0])
            setIsFilePicked(true)
        }
    }

    return (
        <Center
            borderWidth="1px"
            borderRadius="lg"
            borderStyle="dashed"
            px="6"
            py="4"
            bg={useColorModeValue('white', 'gray.800')}
            {...props}
            onClick={() => inputRef.current.click()}
            style={{cursor: "pointer"}}
        >
            <VStack spacing="3">
                <Square size="10" bg="bg.subtle" borderRadius="lg">
                    <Icon as={dragActive ? FiCornerRightDown : isFilePicked ? FiFile : FiUploadCloud} boxSize="5" color="fg.muted" />
                </Square>
                <VStack spacing="1">
                    {dragActive ? (
                        <Text fontSize="sm" color="fg.muted">
                            Drop here to upload
                        </Text>
                    ) : isFilePicked ? (
                        <Text fontSize="sm" color="fg.muted">
                            {selectedFile.name}
                        </Text>
                    ) : (
                        <>
                            <HStack spacing="1" whiteSpace="nowrap">
                                <Button variant="text" colorScheme="blue" size="sm" pointerEvents="none">
                                    Click to upload
                                </Button>
                                <Text fontSize="sm" color="fg.muted">
                                    or drag and drop
                                </Text>
                            </HStack>
                            <Text fontSize="xs" color="fg.muted">
                                .yaml or .yml files
                            </Text>
                        </>
                    )}
                </VStack>
                <input type="file" accept=".yaml,.yml" ref={inputRef} onChange={(e) => fileChosen(e)} hidden />
            </VStack>

            {/* Drag-n-drop over multiple components is kind of annoying. You get "enter" and
            "leave" events for every component you drag over. The recommended way to fix this is
            to create an invisible box to cover the whole drag-n-drop area where you handle all
            your events. With the positioning attributes, this does that. */}
            <Box
                top="0px"
                right="0px"
                bottom="0px"
                left="0px"
                w="100%"
                h="100%"
                position="absolute"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop} />
        </Center>
    )
}
