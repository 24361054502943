import { theme as proTheme } from '@chakra-ui/pro-theme'
import { theme as baseTheme, extendTheme } from '@chakra-ui/react'
import '@fontsource-variable/open-sans'
import '@fontsource-variable/spline-sans'

export const theme = extendTheme({
    initialColorMode: 'dark',
    useSystemColorMode: false,
    colors: { ...proTheme.colors, brand: baseTheme.colors.blue },
}, proTheme)
