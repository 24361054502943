import { useRef } from 'react'
import {
    Box,
    Button,
    Container,
    Flex,
    Spacer,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import { MiscCard } from './MiscCard'

export const Finish = ({ getYamlField, setYamlField, goToNextStep, goToPrevStep }) => {
  const miscRef = useRef()

  const redisHost = () => {
    const project = getYamlField("project").trim()
    let host = ""
    switch (project) {
      case "figure":
        host = "redis.figure.internal"
        break
      case "figure-pay":
        host = "redis.figurepay.internal"
        break
	    case "figure-tech":
        host = "redis.figuretech.internal"
        break
	    case "figure-dataeng":
        host = "redis.dataeng.internal"
        break
      default:
    }
    return host
  }

  const changePage = (change) => {
    for (const field in miscRef.current.values) {
      if (field === "redis.enabled" && miscRef.current.values[field]) {
        setYamlField("deployment.config.REDIS_HOST", redisHost())
        setYamlField("deployment.config.REDIS_PORT", "6379")
        setYamlField("deployment.config.REDIS_CONNECTION_POOL_SIZE", "64")
        // for some reason setting values in a map like this causes the yaml
        // lib to render deployment.config using the "flow" style. which means
        // it surrounds the whole thing with curly braces. explicitly setting
        // flow to false here fixes that on the manifest page.
        let conf = getYamlField("deployment.config", false)
        conf.flow = false
      } else {
        setYamlField(field, miscRef.current.values[field])
      }
    }
    change()
  }

  return (
    <Container
        py={{ base: '4', md: '8' }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Finishing Touches
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Here's a few less common options you can change, or click Finish to get your yaml file.
              </Text>
            </Box>
            <MiscCard
              innerRef={miscRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

            <Flex>
                <Button variant="primary" onClick={() => changePage(goToPrevStep)}>
                    Previous
                </Button>
                <Spacer />
                <Button variant="primary" onClick={() => changePage(goToNextStep)}>
                    Finish
                </Button>
            </Flex>
        </Stack>
      </Container>
    )
}
