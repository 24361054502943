import { useMemo } from 'react'
import { useGetNamespacesQuery, useGetRequestsForTeamQuery } from '../../apis/vault-access-provider'
import { useGetUsersQuery, useGetUserByIdQuery } from '../../apis/org-chart'
import {
    Box,
    HStack,
    Icon,
    Text,
} from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'

export const ResourceAccess = () => {
  const { data: user, isLoading: isLoadingSelf } = useGetUserByIdQuery('me')

  const { data: requests = [], isLoading: isLoadingRequests } = useGetRequestsForTeamQuery(user?.team_id, { skip: isLoadingSelf })
  const { data: namespaces = [], isLoading: isLoadingNamespaces } = useGetNamespacesQuery()
  const { data: rawUsers = [], isLoading: isLoadingUsers } = useGetUsersQuery()

  const activeRequests = useMemo(() => {
    return requests.filter((r) => r.status === "approved")
  }, [requests])

  const users = useMemo(() => {
    const users = {}
    rawUsers.forEach((user) => {
      users[user.id] = user
    })
    return users
  }, [rawUsers])

  const columns = useMemo(
    () => [
        {
            Header: 'User',
            accessor: (row, _) => `${users[row.user_id]?.first_name || 'Unknown'} ${users[row.user_id]?.last_name}`,
            id: 'user',
        },
        {
            Header: 'Resource',
            accessor: (row, _) => `${namespaces.find((n) => n.id === row.namespace_id)?.name || 'Unknown'} namespace`,
            id: 'resource',
        },
        {
            Header: 'Type',
            accessor: (row, _) => 'Vault Access',
        },
        {
            Header: 'Requested Date',
            accessor: 'requested_date',
            Cell: ({ row }) => (
                <Text>{new Date(row.original.requested_date * 1000).toDateString()}</Text>
            ),
            id: 'requested_date',
        },
        {
            Header: 'Expiration Date',
            accessor: 'expiration_date',
            Cell: ({ row }) => (
                <Text>{new Date(row.original.expiration_date * 1000).toDateString()}</Text>
            ),
            id: 'expiration_date',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => {
                return <HStack>
                    <Icon {...iconPropsFromStatus(row.original.status)} />
                    <Text>{row.original.status}</Text>
                </HStack>
            },
            id: 'status',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [users, namespaces])

  return (
    <Box>
        {isLoadingRequests || isLoadingNamespaces || isLoadingSelf || isLoadingUsers ? (
            <LoadingSpinner />
        ) : (
            <DataTable
                data={activeRequests}
                columns={columns}
                title="Resource Access"
                description="Your team can own multiple resources of different types. As a team lead, you can manage all user's access to resources that you own, no matter which team the user is on."
                enableSearch={true}
                sortByField="requested_date"
                sortDesc={false}
                navigationIdField='approval_id'
            />
        )}
    </Box>
  )
}
