import { useMemo, useState } from 'react'
import {
    Flex,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Spacer,
    Spinner,
    Text,
    Tooltip,
} from '@chakra-ui/react'
import { FiCheckCircle, FiChevronRight, FiSearch, FiXCircle } from 'react-icons/fi'
import { useSearchDeploymentsQuery } from '../../apis/tops-service'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'
import { poorMansParse, poorMansFormat } from '../../utils/dateUtils'
import { Search } from '../../components/Search'

export const Deployments = () => {
  const [searchTerm, setSearchTerm] = useState("")

  const { data: deployments = [], isLoading } = useSearchDeploymentsQuery({search: searchTerm}, {
    pollingInterval: 20000,
  })

  const groupDeployments = (deployments) => {
    const grouped = {}
    for (const i in deployments) {
      if (!(deployments[i].messageID in grouped)) {
        grouped[deployments[i].messageID] = { items: [], successful: 0, failed: 0, inProgress: false }
      }
      if (deployments[i].finished === true) {
        if (deployments[i].successful === true) {
          grouped[deployments[i].messageID].successful = grouped[deployments[i].messageID].successful + 1
        } else {
          grouped[deployments[i].messageID].failed = grouped[deployments[i].messageID].failed + 1
        }
      } else {
        grouped[deployments[i].messageID].inProgress = true
      }
      grouped[deployments[i].messageID].items.push(deployments[i])
    }
    const list = []
    for (const msgID of Object.keys(grouped)) {
        list.push({id: msgID, messageID: msgID, ...grouped[msgID]})
    }
    return list
  }

  const columns = useMemo(
    () => [
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => {
                return row.original.inProgress ? (
                    <Spinner />
                ) : row.original.failed === 0 ? (
                    <FiCheckCircle color='green' />
                ) : (
                    <FiXCircle color='red' />
                )
            },
            id: 'status',
        },
        {
            Header: 'Message ID',
            accessor: 'messageID',
        },
        {
            Header: 'Operations',
            accessor: (row, _) => `${row.successful}/${row.items.length}`,
            id: 'operations'
        },
        {
            Header: 'Cluster',
            accessor: (row, _) => row.items[0].cluster,
            id: 'cluster',
        },
        {
            Header: 'Message',
            accessor: 'message',
            Cell: ({ row }) => {
                return row.original.items.map((item) => (
                    <div key={item.id}>
                        {item.kind}/{item.name} {item.status}
                    </div>
                ))
            },
            id: 'message',
        },
        {
            Header: 'Created At',
            accessor: 'createdAt',
            Cell: ({ row }) => (
                <Tooltip label={poorMansParse(row.original.items[0].createdAt).toLocaleString()}>{poorMansFormat(row.original.items[0].createdAt)}</Tooltip>
            ),
            id: 'created_at',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [])

  return (
    isLoading ? (
        <LoadingSpinner />
    ) : (
        <DataTable
            data={groupDeployments(deployments)}
            columns={columns}
            description={
              <Flex>
                <Text fontSize="2xl" fontWeight="medium">
                    Deployments
                </Text>
                <Spacer />
                <InputGroup colorScheme='gray' maxW="xs" float="right" style={{clear: "left"}}>
                  <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                  </InputLeftElement>
                  <Input colorScheme='gray' as={Search} placeholder="Search" value={searchTerm} onValueChange={(e) => setSearchTerm(e)} />
                </InputGroup>
              </Flex>
            }
            sortByField='created_at'
            sortDesc={false}
            enableSearch={false}
        />
    )
  )
}
