import {
    Box,
    Button,
    ButtonGroup,
    HStack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react'

export const Pagination = ({pageIndex, pageCount, canPreviousPage, canNextPage, gotoPage, previousPage, nextPage, hideIfSinglePage = true}) => {
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    })

    if (hideIfSinglePage && pageCount <= 1) {
        return <></>
    }

    return (
        <Box
            px={{
                base: '4',
                md: '6',
            }}
            pb="5"
        >
            <HStack spacing="3" justify="space-between">
                {!isMobile && (
                <Text color="fg.muted" fontSize="sm">
                    Page {pageIndex+1} of {pageCount}
                </Text>
                )}
                <ButtonGroup
                    spacing="3"
                    justifyContent="space-between"
                    width={{
                        base: 'full',
                        md: 'auto',
                    }}
                    variant="secondary"
                >
                    <Button disabled={pageIndex === 0} onClick={() => gotoPage(0)}>First</Button>
                    <Button disabled={!canPreviousPage} onClick={() => previousPage()}>Previous</Button>
                    <Button disabled={!canNextPage} onClick={() => nextPage()}>Next</Button>
                    <Button disabled={pageIndex === pageCount-1} onClick={() => gotoPage(pageCount-1)}>Last</Button>
                </ButtonGroup>
            </HStack>
        </Box>
    )
}
