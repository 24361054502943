import {
  Box,
  Heading,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const CardStat = (props) => {
  const { label, value, isLoading, to, ...boxProps } = props
  const size = useBreakpointValue({ base: 'sm', md: 'md' })
  return (
    <Box
        as={Link}
        to={to}
        px={{ base: '4', md: '6' }}
        py={{ base: '5', md: '6' }}
        bg="bg.surface"
        borderRadius="lg"
        boxShadow='sm'
        {...boxProps}
    >
        <Stack>
          <Text fontSize="sm" color="fg.muted">
            {label}
          </Text>
          {isLoading ? (
            <Spinner />
          ) : (
            <Heading size={size}>{value}</Heading>
          )}
        </Stack>
    </Box>
  )
}
