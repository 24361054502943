import {
  Divider,
  Flex,
  HStack,
  IconButton,
  Link,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  FiBook,
  FiBookmark,
  FiBox,
  FiCheckCircle,
  FiCode,
  FiCloud,
  FiUploadCloud,
  FiDatabase,
  FiGithub,
  FiHome,
  FiKey,
  FiLogOut,
  FiMessageSquare,
  FiServer,
  FiThumbsUp,
  FiUser,
  FiUsers,
  FiBriefcase,
  FiTerminal,
  FiSliders,
  FiStar,
} from 'react-icons/fi'
import { GiGlassShot } from 'react-icons/gi'
import { IoAppsOutline } from 'react-icons/io5'
import { Logo } from './Logo'
import { NavButton } from './NavButton'
import { UserProfile } from './UserProfile'
import { NavLink } from "react-router-dom"
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { useOktaAuth } from '@okta/okta-react'
import { useGetUserByIdQuery } from '../apis/org-chart'

export const Sidebar = () => {
  const { toggleColorMode } = useColorMode()
  const ToggleIcon = useColorModeValue(MoonIcon, SunIcon)
  const { authState } = useOktaAuth()
  const { data: me = {} } = useGetUserByIdQuery('me')

  return (
  <Flex as="section" minH="100vh" bg="bg.canvas">
    <Flex
      flex="1"
      bg="bg.surface"
      overflowY="auto"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      maxW={{
        base: 'full',
        sm: 'xs',
      }}
      py={{
        base: '6',
        sm: '8',
      }}
      px={{
        base: '4',
        sm: '6',
      }}
    >
      <Stack justify="space-between" spacing="1">
        <Stack
          spacing={{
            base: '5',
            sm: '6',
          }}
          shouldWrapChildren
        >
          <Logo gradient="true" />
          <Stack spacing="1">
            <NavButton as={NavLink} to={`/`} label="Home" icon={FiHome} />
            <NavButton as={Link} href={`https://docs.devops.figure.com`} target='_blank' rel='noopener noreferrer' label="Documentation" icon={FiBook} />
            <NavButton as={Link} href={`https://developer.figure.com`} target='_blank' rel='noopener noreferrer' label="Developer Portal" icon={FiTerminal} />
          </Stack>

          <Stack>
            <Text fontSize="sm" color="fg.subtle" fontWeight="medium">
              Access
            </Text>
            <Stack spacing="1">
              <NavButton as={NavLink} to={`access`} end label="My Access" icon={FiBookmark} />
              {/* TODO this is no longer valid as team_lead is not an attribute on v2 users. need to rework
              these pages. likely path forward is to remove the pages entirely, and either have a "reports"
              page, or be able to drill into specific resources and see all access to that resource. */}
              {me.team_lead && (
                <>
                <NavButton as={NavLink} to={`access/team`} label="My Team's Access" icon={FiCheckCircle} />
                <NavButton as={NavLink} to={`access/resource`} label="Resource Access" icon={FiBriefcase} />
                </>
              )}
              <NavButton as={NavLink} to={`access/nexus`} label="Nexus" icon={GiGlassShot} />
            </Stack>
          </Stack>

          <Stack>
            <Text fontSize="sm" color="fg.subtle" fontWeight="medium">
              Databases
            </Text>
            <Stack spacing="1">
              <NavButton as={NavLink} to={`databases`} label="Databases" icon={FiDatabase} />
              <NavButton as={NavLink} to={`instances`} label="Instances" icon={FiServer} />
            </Stack>
          </Stack>

          <Stack>
            <Text fontSize="sm" color="fg.subtle" fontWeight="medium">
              Apps
            </Text>
            <Stack spacing="1">
              <NavButton as={NavLink} to={`apps`} end label="All Apps" icon={IoAppsOutline} />
              <NavButton as={NavLink} to={`apps/builder`} label="App Builder" icon={FiSliders} />
              <NavButton as={NavLink} to={`apps/operationalreadiness`} label="Operational Readiness" icon={FiStar} />
            </Stack>
          </Stack>

          <Stack>
            <Text fontSize="sm" color="fg.subtle" fontWeight="medium">
              Deployments
            </Text>
            <Stack spacing="1">
              <NavButton as={NavLink} to={`deployments`} label="All Deployments" icon={FiCode} />
              <NavButton as={NavLink} to={`clusters`} label="Clusters" icon={FiCloud} />
            </Stack>
          </Stack>

          <Stack>
            <Text fontSize="sm" color="fg.subtle" fontWeight="medium">
              Teams
            </Text>
            <Stack spacing="1">
              <NavButton as={NavLink} to={`teams`} end label="All Teams" icon={FiUsers} />
              <NavButton as={NavLink} to={`users`} label="Users" icon={FiUser} />
            </Stack>
          </Stack>

          {authState?.accessToken?.claims.admin === true && (
            <Stack>
              <Text fontSize="sm" color="fg.subtle" fontWeight="medium">
                Admin
              </Text>
              <Stack spacing="1">
                <NavButton as={NavLink} to={`admin/approvals`} label="Approvals" icon={FiThumbsUp} />
                <NavButton as={NavLink} to={`admin/pending-databases`} label="Pending Databases" icon={FiDatabase} />
                <NavButton as={NavLink} to={`backups`} label="Backups" icon={FiUploadCloud} />
              </Stack>
            </Stack>
          )}

          {authState?.accessToken?.claims.admin === true && (
            <Stack>
              <Text fontSize="sm" color="fg.subtle" fontWeight="medium">
                Kong
              </Text>
              <Stack spacing="1">
                <NavButton as={NavLink} to={`kong/api-keys`} label="API Keys" icon={FiKey} />
                <NavButton as={NavLink} to={`kong/consumers`} label="Consumers" icon={FiUploadCloud} />
              </Stack>
            </Stack>
          )}

          <Stack>
            <Text fontSize="sm" color="fg.subtle" fontWeight="medium">
              Misc
            </Text>
            <Stack spacing="1">
              <NavButton as={NavLink} to={`namespaces`} label="Namespaces" icon={FiBox} />
              <NavButton as={NavLink} to={`gha`} label="GitHub Actions" icon={FiGithub} />
              <NavButton as={Link} href={`https://docs.google.com/forms/d/e/1FAIpQLScwDwi7D8lA3Wby5oaZZjDLW8ZJ3rFlvqsZJ0CssOP1gUmrJg/viewform?usp=sf_link`} target='_blank' rel='noopener noreferrer' label="Feedback" icon={FiMessageSquare} />
              <NavButton as={NavLink} to={`logout`} label="Logout" icon={FiLogOut} />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          spacing={{
            base: '5',
            sm: '6',
          }}
          py="5"
        >
          <Divider />
          <HStack>
            <IconButton variant='tertiary' onClick={toggleColorMode} icon={<ToggleIcon />} />

            <UserProfile
              name={authState?.idToken?.claims?.name}
              id={me.id}
              image={me.slack_avatar_url}
              email={authState?.idToken?.claims?.email}
            />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  </Flex>
  )
}
