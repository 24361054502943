import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const LoggingCard = ({ innerRef, ...props }) => {
  return (
    <Formik initialValues={{ "deployment.logging.enabled": true, "deployment.logging.name": "logback-json" }} innerRef={innerRef}>
      <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
        <Stack
          spacing="5"
          px={{ base: "4", md: "6" }}
          py={{ base: "5", md: "6" }}
        >
          <Field name="['deployment.logging.name']">
            {({ field }) => (
              <FormControl>
                  <FormLabel>Log Format</FormLabel>
                  <RadioGroup {...field}>
                    <Stack spacing="5" direction="row">
                      <Radio {...field} value="logback-json">
                        JSON
                      </Radio>
                      <Radio {...field} value="logback">
                        Plain Text
                      </Radio>
                    </Stack>
                  </RadioGroup>
              </FormControl>
            )}
          </Field>
        </Stack>
      </Box>
    </Formik>
  )
}
