import {
  Button,
  Code,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack, HStack,
  useClipboard,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { config } from '../config'

function shortenedEnvironment(environment) {
  if (environment === 'production') {
    return 'prod'
  }
  return 'test'
}

export const NewCredentialsModal = ({ credentials, isOpen, onClose }) => {
  const connectionStringV2 = `cloud-sql-proxy ${credentials.connection_name} -i`;
  const [project, region] = credentials?.connection_name?.split(':') || [];
  const {onCopy:onCopyV2, hasCopied:hasCopiedV2} = useClipboard(connectionStringV2);
  const environment = shortenedEnvironment(config.environment)
  const connectionStringPfqlFDC = `pfql -e ${environment} -p ${project} -r ${region} <path to deployment.yaml>`;
  const {onCopy:onCopyPfqlFDC, hasCopied:hasCopiedPfqlFDC} = useClipboard(connectionStringPfqlFDC);
  const connectionStringPfqlHelm = `pfql -p ${project} -r ${region}  <path to values.${environment}.yaml>`;
  const {onCopy:onCopyPfqlHelm, hasCopied:hasCopiedPfqlHelm} = useClipboard(connectionStringPfqlHelm);

  return (
    <Modal size='xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Credentials Generated Successfully</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems='flex-start' spacing='15px'>
            <Heading size='md'><Flex>IAM Authentication</Flex></Heading>
            <Text>This database makes use of IAM authentication. IAM authentication uses your Google account to authenticate to the database.</Text>

            <Divider />
            <Text>Username: <Code>{credentials.username}</Code></Text>
            <Text>Password: IAM auth does not use a password</Text>

            <Text>You can connect to the Cloud SQL instance with one of the following command(s)</Text>

            <Text>Cloud SQL Proxy v2:</Text>
            <HStack>
              <Text><Code>{connectionStringV2}</Code></Text>
              <Button onClick={onCopyV2}>{hasCopiedV2 ? "Copied!" : "Copy"}</Button>
            </HStack>

            <Text>Dev Cli Tools (deployment.yaml):</Text>
            <HStack>
              <Text><Code>{connectionStringPfqlFDC}</Code></Text>
              <Button onClick={onCopyPfqlFDC}>{hasCopiedPfqlFDC ? "Copied!" : "Copy"}</Button>
            </HStack>

            <Text>Dev Cli Tools (values.yaml):</Text>
            <HStack>
              <Text><Code>{connectionStringPfqlHelm}</Code></Text>
              <Button onClick={onCopyPfqlHelm}>{hasCopiedPfqlHelm ? "Copied!" : "Copy"}</Button>
            </HStack>

            <Divider />
            <Text fontSize='xs'>Is this your first time connecting? Or do you need Cloud SQL Proxy installation instructions? Or do you need help using pfql? Check out
              the full guide at{' '}
              <Button
                size='xs' variant='text' as='a'
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.notion.so/figuretech/Use-CloudSQL-Proxy-to-Establish-Database-Connections-885195912ffe448eb92e7bfd2090b4b3'
              >
                Databases, CloudSQL Proxy, and You <ExternalLinkIcon mx='2px' />
              </Button>
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
