import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Code,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Link,
  Input,
  Select,
  useColorModeValue,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const ManualInputTab = ({ onSubmit, projects }) => {
  const buttonColorScheme = useColorModeValue('blue', 'green')

  const submitForm = (values, actions) => {
    onSubmit({
      project: values.project,
      app_name: values.appName,
      name: values.dbName,
      namespace: values.namespace,
      schema: values.schema,
      user: values.user,
      data_classification: values.dataClassification
    }, actions)
  }

  const validateRequiredField = (value) => {
    let error
    if (!value || value.length < 1) {
      error = 'Required'
    }
    return error
  }

  return (
    <Formik initialValues={{ project: '', dbName: '', schema: '', appName: '', namespace: '', user: '', dataClassification: '' }} onSubmit={submitForm}>
      {({ isSubmitting }) => (
        <Form>
          <Field name='appName' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.appName && form.touched.appName}>
                <FormLabel htmlFor='appName'>App Name</FormLabel>
                <Input {...field} type='text' id='appName' />
                <FormHelperText>This is the name your app. It usually matches the name of your repo. In your deployment.yaml, this is the <Code>metadata.name</Code> field.</FormHelperText>
                <FormErrorMessage>{form.errors.appName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name='project' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.project && form.touched.project}>
                <FormLabel htmlFor='project'>Project</FormLabel>
                <Select {...field} id='project' placeholder='Select a project'>
                  {projects.map((p) => (
                    <option key={p.project_id} value={p.project_id}>{p.display_name}</option>
                  ))}
                </Select>
                <FormHelperText>Pick which project your application will run in</FormHelperText>
                <FormErrorMessage>{form.errors.project}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name='namespace' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.namespace && form.touched.namespace}>
                <FormLabel htmlFor='namespace'>Namespace</FormLabel>
                <Input {...field} type='text' id='namespace' />
                <FormHelperText>The namespace your app is deployed in. In your deployment.yaml, this is the <Code>metadata.namespace</Code> field.</FormHelperText>
                <FormErrorMessage>{form.errors.namespace}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name='dbName' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.dbName && form.touched.dbName}>
                <FormLabel htmlFor='dbName'>Database Name</FormLabel>
                <Input {...field} type='text' id='dbName' />
                <FormHelperText>The database name. In your deployment.yaml, this is the <Code>spec.template.spec.deployment.db.name.prod</Code> field.</FormHelperText>
                <FormErrorMessage>{form.errors.dbName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name='user' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.user && form.touched.user}>
                <FormLabel htmlFor='user'>Username</FormLabel>
                <Input {...field} type='text' id='user' />
                <FormHelperText>The name of your app's database user. In your deployment.yaml, this is the <Code>spec.template.spec.deployment.db.user.prod</Code> field.</FormHelperText>
                <FormErrorMessage>{form.errors.user}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name='schema' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.schema && form.touched.schema}>
                <FormLabel htmlFor='schema'>Schema</FormLabel>
                <Input {...field} type='text' id='schema' />
                <FormHelperText>The schema name. In your deployment.yaml, this is the <Code>spec.template.spec.deployment.db.schema.prod</Code> field.</FormHelperText>
                <FormErrorMessage>{form.errors.schema}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name='dataClassification' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.dataClassification && form.touched.dataClassification}>
                <FormLabel htmlFor='dataClassification'>Data Classification</FormLabel>
                <Select {...field} id='dataClassification' placeholder='Select a Data Classification'>
                  <option key='confidential' value='confidential'>Confidential</option>
                  <option key='internal' value='internal'>Internal</option>
                </Select>
                <FormHelperText>Please see the <Link href='https://www.notion.so/figuretech/Data-Classification-122c06bd5c2e80b189d4c3c325b84785?pvs=4'>Data Classification</Link> page for more information.</FormHelperText>
                <FormErrorMessage>{form.errors.dataClassification}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Alert status='info' mb={5}>
            <AlertIcon />
            <AlertDescription>An instance name will be provided to you upon approval of your request.</AlertDescription>
          </Alert>

          <Button
            colorScheme={buttonColorScheme}
            isLoading={isSubmitting}
            type='submit'
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}
