import { useMemo } from 'react'
import {
    Box,
    Button,
    Icon,
    List,
    ListItem,
    Stack,
    Text,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiAlertTriangle, FiChevronRight } from 'react-icons/fi'
import { useGetNamespacesQuery } from '../../apis/vault-access-provider'
import { useGetTeamsQuery } from '../../apis/org-chart'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useOktaAuth } from '@okta/okta-react'

export const Namespaces = () => {
  const { data: namespaces = [], isLoading: isLoadingNamespaces } = useGetNamespacesQuery()
  const { data: teams = [], isLoading: isLoadingTeams } = useGetTeamsQuery()
  const { authState } = useOktaAuth()
  const isAdmin = authState?.accessToken?.claims.admin === true
  const buttonColorScheme = useColorModeValue('blue', 'green')

  const columns = useMemo(
    () => {
        const cols = [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Owned By',
                accessor: 'owned_by',
                Cell: ({ row }) => (
                    <List spacing={2}>
                        {row.original.owned_by.length > 0 ? (
                            teams.filter((team) => row.original.owned_by.includes(team.id))?.map((m) =>
                                <ListItem key={m.id}>
                                    <Button variant='text' as={Link} to={`/teams/details/${m.id}`}>{m.name}</Button>
                                </ListItem>
                            )
                        ) : (
                            <ListItem>
                                <Tooltip label='No owner assigned. If you own this namespace, or need access to this namespace, please reach out to DevOps to get an owner assigned'>
                                    <Box><FiAlertTriangle color='yellow' /></Box>
                                </Tooltip>
                            </ListItem>
                        )}
                    </List>
                ),
                id: 'owned_by',
            },
        ]
        if (isAdmin) {
            cols.push({
                Header: '',
                Cell: () => <Icon as={FiChevronRight} />,
                id: 'details_link',
            })
        }
        return cols
    },
  [teams, isAdmin])

  return (
    isLoadingNamespaces || isLoadingTeams ? (
        <LoadingSpinner />
    ) : (
        <DataTable
            data={namespaces}
            columns={columns}
            title='Namespaces'
            description={
                <Stack spacing="5">
                    <Text>Namespaces are Kubernetes namespaces that exist in any cluster.
                        Since namespaces are a global resource (shared across all clusters within an environment such as test, prod, etc.),
                        owning a namespace, or having access to one in Vault, means you have access across all clusters.
                        Access to a namespace in Vault can be requested from the <Button as={Link} variant='text' to={'/access/new'}>My Access <ExternalLinkIcon /></Button> page.
                    </Text>

                    <Text>Please reach out to the DevOps team if you need access to a namespace that does not have an owner assigned.</Text>

                    {isAdmin && (
                        <Box><Button as={Link} to={`new`} colorScheme={buttonColorScheme}>Create New Namespace</Button></Box>
                    )}
                </Stack>
            }
            sortByField='name'
            sortDesc={false}
            enableRowNavigation={isAdmin}
        />
    )
  )
}
