import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Code,
  Flex,
  Grid,
  GridItem, HStack,
  Icon,
  List,
  ListIcon,
  ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  Spinner,
  Tag,
  Text, Tooltip, useColorModeValue, useDisclosure, useToast
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useGetUsersQuery } from '../../apis/org-chart'
import {
  useGetBackupsByDatabaseIdQuery,
  useGetDatabaseByIdQuery,
  useGetInstanceByIdQuery,
  usePostBackupMutation
} from '../../apis/database-builder'
import { useGetRequestByIdQuery } from '../../apis/approval-engine'
import {FiAlertTriangle, FiChevronRight} from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { approversTransform } from '../../utils/approversTransform'
import { DataTable } from '../../components/DataTable'
import { useMemo } from 'react'
import { config } from '../../config'

export const DatabaseDetails = () => {
  const { id } = useParams()
  const autoImporterId = 'auto-importer'
  const { data: database = {}, isLoading: isLoadingDatabase, isError: isErrorDatabase } = useGetDatabaseByIdQuery(id)
  const { data: approvalRequest = {}, isLoading: isLoadingApprovalRequest, isError: isErrorApprovalRequest } = useGetRequestByIdQuery(database.approval_id || 'undefined', { skip: database.approval_id === undefined || database.approval_id === '' || config.environment === 'test'})
  const { data: instance = {}, isLoading: isLoadingInstance } = useGetInstanceByIdQuery(database.instance_id, { skip: database.instance_id === undefined })
  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersQuery()
  const { data: backups = [], isLoading: isLoadingBackups } = useGetBackupsByDatabaseIdQuery(id)
  const [ postBackup ] = usePostBackupMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const buttonColorScheme = useColorModeValue('blue', 'green')

  const getApprovers = () => {
    if (isLoadingDatabase || isLoadingApprovalRequest || isLoadingUsers || isErrorDatabase || isErrorApprovalRequest) {
      return {}
    }
    if (database.requester_id === autoImporterId || config.environment === 'test') {
      return { DevOps: [{ uid: 'DevOps', status: 'approved', comment: '' }] }
    }

    return approversTransform(approvalRequest, users)
  }

  const approvalRequestStatus = () => {
    if (database.requester_id === autoImporterId || config.environment === 'test') {
      return 'approved'
    }
    return approvalRequest.status
  }

  const recommendedSettings = () => {
    return [
      { path: 'spec.template.spec.deployment.db.enabled', value: 'true', description: 'This field enables the use of a database in both test and prod' },
      { path: 'spec.template.spec.deployment.db.instance', value: instance?.name, description: 'The instance field specifies the name of the instance to connect to. This is a production-only field' },
      { path: 'spec.template.spec.deployment.db.name.prod', value: database?.name, description: 'This is the name of your database on the given instance' },
      { path: 'spec.template.spec.deployment.db.schema.prod', value: database?.schema, description: 'This is the name of your schema within the given database and instance' },
      { path: 'spec.template.spec.deployment.db.user.prod', value: database?.app_user, description: 'This is the name of your application\'s user on the given instance' },

      { optional: true, path: 'spec.template.spec.deployment.db.enablePgBouncer', value: 'false', description: 'Unless your application is taking advantage of specific pgbouncer features, it is recommend you disable it as it is typically unnecessary. This recommendation is optional' },
      // { optional: true, path: 'spec.template.spec.deployment.db.version', value: "'14'", description: 'The version field dictates what image tag to use for your postgres database container in test clusters only. This field does not impact production. It is recommend that new applications use version 14 as that is the version your production database will be running. Keep in mind that you CANNOT change this value after initial deployment without performing a manual migration. This recommendation is optional' }
    ]
  }

  const columns = useMemo(
    () => [
        {
            Header: 'deployment.yaml path',
            accessor: (row, _) => {
              return <>{row.optional && (<Tag colorScheme='orange'>OPTIONAL</Tag>)} <Code>{row.path}</Code></>
            },
            id: 'deployment-yaml-path',
        },
        {
            Header: 'Value',
            accessor: (row, _) => <Code>{row.value}</Code>,
            id: 'value',
        },
        {
            Header: 'Description',
            accessor: 'description',
        }
    ],
  [])

  const columnsBackups = useMemo(
    () => [
      {
        Header: 'Started At',
        // accessor: 'started_at',
        accessor: (row, _) => new Date(row.started_at * 1000).toLocaleString(),
        id: 'started_at',
      },
      {
        Header: 'Completed At',
        accessor: (row, _) => row.completed_at? new Date(row.completed_at * 1000).toLocaleString() : "",
        id: 'completed_at'
      },
      {
        Header: 'Backup Id',
        accessor: 'id',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          return <HStack>
            <Icon {...iconPropsFromStatus(row.original.status)} />
            <Text>{row.original.status}</Text>
            {row.original.error && (
              <Tooltip label='An error occurred processing this request. Click for more details'>
                <Box>
                  <FiAlertTriangle color='yellow' />
                </Box>
              </Tooltip>
            )}
          </HStack>
        },
        id: 'status',
      },
      {
        Header: '',
        Cell: () => <Icon as={FiChevronRight} />,
        id: 'details_link',
      }
    ],[]
  )

  const submitBackup = async () => {
    const resp = await postBackup(id)
    onClose()
    if (resp.error && resp.error.data) {
      toast({
        title: `backup failed`,
        description: resp.error.data || resp.error.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      toast({
        title: `backup submitted successfully`,
        status: 'success',
        duration: 7000,
        isClosable: true
      })
    }
  }

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Databases</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex mt={5} justifyContent='space-between'>
        <Text fontSize="2xl" fontWeight="medium">
            {isLoadingDatabase ? 'Details' : database.name}
        </Text>
      </Flex>

      {isLoadingApprovalRequest ? (
        <Spinner />
      ) : (
        <Box mt={5}>
          <Grid templateColumns='repeat(2, 1fr)'>
            <Grid templateColumns='200px 1fr' gap={2}>
              <GridItem as='b'><Text align='right' pr={5}>Database Status</Text></GridItem><GridItem><Text><Icon {...iconPropsFromStatus(database.status)} /> {database.status}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>App Name</Text></GridItem><GridItem><Text>{database.app_name}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>User</Text></GridItem><GridItem><Text>{database.app_user}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Namespace</Text></GridItem><GridItem><Text>{database.namespace}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Project</Text></GridItem><GridItem><Text>{database.project}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Schema</Text></GridItem><GridItem><Text>{database.schema}</Text></GridItem>
            </Grid>

            <Grid templateColumns='200px 1fr' gap={2}>
              <GridItem as='b'><Text align='right' pr={5}>Requester</Text></GridItem><GridItem><Text>{users.find((u) => u.id === database.requester_id)?.email || database.requester_id}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Requested Date</Text></GridItem><GridItem><Text>{new Date(database.requested_date * 1000).toDateString()}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Approval Status</Text></GridItem><GridItem><Text><Icon {...iconPropsFromStatus(approvalRequestStatus())} /> {approvalRequestStatus()}</Text></GridItem>
              {approvalRequestStatus() !== 'approved' && (
                <>
                  <GridItem as='b'><Text align='right' pr={5}>Expires</Text></GridItem><GridItem><Text>{new Date(approvalRequest.request_expiration * 1000).toDateString()}</Text></GridItem>
                </>
              )}
              {approvalRequest.error && (
                <>
                  <GridItem as='b'><Text align='right' pr={5}>Error</Text></GridItem><GridItem><Text>{approvalRequest.error}</Text></GridItem>
                </>
              )}
              <GridItem as='b'><Text align='right' pr={5}>Approvals</Text></GridItem>
              <GridItem>
                {Object.entries(getApprovers()).map(([groupName, approvers]) => (
                  <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(2, 1fr)' key={groupName}>
                    <GridItem rowSpan={2}>
                      <Text as='i'>{groupName}</Text>
                    </GridItem>
                    <GridItem>
                      <List spacing={3}>
                        {approvers.map((a) => (
                          <ListItem key={a.uid}>
                            <ListIcon {...iconPropsFromStatus(a.status)} /> {a.email} <i>{a.comment ? a.comment : a.status !== 'denied' ? '' : 'no comment was provided'}</i>
                          </ListItem>
                        ))}
                      </List>
                    </GridItem>
                  </Grid>
                ))}
              </GridItem>
            </Grid>
          </Grid>

          <Box mt={5}>
            {isLoadingInstance ? (
              <Spinner />
            ) : database.status === 'denied' ? (
              <Text>This database request has been denied. Please review the comments from the reviewers and resubmit your request if necessary</Text>
            ) : !database.instance_id ? (
              <Text>This database has not yet been assigned to an instance. Check back later for <Code>deployment.yaml</Code> updates</Text>
            ) : (
              <List spacing={5}>
                <ListItem>
                  <Text>This database has been assigned to the instance <Code>{instance.name}</Code>. Please update your <Code>deployment.yaml</Code> to match the settings below.</Text>
                </ListItem>
                <ListItem mx={{ base: '-4', md: '-6' }}>
                  <DataTable
                    data={recommendedSettings()}
                    columns={columns}
                    sortByField='deployment-yaml-path'
                    sortDesc={false}
                    enableSearch={false}
                    enableRowNavigation={false}
                  />
                </ListItem>
              </List>
            )}
          </Box>

          <Box mt={5}>
            {isLoadingBackups ? (
              <Spinner />
            ) : (
              <List spacing={5}>
                <ListItem mx={{ base: '-4', md: '-6' }}>
                  <DataTable
                    data={backups}
                    columns={columnsBackups}
                    title='Backups'
                    sortByField='started_at'
                    sortDesc={false}
                    enableSearch={false}
                    enableRowNavigation={true}
                    navigationUrlPrefix='/backups/details/'
                    description={<Button onClick={() => onOpen()} colorScheme={buttonColorScheme}>Request Backup</Button>}
                  />
                </ListItem>
              </List>
            )}
          </Box>
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Are you sure you want to perform a backup on {database?.name}?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={() => submitBackup()}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
