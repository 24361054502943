import { useGetRequestByApprovalIdQuery, useGetNamespaceByIdQuery } from '../../apis/vault-access-provider'
import {
    Grid,
    GridItem,
    Icon,
    Spinner,
    Text,
} from '@chakra-ui/react'
import { iconPropsFromStatus } from '../../utils/status'

export const TeamAccessDetailsVault = ({ approvalId }) => {
  const { data: accessRequest = {}, isLoading: isLoadingAccessRequest } = useGetRequestByApprovalIdQuery(approvalId)
  const { data: namespace = {}, isLoading: isLoadingNamespace } = useGetNamespaceByIdQuery(accessRequest.namespace_id || 'undefined', { skip: accessRequest.namespace_id === undefined })

  return (
    isLoadingAccessRequest || isLoadingNamespace ? (
      <Spinner />
    ) : (
      <Grid templateColumns='200px 1fr' gap={2}>
        <GridItem as='b'><Text align='right' pr={5}>Status</Text></GridItem><GridItem><Text><Icon {...iconPropsFromStatus(accessRequest.status)} /> {accessRequest.status}</Text></GridItem>
        <GridItem as='b'><Text align='right' pr={5}>Namespace</Text></GridItem><GridItem><Text>{namespace.name}</Text></GridItem>
        <GridItem as='b'><Text align='right' pr={5}>Reason</Text></GridItem><GridItem><Text>{accessRequest.business_justification}</Text></GridItem>
      </Grid>
    )
  )
}
