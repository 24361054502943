import { useEffect } from 'react'
import {
  Box,
  Container,
  Flex,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { Navbar } from './components/Navbar'
import { Sidebar } from './components/Sidebar'
import { Navigate, Outlet } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { LoadingSpinner } from './components/LoadingSpinner'
import { useDispatch } from 'react-redux'
import { setCredentials } from './apis/authSlice'

export const App = () => {
  const { toggleColorMode } = useColorMode()
  const { oktaAuth, authState } = useOktaAuth()
  const dispatch = useDispatch()

  const updateAccessToken = () => {
    const accessToken = oktaAuth.getAccessToken()
    dispatch(setCredentials({token: accessToken}))
  }

  useEffect(() => {
    updateAccessToken()
    const interval = setInterval(() => {
      updateAccessToken()
    }, 10000); // every 10s

    return () => clearInterval(interval);
  })

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  }, 'lg')

  const borderRadius = useBreakpointValue({
    base: 'none',
    md: 'lg',
  })

  const boxShadow = useColorModeValue('sm', 'sm-dark')

  if (!authState) {
    return <LoadingSpinner />
  }

  return (
    <Flex
      as="section"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      height="100vh"
      bg="bg.canvas"
      // overflowY="auto"
    >
      {isDesktop ? <Sidebar toggleColorMode={toggleColorMode} /> : <Navbar />}

      <Box width="full" overflowY="auto">
        <Container
          py={{
            base: '4',
            md: '8',
          }}
          px={{
            base: '0',
            md: 8,
          }}
        >
          <Box
            bg="bg.surface"
            boxShadow={{
              base: 'none',
              md: boxShadow,
            }}
            borderRadius={borderRadius}
          >
            {authState?.isAuthenticated ? <Outlet /> : <Navigate to={`/login`} />}
          </Box>
        </Container>
      </Box>
    </Flex>
  )
}
