import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Code,
    Flex,
    Grid,
    Heading,
    Icon,
    SimpleGrid,
    Spacer,
    Spinner,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useGetAppByIdQuery, useGetAppYamlQuery, useGetTeamByIdQuery } from '../../apis/org-chart'
import { FiChevronRight } from 'react-icons/fi'
import { MdStarRate } from 'react-icons/md'
import { SiArgo, SiDatadog, SiGithub, SiKibana } from "react-icons/si";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import dracula from 'react-syntax-highlighter/dist/esm/styles/hljs/dracula';
import github from 'react-syntax-highlighter/dist/esm/styles/hljs/github';
import yaml from 'react-syntax-highlighter/dist/esm/languages/hljs/yaml';
import { AppTypeTag } from '../../components/AppTypeTag';

SyntaxHighlighter.registerLanguage('yaml', yaml);

export const AppDetails = () => {
  const { id } = useParams()
  const { data: app = {}, isLoading: isLoadingApp } = useGetAppByIdQuery(id)
  const { data: yamlContent = '' } = useGetAppYamlQuery(id)
  const { data: team = {}, isLoading: isLoadingTeam } = useGetTeamByIdQuery(app.owning_team_id, { skip: app.owning_team_id === undefined || app.owning_team_id === '' })

  const syntaxTheme = useColorModeValue(github, dracula)

  const datadogEnvFromCluster = (clusters) => {
    if (!clusters || clusters.length === 0) {
      return ''
    }

    switch(clusters[0]) {
      case "figure-dataeng-test":
        return "dataeng-test"
      case "figure-dataeng-prod":
        return "dataeng-prod"
      case "figure-test":
        return "development"
      case "figure-prod":
        return "production"
      case "figure-tech-prod":
        return "figure-tech"
      case "shs-test":
        return "shared-services-test"
      case "shs-prod":
        return "shared-services"
      default:
        return clusters[0]
    }
  }

  const clusterIsTestEnv = (clusters) => {
    if (!clusters || clusters.length === 0) {
      return false
    }

    switch(clusters[0]) {
      case "devops-sandbox":
        return true
      case "figure-data-dev":
        return true
      default:
        return clusters[0].endsWith("-test")
    }
  }

  const appHasOwner = !isLoadingTeam && team?.id

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Apps</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex mt={5} justifyContent='space-between'>
        <Text fontSize="2xl" fontWeight="medium">
            {isLoadingApp ? 'Details' : app.name}
        </Text>
      </Flex>

      {isLoadingApp ? (
        <Spinner />
      ) : (
        <Flex mt={5} direction='column' spacing='100'>

          <Text as='b'>This <AppTypeTag isHelmChart={app.helm_chart} /> {app.helm_chart && (`(version ${app.helm_chart_version})`)} is deployed to</Text>

          <Grid mt='5' templateColumns='repeat(4, 1fr)' gap={6}>
            {app.clusters.map((c) => (
              <VStack key={c} spacing='10px'>
                <Avatar size='2xl' name={c.replace('-', ' ')} />
                <Text>{c}</Text>
                <Text fontSize='xs'>namespace: {app.namespace}</Text>
              </VStack>
            ))}
          </Grid>

          <SimpleGrid mt='5' spacing='50' templateColumns='repeat(auto-fill, minmax(300px, 1fr))'>
            {[
              (app.helm_chart ?
                {title: 'ArgoCD', description: 'View and manage your deployments in the Argo UI.', url: `https://argocd${clusterIsTestEnv(app.clusters) ? '.test' : ''}.figure.com/applications/argocd/${app.name}`, icon: SiArgo, buttonText: 'Open in Argo', isExternal: true} :
                {title: 'Convert to a Helm Chart', description: 'Learn how to convert this app to a Helm chart and take advantage of increased observability of your application\'s health through ArgoCD.', url: `https://docs.devops.figure.com/helm-charts/converting`, icon: SiArgo, buttonText: 'Read the docs', isExternal: true}
              ),
              {title: 'Owner', description: isLoadingTeam ? 'Loading...' : appHasOwner ? <Text>This app is owned by the <Code>{team.name}</Code> team.</Text> : 'This app\'s owner could not be identified. Check out', url: appHasOwner ? `/teams/details/${team?.id}` : 'https://docs.devops.figure.com/frontend-devops-portal/devops-portal-apps#app-ownership', avatar: team?.avatar_url, buttonText: appHasOwner ? 'View team details' : 'Learn to assign an owner', isExternal: !appHasOwner},
              {title: 'Operational Readiness Score', description: 'See your application\'s operational readiness score and if you\'re up to date on all the latest best practices. Coming soon!', url: ``, icon: MdStarRate, buttonText: 'View score details', isExternal: false, buttonProps: {isDisabled: true}},
              {title: 'Datadog', description: 'View metrics in Datadog\'s APM dashboard for this app.', url: `https://app.datadoghq.com/apm/services/${app.name}?env=${datadogEnvFromCluster(app.clusters)}`, icon: SiDatadog, buttonText: 'Open in Datadog', isExternal: true},
              {title: 'Kibana', description: 'View this application\'s logs in Kibana.', url: `https://kibana${clusterIsTestEnv(app.clusters) ? '.test' : ''}.figure.com/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&_a=(columns:!(),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,key:kubernetes.container_name,negate:!f,params:(query:${app.name}),type:phrase),query:(match_phrase:(kubernetes.container_name:${app.name})))),interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',desc)))`, icon: SiKibana, buttonText: 'Open in Kibana', isExternal: true},
              {title: 'GitHub Repo', description: app.github_repo ? <Text>Open the <Code>{app.github_repo}</Code> repo in GitHub.</Text> : 'This app\'s repo could not be identified.', url: `https://github.com/FigureTechnologies/${app.github_repo}`, icon: SiGithub, buttonText: 'Open in GitHub', isExternal: true, buttonProps: {isDisabled: !app.github_repo}},
            ].map((item, id) => (
              <Card key={id}>
                <CardHeader>
                  <Flex>
                    <Heading size='sm'> {item.title}</Heading>
                    <Spacer />
                    {item.icon ? (
                      <Icon as={item.icon} boxSize={10} />
                    ) : item.avatar ? (
                      <Avatar mb='2' size='lg' src={item.avatar} />
                    ) : (<></>)}
                  </Flex>
                </CardHeader>
                <CardBody>
                  {typeof item.description === 'string' ? (
                      <Text>{item.description}</Text>
                  ) : (
                      item.description
                  )}
                </CardBody>
                <CardFooter>
                  <Button {...item.buttonProps} as={Link} to={item.url} label={item.buttonText} {...(item.isExternal ? {target: '_blank', rel: 'noopener noreferrer', rightIcon: <ExternalLinkIcon />} : {})}>{item.buttonText}</Button>
                </CardFooter>
              </Card>
            ))}
          </SimpleGrid>

          <Accordion mt='5' allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    Expand to see this app's {app.helm_chart ? 'values file' : 'deployment.yaml'}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <SyntaxHighlighter language="yaml" style={syntaxTheme}>
                  {yamlContent || "# No data"}
                </SyntaxHighlighter>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      )}
    </Box>
  )
}
