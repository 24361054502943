import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Stack,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react'
import { OktaIcon } from '../../components/OktaIcon'
import { Logo } from '../../components/Logo'
import { useOktaAuth } from '@okta/okta-react'
import { Navigate } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { gradientBackgroundDark } from '../../utils/color'

export const Login = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const bg = useBreakpointValue({ base: 'transparent', sm: 'bg.surface' })
  const fill = useColorModeValue("#000000", "#FFFFFF")

  if (!authState) {
    // the okta SDK isn't the fastest thing. they recommend some sort of loading indicator
    // to show while the SDK reads in the local cache and deconstructs the JWT token.
    // this doesn't mean the user is logged in or logged out, just that the SDK is figuring that out.
    return (
        <LoadingSpinner />
    )
  } else if (!authState.isAuthenticated) {
    // user is logged out so show the login page
    return (
        <Box bgGradient={{ sm: gradientBackgroundDark }} h='100vh' w='100vw' py={{ base: '12', md: '24' }}>
            <Flex h='full' w='full' align='center'>
                <Container
                    maxW="md"
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={bg}
                    boxShadow={{ base: 'none', sm: 'xl' }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                    <Stack spacing="8">
                        <Stack spacing="6" align="center">
                            <Logo />
                            <Stack spacing="3" textAlign="center">
                                <Heading size="xs">Log in to your account</Heading>
                            </Stack>
                        </Stack>
                        <Stack spacing="6">
                            <Button onClick={() => oktaAuth.signInWithRedirect()} variant="secondary" leftIcon={<OktaIcon fill={fill} boxSize="5" />} iconSpacing="3">
                                Continue with Okta
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
            </Flex>
        </Box>
    )
  }

  // if already logged in, redirect to the dashboard
  return (
    <Navigate to={`/`} />
  )
}
