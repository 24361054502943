import { useMemo } from 'react'
import {
    Box,
    Button,
    HStack,
    Icon,
    Text,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiAlertTriangle, FiChevronRight } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { useGetDatabasesQuery, useGetInstancesQuery } from '../../apis/database-builder'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'

export const Databases = () => {
  const { data: databases = [], isLoading: isLoadingDatabases } = useGetDatabasesQuery()
  const { data: instances = [], isLoading: isLoadingInstances } = useGetInstancesQuery()

  const buttonColorScheme = useColorModeValue('blue', 'green')

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'App',
            accessor: 'app_name',
        },
        {
            Header: 'Namespace',
            accessor: 'namespace',
        },
        {
            Header: 'Instance',
            accessor: (row, _) => instances.find(i => i.id === row.instance_id)?.name || 'pending',
            id: 'instance',
        },
        {
            Header: 'Project',
            accessor: 'project',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => {
                return <HStack>
                    <Icon {...iconPropsFromStatus(row.original.status)} />
                    <Text>{row.original.status}</Text>
                    {row.original.error && (
                    <Tooltip label='An error occurred processing this request. Click for more details'>
                        <Box>
                            <FiAlertTriangle color='yellow' />
                        </Box>
                    </Tooltip>
                    )}
                </HStack>
            },
            id: 'status',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [instances])

  return (
    isLoadingDatabases || isLoadingInstances ? (
        <LoadingSpinner />
    ) : (
        <DataTable
            data={databases}
            columns={columns}
            title='Databases'
            description={<Button as={Link} to={`request`} colorScheme={buttonColorScheme}>Request New Database</Button>}
            sortByField='name'
            sortDesc={false}
        />
    )
  )
}
