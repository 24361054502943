// to make the layout easier, approversTransform transforms the two fields we get back from the api:
//
// required_approvals: {DevOps: {approvers: ["u1", "u2"], require_all: false}}
// current_decisions: {"u1": {status: "approved", comment: ""}, "u2": {status: "pending", comment: ""}}
//
// into a single entity
//
// {DevOps: [{uid: "u1", status: "approved", comment: ""}, {uid: "u2", status: "pending", comment: ""}]}
export const approversTransform = (req, users) => {
  const data = {}
  let filter = false
  // if the overall status of the request is approved or denied, only show those
  // who are not still "pending". otherwise show everyone
  // const data = { ...approvalRequest.required_approvals }
  if (req.status !== 'pending') {
    filter = true
  }

  Object.entries(req.required_approvals).forEach(([groupName, group]) => {
    const decisions = []
    group.approvers.filter((uid) => !filter || req.current_decisions[uid].status !== 'pending').forEach((uid) => {
      decisions.push({ uid: uid, email: (users.find((u) => u.id === uid)?.email || uid), status: req.current_decisions[uid].status, comment: req.current_decisions[uid].comment })
    })
    data[groupName] = decisions
  })
  return data
}
