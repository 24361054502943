import { chakra, useColorModeValue } from '@chakra-ui/react'

export const Logo = (props) => {
  let fill = useColorModeValue("#1A1B22", "#FFFFFF")
  let gradientPathFill = useColorModeValue("#23d5ab", "#973ce7")
  let gradientTextFill = useColorModeValue("url(#light)", "url(#dark)")

  return (
    <chakra.svg
      color="accent"
      height="8"
      width="auto"
      viewBox="0 0 131 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient id='light' x1='1' x2='0'>
          <stop stopColor='#ea6338' />
          <stop offset='.333' stopColor='#973ce7' />
          <stop offset='.667' stopColor='#23a6d5' />
          <stop offset='1' stopColor='#23d5ab' />
        </linearGradient>
        <linearGradient id='dark'>
          <stop stopColor='#973ce7' />
          <stop offset='.333' stopColor='#23d5ab' />
          <stop offset='.667' stopColor='#23a6d5' />
          <stop offset='1' stopColor='#ea6338' />
        </linearGradient>
      </defs>
      <path fill={props.gradient === "true" ? gradientPathFill : fill} fillRule="evenodd" clipRule="evenodd" d="M0 0.34375H10.2365V3.73295H0V0.34375ZM0 17.3187V7.13493H10.2365V10.5241H3.40676V17.3187H0Z"></path>
      <text fill={props.gradient === "true" ? gradientTextFill : fill} x="20" y="14" fontFamily="Avenir" fontSize="16" fontWeight="bold">DevOps Portal</text>
    </chakra.svg>
  )
}
