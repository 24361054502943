import {
    Box,
    Container,
    Stack,
    Tabs,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react'
import { parseDocument, stringify } from 'yaml'
import { Step } from '../../../components/steps/Step'
import { useStep } from '../../../components/steps/useStep'
import { AppInfo } from './AppInfo'
import { Deployment } from './Deployment'
import { Database } from './Database'
import { Kafka } from './Kafka'
import { Routing } from './Routing'
import { Finish } from './Finish'
import { Manifest } from './Manifest'

export const steps = [
    {
      title: 'App Info',
    },
    {
      title: 'Deployment',
    },
    {
      title: 'Database',
    },
    {
      title: 'Kafka',
    },
    {
      title: 'Routing',
    },
    {
      title: 'Finish',
    },
]

const valuesYamlDoc = parseDocument(`name: TODO
namespace: TODO
environment: TODO
project: TODO
businessUnit: TODO
businessProduct: TODO

########################
# DEPLOYMENT
########################
deployment:
  enabled: true
  replicas: 1
  port: 8080

  image:
    repository: us-east1-docker.pkg.dev/figure-development/figure-docker-repo/TODO
    tag: latest

  config: {}

  logging:
    enabled: true
    name: logback-json

  livenessProbe:
    httpGet:
      path: /TODO
      port: 8080
    failureThreshold: 3
    periodSeconds: 10

  readinessProbe:
    httpGet:
      path: /TODO
      port: 8080
    failureThreshold: 3
    periodSeconds: 10

  resources:
    limits:
      cpu: '3'
      memory: 2Gi
    requests:
      cpu: '1'
      memory: 1Gi

########################
# DATADOG
########################
datadog:
  enabled: true

########################
# VAULT
########################
vault:
  enabled: true

########################
# CROSS CUTTER
########################
crossCutter:
  enabled: false

########################
# KAFKA
########################
kafka:
  enabled: true

  consumers: []

  producers: []

  topics: []

########################
# DATABASE
########################
database:
  enabled: true
  type: proxy
  name: myapp
  schema: myapp
  user: myapp

  proxy:
    instance: unknown
    project: unknown
    region: unknown

  migrations:
    enabled: true
    image:
      repository: us-east1-docker.pkg.dev/figure-development/figure-docker-repo/TODO-migrate
      tag: latest

########################
# ROUTING
########################
routing:
  routes:
    - name: 'TODO-secure'
      external:
        path: '/TODO/api/v1'
        protocols: https
      internal:
        path: '/api/v1'
        protocols: http
      plugins:
        - type: jwt
          config:
            claims_to_include:
            - uuid
            - roles
            - email
            - groups
            - org
            - memberships
            - puid
            - addr
            - grp
            cookie_name: test-jwt
            fail_on_no_cookie: false
            include_claims_downstream: true
`)

export const AppBuilderController = () => {
    const [currentStep, { setStep, goToNextStep, goToPrevStep }] = useStep({
        maxStep: steps.length,
        initialStep: 0,
    })

    const getYamlField = (path, stringifyValue=true) => {
      const val = valuesYamlDoc.getIn(path.split('.'))
      if (stringifyValue) {
        return stringify(val)
      }
      return val
    }

    const setYamlField = (path, value) => {
      valuesYamlDoc.setIn(path.split('.'), value)
    }

    const deleteYamlField = (path) => {
      valuesYamlDoc.deleteIn(path.split('.'))
    }

    const stringifyYaml = () => {
      return stringify(valuesYamlDoc)
    }

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
        bg="bg.surface"
    >
        <Container
            py={{ base: '4', md: '8' }}
        >
            <Stack
                spacing="0"
                direction={{ base: 'column', md: 'row' }}
            >
            {steps.map((step, id) => (
                <Step
                    key={id}
                    cursor="pointer"
                    onClick={() => setStep(id)}
                    title={step.title}
                    description={step.description}
                    isActive={currentStep === id}
                    isCompleted={currentStep > id}
                    isFirstStep={id === 0}
                    isLastStep={steps.length === id + 1}
                />
            ))}
            </Stack>
        </Container>

        <Tabs index={currentStep}>
          <TabPanels>
            <TabPanel>
              <AppInfo setYamlField={setYamlField} deleteYamlField={deleteYamlField} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
            </TabPanel>
            <TabPanel>
              <Deployment setYamlField={setYamlField} deleteYamlField={deleteYamlField} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
            </TabPanel>
            <TabPanel>
              <Database setYamlField={setYamlField} deleteYamlField={deleteYamlField} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
            </TabPanel>
            <TabPanel>
              <Kafka setYamlField={setYamlField} deleteYamlField={deleteYamlField} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
            </TabPanel>
            <TabPanel>
              <Routing setYamlField={setYamlField} deleteYamlField={deleteYamlField} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
            </TabPanel>
            <TabPanel>
              <Finish getYamlField={getYamlField} setYamlField={setYamlField} deleteYamlField={deleteYamlField} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
            </TabPanel>
            <TabPanel>
              <Manifest
                name={getYamlField("name")}
                data={{
                  [getYamlField("environment")]: {
                    yaml: stringifyYaml(),
                    errors: [],
                    warnings: [],
                  },
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
    </Box>
  )
}
