import { useState } from 'react'
import { useCreateNexusAccessRequestMutation } from '../../apis/tops-service'
import {
    Box,
    Button,
    Code,
    IconButton,
    Link,
    Stack,
    Text,
    useToast,
    useClipboard,
    useColorModeValue,
    HStack,
} from '@chakra-ui/react'
import { useOktaAuth } from '@okta/okta-react'
import { FiCopy, FiEye, FiEyeOff, FiRotateCcw } from 'react-icons/fi'

export const Nexus = () => {
  const { authState } = useOktaAuth()
  const firstName = authState.idToken.claims.firstName
  const lastName = authState.idToken.claims.lastName

  const toast = useToast()
  const [createNexusAccount, {isLoading}] = useCreateNexusAccessRequestMutation()
  const [showPassword, setShowPassword] = useState(false)
  const { onCopy, value: password, setValue: setPassword, hasCopied } = useClipboard('')

  const buttonColorScheme = useColorModeValue('blue', 'green')

  const newNexus = async (firstName, lastName) => {
    const resp = await createNexusAccount({ firstName: firstName, lastName: lastName })
    if (resp.error && resp.error.data) {
      toast({
        title: 'Failed to create your nexus user.',
        description: resp.error.data.error || resp.error.data,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      setPassword(resp.data.password)
      setShowPassword(true)
    }
  }

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
        <Stack spacing="5">
            <Text fontSize="2xl" fontWeight="medium">
                Nexus Access
            </Text>

            <Stack spacing="5">
                <Text>Nexus is Figure's repository manager. You can use this page to reset your password. If you don't have an account, one will be created automatically.</Text>

                <Stack>
                    <Text>
                        URL: <Button as={Link} variant='text' href={`https://nexus.figure.com`} target='_blank' rel='noopener noreferrer' label="Documentation">https://nexus.figure.com</Button>
                    </Text>

                    <Text>Username: {authState.accessToken.claims.sub.split('@')[0]}</Text>

                    <HStack>
                        <Text>Password: <Code>{showPassword ? password : '******'}</Code></Text>
                        {password === '' ? (
                            <IconButton ml="3" aria-label='Reset your password' isLoading={isLoading} variant='ghost' icon={<FiRotateCcw />} colorScheme={buttonColorScheme} onClick={() => newNexus(firstName, lastName)} />
                        ) : (
                            <>
                                <IconButton ml="3" variant='ghost' icon={showPassword ? <FiEyeOff /> : <FiEye />} colorScheme={buttonColorScheme} onClick={() => setShowPassword(!showPassword)} />
                                <Button ml="1" variant='ghost' leftIcon={<FiCopy />} colorScheme={buttonColorScheme} onClick={onCopy}>{hasCopied ? "Copied!" : "Copy"}</Button>

                            </>
                        )}
                    </HStack>
                    {password !== '' && (
                        <Text color="fg.muted" fontSize="xs">
                            This password will not be shown again, so make sure you save it in a secure location. If you do lose it, you can reset it
                            again at any time.
                        </Text>
                    )}
                </Stack>
            </Stack>
        </Stack>
    </Box>
  )
}
