import { useState } from 'react'
import {
    Box,
    Center,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { IoArrowUp, IoArrowDown } from 'react-icons/io5'
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { Pagination } from './Pagination'
import { useNavigate } from 'react-router-dom'

// data and columns MUST be memoized (see useMemo)
export const DataTable = ({
    data,
    columns,
    title,
    description,
    sortByField,
    sortDesc = true,
    pageSize = 30,
    enableSearch = true,
    enableRowNavigation = true,
    navigationUrlPrefix = 'details/',
    navigationIdField = 'id',
    noDataText = 'No data was found',
    hiddenColumns = [] }) => {

    const navigate = useNavigate()

    const tbl = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: sortByField,
                        desc: sortDesc
                    }
                ],
                pageSize: pageSize,
                hiddenColumns: hiddenColumns,
            },
            // react-table allows sorting in ascending order, descending order, or no order (whatever order the data is given in)
            // disableSortRemove disallows the "no order" option as it's not very useful.
            disableSortRemove: true,
        },
        useGlobalFilter,
        useSortBy,
        usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,

        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, globalFilter },

        setGlobalFilter,
    } = tbl

    const [search, setSearch] = useState(globalFilter)
    const onSearchChange = useAsyncDebounce(search => {
        setGlobalFilter(search || undefined)
    }, 200)

    const getRowNavigationProps = (id) => {
        if (enableRowNavigation) {
            let prefix = navigationUrlPrefix
            if (!prefix.endsWith("/")) {
                prefix = prefix + "/"
            }
            const to = prefix + id
            return {cursor: 'pointer', onClick: () => navigate(to)}
        }
        return {}
    }

    return (
        <Stack spacing="5">
            {(title || description || enableSearch) && (
                <Box
                    px={{ base: '4', md: '6' }}
                    pt="5"
                >
                    <Stack
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        justify="space-between"
                    >
                        <Text fontSize="2xl" fontWeight="medium">
                            {title}
                        </Text>
                        {enableSearch && (
                            <InputGroup colorScheme='gray' maxW="xs">
                                <InputLeftElement pointerEvents="none">
                                    <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                                </InputLeftElement>
                                <Input colorScheme='gray' placeholder="Search" value={search || ""} onChange={e => {
                                    setSearch(e.target.value)
                                    onSearchChange(e.target.value)
                                }} />
                            </InputGroup>
                        )}
                    </Stack>
                    {description && (
                        <Box my="5">
                            {typeof description === 'string' ? (
                                <Text>{description}</Text>
                            ) : (
                                description
                            )}
                        </Box>
                    )}
                </Box>
            )}
            <Box overflowX="auto">
                <Pagination
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                />

                <Table {...getTableProps()}>
                    <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <HStack spacing="3">
                                        <>{column.render('Header')}</>
                                        {column.isSorted ?
                                            column.isSortedDesc ?
                                                <Icon as={IoArrowDown} color="fg.muted" boxSize="4" />
                                            :
                                                <Icon as={IoArrowUp} color="fg.muted" boxSize="4" />
                                            : ''}
                                    </HStack>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            return (
                            <Tr {...row.getRowProps()} {...getRowNavigationProps(row.original[navigationIdField])}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td whiteSpace="normal" {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </Td>
                                    )
                                })}
                            </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
                {page.length === 0 && (
                    <Center m="10" textColor="fg.subtle">{noDataText}</Center>
                )}
            </Box>

            <Pagination
                pageIndex={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
            />
        </Stack>
    )
}
