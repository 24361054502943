import { useRef } from 'react'
import {
    Box,
    Button,
    Container,
    Flex,
    Spacer,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import { AppInfoCard } from './AppInfoCard'

export const AppInfo = ({ setYamlField, goToNextStep }) => {
  const appInfoRef = useRef()

  const changePage = (change) => {
    for (const field in appInfoRef.current.values) {
      setYamlField(field, appInfoRef.current.values[field])
    }
    const name = appInfoRef.current.values["name"]
    let registryProject = "figure-production"
    if (appInfoRef.current.values["environment"] === "test") {
      registryProject = "figure-development"
    }
    setYamlField("deployment.image.repository", `us-east1-docker.pkg.dev/${registryProject}/figure-docker-repo/${name}`)
    setYamlField("database.migrations.image.repository", `us-east1-docker.pkg.dev/${registryProject}/figure-docker-repo/${name}-migrate`)
    change()
  }

  return (
      <Container
        py={{
          base: '4',
          md: '8',
        }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction={{
              base: 'column',
              lg: 'row',
            }}
            spacing={{
              base: '5',
              lg: '8',
            }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                App Info
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Basic info about your application.
              </Text>
            </Box>
            <AppInfoCard
              innerRef={appInfoRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

            <Flex>
                <Spacer />
                <Button variant="primary" onClick={() => changePage(goToNextStep)}>
                    Next
                </Button>
            </Flex>
        </Stack>
      </Container>
    )
}
