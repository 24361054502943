import { useRef } from 'react'
import {
    Box,
    Button,
    Container,
    Flex,
    Spacer,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import { LoggingCard } from './LoggingCard'
import { HealthcheckCard } from './HealthcheckCard'
import { ResourcesCard } from './ResourcesCard'

export const Deployment = ({ setYamlField, goToNextStep, goToPrevStep }) => {
  const loggingRef = useRef()
  const resourcesRef = useRef()
  const healthcheckRef = useRef()

  const changePage = (change) => {
    for (const field in loggingRef.current.values) {
      setYamlField(field, loggingRef.current.values[field])
    }

    for (const field in resourcesRef.current.values) {
      setYamlField(field, resourcesRef.current.values[field])
    }

    const path = healthcheckRef.current.values["deployment.readinessProbe.httpGet.path"]
    setYamlField("deployment.readinessProbe.httpGet.path", path)
    setYamlField("deployment.livenessProbe.httpGet.path", path)
    change()
  }

  return (
    <Container
        py={{ base: '4', md: '8' }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Logging
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Define what format your applications logs are in.
              </Text>
            </Box>
            <LoggingCard
              innerRef={loggingRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Resources
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Here's where you put in your app resource requests and limits.
              </Text>
            </Box>
            <ResourcesCard
              innerRef={resourcesRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Healthcheck
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Your healthcheck will be used to populate liveness and readiness probes.
              </Text>
            </Box>
            <HealthcheckCard
              innerRef={healthcheckRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

            <Flex>
                <Button variant="primary" onClick={() => changePage(goToPrevStep)}>
                    Previous
                </Button>
                <Spacer />
                <Button variant="primary" onClick={() => changePage(goToNextStep)}>
                    Next
                </Button>
            </Flex>
        </Stack>
      </Container>
    )
}
