import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { useCreateConsumerMutation, useListClustersQuery, useCreateACLMutation, useUpdateRequestTransformerMutation, useCreateAPIKeyMutation } from '../../apis/api-key-portal'
import {
    Select,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    useToast,
    FormHelperText,
    Input,
    useColorModeValue,
    Text,
    Stack,
} from '@chakra-ui/react'
import { CreatableSelect } from 'chakra-react-select'
import { FiChevronRight } from 'react-icons/fi'

export const NewConsumer = () => {
    const toast = useToast()
    const navigate = useNavigate()
    const buttonColorScheme = useColorModeValue('blue', 'green')

    const [createConsumer] = useCreateConsumerMutation()
    const [createACL] = useCreateACLMutation()
    const [updateRequestTransformer] = useUpdateRequestTransformerMutation()
    const [createKey] = useCreateAPIKeyMutation()
    const { data: clusters = [], isLoading: loadingClusters } = useListClustersQuery()

    const onSubmit = async (values, actions) => {
        const resp = await createConsumer(values)
        actions.setSubmitting(false)
        if (resp.error && resp.error.data) {
            toast({
                title: 'Failed to create new consumer.',
                description: resp.error.data.error || resp.error.data,
                status: 'error',
                duration: 7000,
                isClosable: true
            })
        } else {
            await createACL({ id: `${values.cluster}_${resp.data.id}`, body: { group: values.consumer_group } });
            await updateRequestTransformer({ id: `${values.cluster}_${resp.data.id}`, body: { add: { headers: values.add_headers, querystring: []}, remove: { headers: values.remove_headers, querystring: values.remove_headers} } });
            await createKey({ id: `${values.cluster}_${resp.data.id}`, body: { name: 'original' } });

            navigate('..')
        }
    }

    const validateRequiredField = (value) => {
        let error
        if (!value || value.length < 1) {
            error = 'Required'
        }
        return error
    }

    const validateConsumerField = (value) => {
        let error
        if (!value || value.length < 1) {
            error = 'Required'
        } else if (/[^a-z-]/g.test(value)) {
            error = 'Invalid. Can only contain lower case letters and -'
        }
        return error
    }

    const validateConsumerGroupField = (value) => {
        let error
        if (!value || value.length < 1) {
            error = 'Required'
        } else if (/[^a-z0-9-]/g.test(value)) {
            error = 'Invalid. Can only contain lower case letters, numbers, and -'
        }
        return error
    }

    const validateEmailField = (value) => {
        let error
        if (!value || value.length < 1) {
            error = 'Required'
        } else if (!/.+@.+\..+/i.test(value)) {
            error = 'Invalid email address'
        }
        return error
    }

    return (
        <Box
            px={{ base: '4', md: '6' }}
            py="5"
        >
            <Breadcrumb spacing='8px' separator={<FiChevronRight color='gray.500' />}>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to='..'><Button variant='text'>API Keys</Button></BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink>New</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Stack mt="5" spacing="5">
                <Text fontSize="2xl" fontWeight="medium">Create New Consumer</Text>

                <Formik initialValues={{ 
                    cluster: '', 
                    namespace: 'api-keys', 
                    username: '', 
                    consumer_group: '',
                    description: '', 
                    externalContact: '', 
                    internalContact: '', 
                    add_headers: [], 
                    remove_headers: ['x-uuid', 'apikey'] 
                }} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>

                    <Field name='cluster' validate={validateRequiredField}>
                        {({ field, form }) => (
                        <FormControl isRequired mb={5} isInvalid={form.errors.cluster && form.touched.cluster}>
                            <FormLabel htmlFor='cluster'>Cluster</FormLabel>
                            <Select {...field} id='cluster' placeholder='Select a cluster' isDisabled={loadingClusters}>
                            {clusters.map((cluster) => (
                                <option key={cluster} value={cluster}>{cluster}</option>
                            ))}
                            </Select>
                            <FormHelperText>Select the applicable cluster for the consumer.</FormHelperText>
                            <FormErrorMessage>{form.errors.cluster}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Field name='username' validate={validateConsumerField}>
                        {({ field, form }) => (
                        <FormControl isRequired mb={5} isInvalid={form.errors.username && form.touched.username}>
                            <FormLabel htmlFor='username'>Username</FormLabel>
                            <Input {...field} type='text' id='username' />
                            <FormHelperText> Name used for the Kong Consumer. Must only contain lowercase letters and -. EX: consumer-name</FormHelperText>
                            <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Field name='consumer_group' validate={validateConsumerGroupField}>
                        {({ field, form }) => (
                        <FormControl isRequired mb={5} isInvalid={form.errors.consumer_group && form.touched.consumer_group}>
                            <FormLabel htmlFor='consumer_group'>Consumer Group</FormLabel>
                            <Input {...field} type='text' id='consumer_group' />
                            <FormHelperText> Name used for the ACL group that controls what the consumer has access to. Must only contain lowercase letters and -. EX: consumer-group</FormHelperText>
                            <FormErrorMessage>{form.errors.consumer_group}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Field name='namespace' validate={validateRequiredField}>
                        {({ field, form }) => (
                        <FormControl isRequired mb={5} isInvalid={form.errors.namespace && form.touched.namespace}>
                            <FormLabel htmlFor='namespace'>Namespace</FormLabel>
                            <Input {...field} type='text' id='namespace' />
                            <FormHelperText> Specify the namespace for the consumer. </FormHelperText>
                            <FormErrorMessage>{form.errors.namespace}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Field name='description' validate={validateRequiredField}>
                        {({ field, form }) => (
                        <FormControl isRequired mb={5} isInvalid={form.errors.description && form.touched.description}>
                            <FormLabel htmlFor='description'>Description</FormLabel>
                            <Input {...field} type='text' id='description' />
                            <FormHelperText> Include a short description of the key's purpose </FormHelperText>
                            <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Field name='externalContact' validate={validateEmailField}>
                        {({ field, form }) => (
                        <FormControl isRequired mb={5} isInvalid={form.errors.externalContact && form.touched.externalContact}>
                            <FormLabel htmlFor='externalContact'>Third Party Contact</FormLabel>
                            <Input {...field} type='text' id='externalContact' />
                            <FormHelperText> Email address of the third party contact associated with the key </FormHelperText>
                            <FormErrorMessage>{form.errors.externalContact}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Field name='internalContact' validate={validateEmailField}>
                        {({ field, form }) => (
                        <FormControl isRequired mb={5} isInvalid={form.errors.internalContact && form.touched.internalContact}>
                            <FormLabel htmlFor='internalContact'>Figure Contact</FormLabel>
                            <Input {...field} type='text' id='internalContact' />
                            <FormHelperText> Email address of the internal contact associated with the key. Can be a person or team </FormHelperText>
                            <FormErrorMessage>{form.errors.internalContact}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Field name='add_headers'>
                        {({ field, form }) => (
                        <FormControl mb={5} isInvalid={form.errors.add_headers && form.touched.add_headers}>
                            <FormLabel htmlFor='add_headers'>Add Headers</FormLabel>
                            <CreatableSelect
                            id='add_headers'
                            isMulti
                            onChange={(option) => {
                                form.setFieldValue(field.name, option.map((o) => o.value))
                            }}
                            options={[]}
                            />
                            <FormHelperText>Add multiple headers by inputting them and hitting enter.<br/> <code>x-uuid:$value</code> is the most commonly added header.</FormHelperText>
                            <FormErrorMessage>{form.errors.add_headers}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Field name='remove_headers'>
                        {({ field, form }) => (
                        <FormControl mb={5} isInvalid={form.errors.remove_headers && form.touched.remove_headers}>
                            <FormLabel htmlFor='remove_headers'>Remove Headers</FormLabel>
                            <CreatableSelect
                            id='remove_headers'
                            isMulti
                            defaultValue={field.value.map(value => ({ label: value, value }))}
                            onChange={(option) => {
                                form.setFieldValue(field.name, option.map((o) => o.value))
                            }}
                            options={[]}
                            />
                            <FormHelperText> Add multiple headers by inputting them and hitting enter </FormHelperText>
                            <FormErrorMessage>{form.errors.remove_headers}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>

                    <Button
                        colorScheme={buttonColorScheme}
                        isLoading={isSubmitting}
                        type='submit'
                    >
                        Submit
                    </Button>
                    </Form>
                )}
                </Formik>
            </Stack>
        </Box>
    )
}
