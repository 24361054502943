import { useMemo } from 'react'
import { Formik, Form, Field } from 'formik'
import {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    useColorModeValue,
    Input,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'

export const NamespaceForm = ({ namespace, teams, isEdit=true, onSubmit }) => {
    const buttonColorScheme = useColorModeValue('blue', 'green')

    const initialTeamOptions = useMemo(() => {
        if (!namespace.owned_by) {
          return []
        }
        return teams.filter((t) => namespace.owned_by.includes(t.id)).map((t) => ({ value: t.id, label: t.name }))
    }, [namespace, teams])

    const teamOptions = useMemo(() => {
        return teams.map((t) => ({ value: t.id, label: t.name }))
          .sort((a, b) => (a.label > b.label) ? 1 : -1)
    }, [teams])

    const validateRequiredField = (value) => {
      let error
      if (!value || value.length < 1) {
        error = 'Required'
      }
      return error
    }

    return (
        <Formik initialValues={{ name: namespace.name || '', owned_by: namespace.owned_by || [] }} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>

            <Field name='name' validate={validateRequiredField}>
                {({ field, form }) => (
                    <FormControl isRequired={!isEdit} mb={5} isInvalid={form.errors.name && form.touched.name}>
                        <FormLabel htmlFor='name'>Name</FormLabel>
                        <Input disabled={isEdit} {...field} type='text' id='name' />
                        <FormHelperText />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                )}
            </Field>

            <Field name='owned_by' validate={validateRequiredField}>
              {({ field, form }) => (
                <FormControl isRequired mb={5} isInvalid={form.errors.owned_by && form.touched.owned_by}>
                  <FormLabel htmlFor='owned_by'>Owners</FormLabel>
                    <Select
                        onChange={(option) => {
                            const newVal = option.map((o) => o.value)
                            form.setFieldValue(field.name, newVal)
                        }}
                        defaultValue={initialTeamOptions}
                        options={teamOptions}
                        isClearable={true}
                        isMulti
                    />
                  <FormHelperText>These teams will co-manage this namespace. All team leads across all owning teams will receive notifications to approve access requests for this namespace.</FormHelperText>
                  <FormErrorMessage>{form.errors.owned_by}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button
              colorScheme={buttonColorScheme}
              isLoading={isSubmitting}
              type='submit'
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    )
}
