import { useEffect } from "react"
import Prism from "prismjs"

export default function Code(props) {
    // eslint-disable-next-line react/prop-types
    const { code, language } = props

    useEffect(() => {
        Prism.highlightAll()
    }, [])
    return (
        <pre style={{ overflowX: 'auto' }}>
            <code className={`language-${language}`}>{code}</code>
        </pre>
    )
}
