import { useMemo } from 'react'
import {
  Box,
  Icon, Stack, Tag, Text,
} from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { useGetAppsQuery } from '../../apis/org-chart'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'
import { AppTypeTag } from '../../components/AppTypeTag'
import { useColorSchemes } from "../../utils/color";

export const Apps = () => {
  const { data: apps = [], isLoading: isLoadingApps } = useGetAppsQuery({})

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Namespace',
            accessor: 'namespace',
        },
        {
            Header: 'Type',
            accessor: (row, _) => row.helm_chart ? 'helm' : 'fd',
            Cell: ({ row }) => (
                <AppTypeTag isHelmChart={row.original.helm_chart} />
            ),
            id: 'type',
        },
        {
            Header: 'Clusters',
            accessor: 'clusters',
            Cell: ({ value }) => value.join(', '),
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [])

  // title + badges
  const { helmChartColorScheme, fdColorScheme } = useColorSchemes();

  const title = (
    <Box>
      <Text fontSize='2xl' fontWeight='medium'>Apps</Text>
      <Stack direction='row' spacing='2'>
        <Tag mt='2' fontSize='0.6em' colorScheme={helmChartColorScheme}>Helm Chart: {apps.filter(app => app.helm_chart).length}</Tag>
        <Tag mt='2' fontSize='0.6em' colorScheme={fdColorScheme}>FD: {apps.filter(app => !app.helm_chart).length}</Tag>
      </Stack>
    </Box>
  )

  return (
    isLoadingApps ? (
      <LoadingSpinner />
    ) : (
      <DataTable
        data={apps}
        columns={columns}
        title={title}
        sortByField='requested_date'
        sortDesc={true}
      />
    )
  )
}
