import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    Stack,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const AppInfoCard = ({ innerRef, ...props }) => {
    const environmentChanged = (newVal, setFieldValue, parentOnChanged) => {
        parentOnChanged(newVal)
    }

    return (
        <Formik initialValues={{ name: '', namespace: '', environment: 'test', project: 'figure', businessUnit: '', businessProduct: '' }} innerRef={innerRef}>
            {({ setFieldValue }) => (
                <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
                    <Stack
                        spacing="5"
                        px={{ base: "4", md: "6" }}
                        py={{ base: "5", md: "6" }}
                    >
                        <Stack
                            spacing="6"
                            direction={{ base: 'column', md: 'row' }}
                        >
                            <Field name="name">
                                {({ field }) => (
                                    <FormControl>
                                        <FormLabel>App Name</FormLabel>
                                        <Input {...field} type="text" />
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="namespace">
                                {({ field }) => (
                                    <FormControl>
                                        <FormLabel>Namespace</FormLabel>
                                        <Input {...field} type="text" />
                                        <FormHelperText color="fg.subtle">Some common namespaces are lending-csp, lending-heloc, and securities-exchange. If you're not sure which namespace to use,
                                        find the most closely related, existing app, and use the same one. If you need a new namespace created, please submit DevOps Helpdesk ticket.</FormHelperText>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        <Field name="environment">
                            {({ field }) => (
                                <FormControl>
                                    <FormLabel>Environment</FormLabel>
                                    <RadioGroup {...field} onChange={(e) => environmentChanged(e, setFieldValue, field.onChange)}>
                                        <Stack spacing="5" direction="row">
                                            <Radio {...field} value="test">
                                                Test
                                            </Radio>
                                            <Radio {...field} value="prod">
                                                Prod
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </Field>
                        <Field name="project">
                            {({ field, form }) => (
                                <FormControl>
                                    <FormLabel>Cluster</FormLabel>
                                    <RadioGroup {...field}>
                                        <Stack spacing="5" direction="row">
                                            <Radio {...field} value="figure">
                                                Figure
                                            </Radio>
                                            <Radio {...field} value="figure-tech">
                                                Figure Tech
                                            </Radio>
                                            <Radio {...field} value="figure-dataeng">
                                                Data Eng
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </Field>
                        <Stack
                            spacing="6"
                            direction={{ base: 'column', md: 'row' }}
                        >
                            <Field name="businessUnit">
                                {({ field }) => (
                                    <FormControl>
                                        <FormLabel>Business Unit</FormLabel>
                                        <Input {...field} type="text" />
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="businessProduct">
                                {({ field }) => (
                                    <FormControl>
                                        <FormLabel>Product</FormLabel>
                                        <Input {...field} type="text" />
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                    </Stack>
                </Box>
            )}
        </Formik>
    )
}
