import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

export const AreYouSure = ({ content, submitting, onConfirm, isOpen, onClose, confirmButtonText = 'Confirm' }) => {
  const buttonColorScheme = useColorModeValue('blue', 'green')

  return (
    <Modal size='xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems='flex-start' spacing='15px'>
            {content}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={buttonColorScheme}
            isLoading={submitting}
            mr={3}
            onClick={() => onConfirm()}
          >
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
