import { useMemo } from 'react'
import { useGetDeploymentClustersQuery } from '../../apis/tops-service'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'
import { Button, Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const Clusters = () => {
  const { data: clusters = [], isLoading } = useGetDeploymentClustersQuery()

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Environment',
            accessor: 'env',
        },
    ],
  [])

  return (
    isLoading ? (
        <LoadingSpinner />
    ) : (
        <DataTable
            data={clusters}
            description={<Button variant='text' as={Link} href='https://docs.devops.figure.com/deployinator/getting-started-gha/' target='_blank' rel='noopener noreferrer' isExternal>Learn to deploy to one of these clusters <ExternalLinkIcon mx='2px' /></Button>}
            columns={columns}
            title='Clusters'
            sortByField='name'
            sortDesc={false}
            enableSearch={false}
            enableRowNavigation={false}
        />
    )
  )
}
