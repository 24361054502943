import {
 SimpleGrid,
 Stack,
 Text,
} from "@chakra-ui/react"
import { BlogPost } from "./BlogPost"

export const RecentBlogPosts = (props) => {
    // TODO pull this from notion dynamically
    const posts = [
        {
            id: 1,
            title: "Service to Service Communication",
            excerpt: "Going forward, all service to service communication must be done using the service name identical to the target application. Traffic should **NOT** be sent to any service with a `-kong` suffix. This includes cross-cluster traffic ...",
            publishedAt: "January 25th, 2024",
            link: "https://www.notion.so/figuretech/Service-to-Service-Communication-ea76bcf1a5ba430da00e37d820054ee0?pvs=4",
        },
        {
            id: 2,
            title: "New GitHub Action Runners",
            excerpt: "As of today we officially have brand new GitHub Action Runners! For the most part the runners will behave the same as the old ones, but come with some cool new stuff: Official GitHub support for the infrastructure and base runners...",
            publishedAt: "January 25th, 2024",
            link: "https://www.notion.so/figuretech/New-GitHub-Action-Runners-dea588c903e949f0958469492f4da882?pvs=4",
        },
        {
            id: 3,
            title: "Upcoming CloudSQL Changes",
            excerpt: "Historically, databases in production have always used CloudSQL, and databases in test have been pods. This separation, although fairly cost effective, has made maintenance and management challenging. The process used to perform t...",
            publishedAt: "January 22nd, 2024",
            link: "https://www.notion.so/figuretech/Upcoming-CloudSQL-Changes-90f21a6725a644e699fef59e119e4470?pvs=4",
        },
    ]

    return (
        <Stack
            spacing="6"
            {...props}
        >
            <Text fontSize="2xl">Recent Blog Posts</Text>

            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                gap={{ base: '12', lg: '8' }}
            >
            {posts.map((p) =>
                <BlogPost key={p.id} {...p} />
            )}
            </SimpleGrid>
        </Stack>
    )
}
