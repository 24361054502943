import { Spinner, Text } from "@chakra-ui/react"
import { useGetRequestByApprovalIdQuery } from "../apis/db-access-provider"
import { useGetRequestByApprovalIdQuery as useGetVaultRequestByApprovalIdQuery, useGetNamespaceByIdQuery } from "../apis/vault-access-provider"
import { useGetDatabaseByIdQuery } from "../apis/database-builder"

export const AccessibleResource = ({ request }) => {
    if (request.type === 'Database Access') {
        return <DatabaseAccessResource request={request} />
    } else if (request.type === 'Vault Access') {
        return <VaultAccessResource request={request} />
    } else {
        return <Text></Text>
    }
}

const VaultAccessResource = ({ request }) => {
    const { data: vaultReq = {}, isLoading: isLoadingRequest, isError: isErrorRequest } = useGetVaultRequestByApprovalIdQuery(request.id)
    const { data: ns = {}, isLoading: isLoadingNamespace, isError: isErrorNamespace } = useGetNamespaceByIdQuery(vaultReq.namespace_id, {skip: isLoadingRequest})

    if (isErrorRequest || isErrorNamespace) {
        return <Text>error</Text>
    } else if (isLoadingRequest || isLoadingNamespace) {
        return <Spinner />
    }
    return <Text>{ns.name} namespace</Text>
}

const DatabaseAccessResource = ({ request }) => {
    const { data: dbRequest = {}, isLoading: isLoadingDbRequest, isError: isErrorRequest } = useGetRequestByApprovalIdQuery(request.id)
    const { data: db = {}, isLoading: isLoadingDb, isError: isErrorDb } = useGetDatabaseByIdQuery(dbRequest.database_id, {skip: isLoadingDbRequest})

    if (isErrorRequest || isErrorDb) {
        return <Text>error</Text>
    } else if (isLoadingDbRequest || isLoadingDb) {
        return <Spinner />
    }
    return <Text>{dbRequest.role} on {db.name}</Text>
}
