import {
    Box,
    HStack,
    Link,
    Stack,
    Text,
} from "@chakra-ui/react"

export const BlogPost = ({ title, excerpt, publishedAt, link }) => {
    return (
        <Link role="group" _hover={{ textDecor: 'none' }} href={link} target='_blank' rel='noopener noreferrer'>
            <Box
                p="6"
                bg="bg.surface"
                boxShadow='lg'
                _groupHover={{ boxShadow: 'xl' }}
                transition="all 0.2s"
                height="full"
            >
                <Stack spacing={{ base: '8', lg: '16' }} justify="space-between" height="full">
                    <Stack spacing="8">
                        <Stack spacing="3">
                            <Text fontSize="lg" color="fg.default">{title}</Text>
                            <Text fontSize="sm" color="fg.muted">{excerpt}</Text>
                        </Stack>
                    </Stack>
                    <HStack>
                        <Box fontSize="sm">
                            <Text color="fg.muted">{publishedAt}</Text>
                        </Box>
                    </HStack>
                </Stack>
            </Box>
        </Link>
    )
}
