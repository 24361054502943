import { createApi } from '@reduxjs/toolkit/query/react'
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from './retry'

export const approvalEngineApi = createApi({
  reducerPath: 'approvalEngineApi',
  baseQuery: baseQueryWithUnAuthRetry(config.approvalEngineApiUrl),
  tagTypes: ['ApprovalRequest'],
  endpoints: (builder) => ({
    // requests
    getRequests: builder.query({
      query: ({forUser = "", forTeam = ""}) => `/requests?user=${forUser}&team=${forTeam}`,
      providesTags: (result = [], error, arg) => [
        'ApprovalRequest',
        ...result.map(({ id }) => ({ type: 'ApprovalRequest', id }))
      ]
    }),
    getRequestById: builder.query({
      query: (id) => `/requests/${id}`,
      providesTags: (result, error, arg) => [{ type: 'ApprovalRequest', id: arg }]
    }),
    createRequest: builder.mutation({
      query: ({ ...body }) => ({
        url: '/requests',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['ApprovalRequest']
    }),
    updateRequest: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/requests/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['ApprovalRequest']
    }),
    cancelRequest: builder.mutation({
      query: ({ id }) => ({
        url: `/requests/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ApprovalRequest']
    })
  })
})

export const {
  useGetRequestsQuery,
  useGetRequestByIdQuery,
  useCreateRequestMutation,
  useUpdateRequestMutation,
  useCancelRequestMutation
} = approvalEngineApi
