import { useState } from 'react'
import { Link, useParams, useResolvedPath } from 'react-router-dom'
import { useGetUsersQuery } from '../../apis/org-chart'
import { useGetRequestByIdQuery, useUpdateRequestMutation, useCancelRequestMutation } from '../../apis/approval-engine'
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Center,
    Flex,
    Grid,
    GridItem,
    List,
    ListIcon,
    ListItem,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react'
import { iconPropsFromStatus } from '../../utils/status'
import { approversTransform } from '../../utils/approversTransform'
import { FiCheckCircle, FiChevronRight, FiXCircle } from 'react-icons/fi'
import { DecideWithCommentModal } from '../../components/DecideWithCommentModal'
import { TeamAccessDetailsDatabase } from './TeamAccessDetailsDatabase'
import { TeamAccessDetailsVault } from './TeamAccessDetailsVault'
import { dbAccessProviderApi } from '../../apis/db-access-provider'
import { useDispatch } from 'react-redux'
import { vaultAccessProviderApi } from '../../apis/vault-access-provider'

export const TeamAccessDetails = () => {
  const { id } = useParams()
  const resourceParentPage = useResolvedPath().pathname.startsWith('/access/resource')
  const toast = useToast()
  const dispatch = useDispatch()
  const { data: approvalRequest = {}, isLoading: isLoadingApprovalRequest, isError: isErrorApprovalRequest } = useGetRequestByIdQuery(id)
  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersQuery()
  const [updateRequest] = useUpdateRequestMutation()
  const [cancelRequest] = useCancelRequestMutation()
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showDenyModal, setShowDenyModal] = useState(false)
  const [showRevokeModal, setShowRevokeModal] = useState(false)

  const getApprovers = () => {
    if (isLoadingApprovalRequest || isLoadingUsers || isErrorApprovalRequest) {
      return {}
    }

    return approversTransform(approvalRequest, users)
  }

  const userDetails = (user, id) => {
    if (user === undefined) {
      return id
    } else {
      return `${user.first_name} ${user.last_name} (${user.email})`
    }
  }

  const invalidateChildCaches = () => {
    // this is kind of a hack for a couple reasons. 1) react doesnt really lend itself
    // well to a parent component calling functions in a child components (which is
    // where the refetch methods would be). so instead, we tell rtk query to invalidate
    // the cache for a few things to work our way around it. 2) it requires updating
    // this function every time we add a new type of request we want to support linking
    // to from this page (currently its db and vault access). 3) it requires knowing the
    // names of the tags used by RTK query for caching, which no code outside of the store
    // should be aware of. this should be revisited at some point, but it works for now.
    dispatch(dbAccessProviderApi.util.invalidateTags(['DBAccessRequest']))
    dispatch(vaultAccessProviderApi.util.invalidateTags(['VaultAccessRequest']))
  }

  const approveRequest = async (values, actions) => {
    await decideOnRequest('approve', values.comment)
    actions.setSubmitting(false)
  }

  const denyRequest = async (values, actions) => {
    await decideOnRequest('deny', values.comment)
    actions.setSubmitting(false)
  }

  const decideOnRequest = async (decision, comment) => {
    const resp = await updateRequest({ id, decision: decision, comment: comment })
    if (resp.error) {
      toast({
        title: `Failed to ${decision} the request.`,
        description: resp.error.data.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      setShowApproveModal(false)
      setShowDenyModal(false)
      invalidateChildCaches()
      toast({
        title: 'Successfully updated the request.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
    }
  }

  const revokeRequest = async (_, actions) => {
    const resp = await cancelRequest({ id })
    if (resp.error) {
      toast({
        title: 'Failed to revoke the request.',
        description: resp.error.data.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      setShowRevokeModal(false)
      invalidateChildCaches()
      toast({
        title: 'Successfully revoked the request.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
    }
    actions.setSubmitting(false)
  }

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>{resourceParentPage ? "Resource Access" : "My Team's Access"}</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex mt={5} justifyContent='space-between'>
        <Text fontSize="2xl" fontWeight="medium">
            Details
        </Text>
      </Flex>

      {isLoadingApprovalRequest ? (
        <Spinner />
      ) : (
        <Box mt={5}>
          <Grid templateColumns='repeat(2, 1fr)'>
            <GridItem>
              <Grid templateColumns='200px 1fr' gap={2}>
                <GridItem as='b'><Text align='right' pr={5}>Requestor</Text></GridItem><GridItem><Text>{userDetails(users.find((u) => u.id === approvalRequest.user_id))}</Text></GridItem>
                <GridItem as='b'><Text align='right' pr={5}>Type</Text></GridItem><GridItem><Text>{approvalRequest.type}</Text></GridItem>
                <GridItem as='b'><Text align='right' pr={5}>Requested Date</Text></GridItem><GridItem><Text>{new Date(approvalRequest.requested_date * 1000).toDateString()}</Text></GridItem>
                {approvalRequest.status === 'pending' && (
                  <>
                    <GridItem as='b'><Text align='right' pr={5}>Expires</Text></GridItem><GridItem><Text>{new Date(approvalRequest.request_expiration * 1000).toDateString()}</Text></GridItem>
                  </>
                )}
                {approvalRequest.error && (
                  <>
                    <GridItem as='b'><Text align='right' pr={5}>Error</Text></GridItem><GridItem><Text>{approvalRequest.error}</Text></GridItem>
                  </>
                )}
                <GridItem as='b'><Text align='right' pr={5}>Approvals</Text></GridItem>
                <GridItem>
                  {Object.entries(getApprovers()).map(([groupName, approvers]) => (
                    <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(2, 1fr)' key={groupName}>
                      <GridItem rowSpan={2}>
                        <Text as='i'>{groupName}</Text>
                      </GridItem>
                      <GridItem>
                        <List spacing={3}>
                          {approvers.map((a) => (
                            <ListItem key={a.uid}>
                              <ListIcon {...iconPropsFromStatus(a.status)} /> {a.email} <i>{a.comment ? a.comment : a.status !== 'denied' ? '' : 'no comment was provided'}</i>
                            </ListItem>
                          ))}
                        </List>
                      </GridItem>
                    </Grid>
                  ))}
                </GridItem>

                {approvalRequest.status === 'pending' ? (
                    <GridItem colSpan={2}>
                        <Center>
                            <Button mr={5} colorScheme='green' aria-label='Approve the request' leftIcon={<FiCheckCircle />} onClick={() => setShowApproveModal(true)}>Approve</Button>
                            <Button colorScheme='red' aria-label='Deny the request' leftIcon={<FiXCircle />} onClick={() => setShowDenyModal(true)}>Deny</Button>
                        </Center>
                    </GridItem>
                ) : approvalRequest.status === 'approved' ? (
                    <GridItem colSpan={2}>
                        <Center>
                            <Button colorScheme='red' aria-label='Revoke the request' leftIcon={<FiXCircle />} onClick={() => setShowRevokeModal(true)}>Revoke</Button>
                        </Center>
                    </GridItem>
                ) : (
                    <></>
                )}
              </Grid>
            </GridItem>

            <GridItem>
              {approvalRequest.type === "Database Access" ? (
                <TeamAccessDetailsDatabase approvalId={id} />
              ) : approvalRequest.type === "Vault Access" ? (
                <TeamAccessDetailsVault approvalId={id} />
              ) : (
                <Text>Unsupported request type {approvalRequest.type}</Text>
              )}
            </GridItem>
          </Grid>
        </Box>
      )}

      <DecideWithCommentModal action='approve' colorScheme='green' onSubmit={approveRequest} isOpen={showApproveModal} onClose={() => setShowApproveModal(false)} />
      <DecideWithCommentModal action='deny' colorScheme='red' onSubmit={denyRequest} isOpen={showDenyModal} onClose={() => setShowDenyModal(false)} />
      <DecideWithCommentModal action='revoke' colorScheme='red' allowComment={false} onSubmit={revokeRequest} isOpen={showRevokeModal} onClose={() => setShowRevokeModal(false)} />
    </Box>
  )
}
