import { useMemo } from 'react'
import {
  HStack,
  Icon,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { FiInfo, FiChevronRight } from 'react-icons/fi'
import { useGetUsersQuery } from '../../apis/org-chart'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'

export const Users = () => {
  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersQuery()

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: (row, _) => row.first_name + ' ' + row.last_name,
            id: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: (
                <Tooltip label="If a user does not have a manager, they will be unable to request database access. Please verify the user has
                                a manager assigned on their Okta profile. If they don't, verify with HR the user has a manager in Paycom.">
                    <HStack><Text>Manager</Text> <FiInfo /></HStack>
                </Tooltip>
            ),
            accessor: (row, _) => users.find(u => u.id === row.manager_id)?.first_name,
            Cell: ({ data, row }) => {
                const mgr = data.find(u => u.id === row.original.manager_id)
                if (mgr) {
                    return <>{mgr?.first_name + ' ' + mgr?.last_name}</>
                }
                return <></>
            },
            id: 'manager',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [users])

  return (
    isLoadingUsers ? (
      <LoadingSpinner />
    ) : (
      <DataTable
        data={users}
        columns={columns}
        title='Users'
        sortByField='name'
        sortDesc={false}
      />
    )
  )
}
