import { Avatar, Box, HStack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const UserProfile = (props) => {
  const { name, id, image, email } = props
  return (
    <Link to={`users/details/${id}`}>
      <HStack spacing="3" ps="2" pb="2">
        <Avatar name={name} src={image} boxSize="10" />
        <Box>
          <Text fontWeight="medium" fontSize="sm">
            {name}
          </Text>
          <Text color="fg.muted" fontSize="sm">
            {email}
          </Text>
        </Box>
      </HStack>
    </Link>
  )
}
