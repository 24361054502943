import { createApi } from '@reduxjs/toolkit/query/react'
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from './retry'

export const orgChartApi = createApi({
  reducerPath: 'orgChartApi',
  baseQuery: baseQueryWithUnAuthRetry(config.orgChartApiUrl),
  tagTypes: ['User', 'Team', 'App'],
  endpoints: (builder) => ({
    // users
    getUsers: builder.query({
      query: () => '/v2/users',
      providesTags: (result = [], error, arg) => [
        'User',
        ...result.map(({ id }) => ({ type: 'User', id }))
      ]
    }),
    getUserById: builder.query({
      query: (userId) => `/v2/users/${userId}`,
      providesTags: (result, error, arg) => [{ type: 'User', id: result?.id }]
    }),

    // teams
    getTeams: builder.query({
      query: () => '/v2/teams',
      providesTags: (result = [], error, arg) => [
        'Team',
        ...result.map(({ id }) => ({ type: 'Team', id }))
      ]
    }),
    getTeamById: builder.query({
      query: (id) => `/v2/teams/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Team', id: arg }]
    }),
    updateTeamMemberRole: builder.mutation({
      query: ({ id, userId, ...body }) => ({
        url: `/v2/teams/${id}/members/${userId}/role`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [
        'Team',
        { type: 'Team', id: arg.id }
      ]
    }),
    updateTeam: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/v2/teams/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [
        'Team',
        { type: 'Team', id: arg.id }
      ]
    }),

    // apps
    getApps: builder.query({
      query: () => '/v2/apps',
      providesTags: (result = [], error, arg) => [
        'App',
        ...result.map(({ id }) => ({ type: 'App', id }))
      ]
    }),
    getAppById: builder.query({
      query: (id) => `/v2/apps/${id}`,
      providesTags: (result, error, arg) => [{ type: 'App', id: arg }]
    }),
    getAppYaml: builder.query({
      query: (id) => ({
        url: `/v2/apps/${id}/yaml`,
        responseHandler: 'text',
      }),
    }),
  })
})

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,

  useGetTeamsQuery,
  useGetTeamByIdQuery,
  useUpdateTeamMemberRoleMutation,
  useUpdateTeamMutation,

  useGetAppsQuery,
  useGetAppByIdQuery,
  useGetAppYamlQuery,
} = orgChartApi
