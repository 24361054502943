import {
    FiCheckCircle,
    FiXCircle,
    FiLoader,
    FiAlertTriangle,
} from 'react-icons/fi'

export const iconPropsFromStatus = (status) => {
  if (status === 'approved' || status === 'active' || status === 'completed') {
    return { as: FiCheckCircle, color: 'green.500' }
  } else if (status === 'denied' || status === 'cancelled' || status === 'revoked') {
    return { as: FiXCircle, color: 'red.500' }
  } else if (status === 'error') {
    return { as: FiAlertTriangle, color: 'yellow.500' }
  } else {
    return { as: FiLoader, color: 'blue.500' }
  }
}
