import { useGetRequestByApprovalIdQuery } from '../../apis/db-access-provider'
import { useGetDatabaseByIdQuery, useGetInstanceByIdQuery } from '../../apis/database-builder'
import {
    Code,
    Grid,
    GridItem,
    Icon,
    Spinner,
    Text,
} from '@chakra-ui/react'
import { iconPropsFromStatus } from '../../utils/status'

export const TeamAccessDetailsDatabase = ({ approvalId }) => {
  const { data: accessRequest = {}, isLoading: isLoadingAccessRequest } = useGetRequestByApprovalIdQuery(approvalId)
  const { data: database = {}, isLoading: isLoadingDatabase } = useGetDatabaseByIdQuery(accessRequest.database_id || 'undefined', { skip: accessRequest.database_id === undefined })
  const { data: instance = {}, isLoading: isLoadingInstance } = useGetInstanceByIdQuery(database.instance_id, { skip: database.instance_id === undefined })

  return (
    isLoadingAccessRequest || isLoadingDatabase || isLoadingInstance ? (
      <Spinner />
    ) : (
      <Grid templateColumns='200px 1fr' gap={2}>
        <GridItem as='b'><Text align='right' pr={5}>Status</Text></GridItem><GridItem><Text><Icon {...iconPropsFromStatus(accessRequest.status)} /> {accessRequest.status}</Text></GridItem>
        <GridItem as='b'><Text align='right' pr={5}>Database</Text></GridItem><GridItem><Text>{database.name} (on the <Code>{instance?.name || 'unknown'}</Code> instance)</Text></GridItem>
        <GridItem as='b'><Text align='right' pr={5}>Access Level</Text></GridItem><GridItem><Text>{accessRequest.role}</Text></GridItem>
        <GridItem as='b'><Text align='right' pr={5}>Environment</Text></GridItem><GridItem><Text>{database.project}</Text></GridItem>
        <GridItem as='b'><Text align='right' pr={5}>Reason</Text></GridItem><GridItem><Text>{accessRequest.business_justification}</Text></GridItem>
      </Grid>
    )
  )
}
