import {
  Button,
    Code,
    Container,
    HStack,
    Heading,
    Icon,
    Link,
    Stack,
    StackDivider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react'
import { IoAlertCircleOutline, IoDocumentTextOutline, IoGitPullRequest } from 'react-icons/io5';
import { SiArgo } from 'react-icons/si';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import yaml from 'react-syntax-highlighter/dist/esm/languages/hljs/yaml';
import { ManifestTab } from './ManifestTab';
import { ExternalLinkIcon } from '@chakra-ui/icons';

SyntaxHighlighter.registerLanguage('yaml', yaml);

export const Manifest = ({
    name,
    data = {},
    isConversion = false,
  }) => {

  return (
    <Container
        py={{
          base: '4',
          md: '8',
        }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction="column"
            spacing={{
              base: '5',
              lg: '8',
            }}
            justify="space-between"
          >
            <Heading align="center">{name}</Heading>
            <Text>Here's your values files. It's recommended you name these files after the environment, so <Code>values.test.yaml</Code>, and
            <Code>values.prod.yaml</Code>. Everything you need will be in these files, including your app configuration, which cluster it
            deploys to, and more. This quick start guide helps you bootstrap this file, so you'll need to review it and make sure everything you need is
            defined here. Please refer to the Chart documentation for all available fields.</Text>
            <Text>Next steps:</Text>
            <Stack>
              <HStack><Icon boxSize="8" color="green.400" as={IoDocumentTextOutline} /><Text>Review the <Button as={Link} variant="text" target="_blank" rel="noopener noreferrer" href="https://docs.devops.figure.com/helm-charts" rightIcon={<ExternalLinkIcon />}>Chart documentation</Button> to get to know this file better</Text></HStack>
              {isConversion && (
                <HStack><Icon boxSize="8" color="yellow.400" as={IoAlertCircleOutline} /><Text>If your application uses any values from the <Code>common</Code> or <Code>common-v2</Code> config maps, you <b>must</b> define those variables in your <Code>deployment.config</Code> section. If you're not sure, review the <Button as={Link} variant="text" target="_blank" rel="noopener noreferrer" href="https://docs.devops.figure.com/helm-charts/converting" rightIcon={<ExternalLinkIcon />}>documentation</Button></Text></HStack>
              )}
              <HStack><Icon boxSize="8" color="purple.600" as={IoGitPullRequest} /><Text>Make a PR to add this file and update your GitHub Actions workflow. Review the <Button as={Link} variant="text" target="_blank" rel="noopener noreferrer" href="https://docs.devops.figure.com/helm-charts/getting-started" rightIcon={<ExternalLinkIcon />}>Getting Started guide</Button> for more information</Text></HStack>
              <HStack><Icon boxSize="8" color="red.400" as={SiArgo} /><Text>Deploy your app and check out the results in Argo in <Button as={Link} variant="text" target="_blank" rel="noopener noreferrer" href="https://argocd.test.figure.com" rightIcon={<ExternalLinkIcon />}>test</Button> or <Button as={Link} variant="text" target="_blank" rel="noopener noreferrer" href="https://argocd.figure.com" rightIcon={<ExternalLinkIcon />}>prod</Button></Text></HStack>
            </Stack>

            <Tabs>
              <TabList>
                {Object.keys(data).map((env) => (
                  <Tab key={env}>{env.charAt(0).toUpperCase() + env.substring(1)}</Tab>
                ))}
              </TabList>

              <TabPanels>
                {Object.keys(data).map((env) => (
                  <TabPanel key={env}>
                    <ManifestTab yamlString={data[env].yaml} warnings={data[env].warnings} errors={data[env].errors} />
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Stack>
        </Stack>
      </Container>
    )
}
