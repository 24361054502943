import { Button, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, VStack, RadioGroup, Stack, Radio } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const TeamRoleModal = ({ teamMember = {}, colorScheme, onSubmit, isOpen, onClose }) => {
  return (
    <Modal size='xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change {teamMember?.user?.first_name}'s role</ModalHeader>
        <ModalCloseButton />
        <Formik initialValues={{ user_id: teamMember?.user?.id, role: teamMember.role }} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <VStack alignItems='flex-start' spacing='15px'>
                    <Field name='role'>
                    {({ field }) => (
                        <FormControl>
                            <FormLabel>Role</FormLabel>
                            <RadioGroup {...field} colorScheme={colorScheme}>
                                <Stack direction='row'>
                                    <Radio {...field} value='LEAD'>Lead</Radio>
                                    <Radio {...field} value='MEMBER'>Member</Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    )}
                    </Field>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme={colorScheme} isLoading={isSubmitting} type="submit">
                  Save
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
