import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Spinner,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import { useCreateNamespaceMutation } from '../../apis/vault-access-provider'
import { useGetTeamsQuery } from '../../apis/org-chart'
import { NamespaceForm } from './NamespaceForm'

export const CreateNamespace = () => {
    const toast = useToast()
    const navigate = useNavigate()
    const { id } = useParams()

    const { data: teams = [], isLoading: isLoadingTeams } = useGetTeamsQuery()
    const [createNamespace] = useCreateNamespaceMutation()

    const onSubmit = async (values, actions) => {
        const resp = await createNamespace({ id: id, ...values })
        actions.setSubmitting(false)
        if (resp.error) {
          toast({
            title: resp.error.data.title,
            description: resp.error.data.description,
            status: 'error',
            duration: 7000,
            isClosable: true
          })
        } else {
          toast({
            title: 'Successfully created namespace.',
            status: 'success',
            duration: 7000,
            isClosable: true
          })
          navigate('..')
        }
    }

    return (
        <Box
            px={{ base: '4', md: '6' }}
            py="5"
        >
            <Breadcrumb spacing='8px' separator={<FiChevronRight color='gray.500' />}>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to='..'><Button variant='text'>Namespaces</Button></BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink>Create</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            {isLoadingTeams ? (
                <Box>
                    <Spinner />
                </Box>
            ) : (
                <Stack spacing="5">
                    <Text fontSize="4xl" fontWeight="bold">Create a New Namespace</Text>

                    <NamespaceForm
                        isEdit={false}
                        namespace={{}}
                        teams={teams}
                        onSubmit={onSubmit}
                    />
                </Stack>
            )}
        </Box>
    )
}
