import { useRef } from 'react'
import {
    Box,
    Button,
    Container,
    Flex,
    Spacer,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import { KafkaCard } from './KafkaCard'

export const Kafka = ({ setYamlField, goToNextStep, goToPrevStep }) => {
  const kafkaRef = useRef()

  const changePage = (change) => {
    let topics = []
    let consumers = []
    let producers = []

    for (const topic in kafkaRef.current.values["kafka.topics"]) {
      topics.push({topicName: topic, cleanupPolicy: "compact"})
      producers.push({topicName: topic, topicPatternType: "LITERAL"})
    }
    for (const consumer in kafkaRef.current.values["kafka.consumers"]) {
      consumers.push({consumerGroupId: consumer, consumerGroupPatternType: "LITERAL", topics: []})
    }

    setYamlField("kafka.topics", topics)
    setYamlField("kafka.consumers", consumers)
    setYamlField("kafka.producers", producers)
    change()
  }

  return (
    <Container
        py={{ base: '4', md: '8' }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Kafka Details
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Here's where you specify your consumers, producers, and topics.
              </Text>
            </Box>
            <KafkaCard
              innerRef={kafkaRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

            <Flex>
                <Button variant="primary" onClick={() => changePage(goToPrevStep)}>
                    Previous
                </Button>
                <Spacer />
                <Button variant="primary" onClick={() => changePage(goToNextStep)}>
                    Next
                </Button>
            </Flex>
        </Stack>
      </Container>
    )
}
