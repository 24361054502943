import { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useGetPendingDatabaseByIdQuery, useGetInstanceByIdQuery, useUpdatePendingDatabaseMutation, useImportPendingDatabaseMutation, useIgnorePendingDatabaseMutation } from '../../apis/database-builder'
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Code,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Grid,
    GridItem,
    Icon,
    Input,
    Spinner,
    Stack,
    Text,
    useToast,
    useColorModeValue,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { BsSlashLg } from 'react-icons/bs'
import { AreYouSure } from '../../components/AreYouSure'
import { FiChevronRight } from 'react-icons/fi'

export const PendingDatabaseDetails = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const [skipQuery, setSkipQuery] = useState(false)

  const { data: database = {}, isLoading: isLoadingDatabase } = useGetPendingDatabaseByIdQuery(id, { skip: skipQuery })
  const { data: instance = {}, isLoading: isLoadingInstance } = useGetInstanceByIdQuery(database.instance_id, { skip: skipQuery || database.instance_id === undefined })

  const [updatePendingDatabase] = useUpdatePendingDatabaseMutation()
  const [importDatabase] = useImportPendingDatabaseMutation()
  const [ignoreDatabase] = useIgnorePendingDatabaseMutation()

  const [showImportModal, setShowImportModal] = useState(false)
  const [showIgnoreModal, setShowIgnoreModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const buttonColorScheme = useColorModeValue('blue', 'green')

  const onImport = async () => {
    // see onIgnore for explanation of why skip query is needed
    setSkipQuery(true)
    setSubmitting(true)
    const resp = await importDatabase(database.id)
    setSubmitting(false)
    if (resp.error) {
      setSkipQuery(false)
      toast({
        title: 'Failed to import the database',
        description: resp.error.data.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Successfully imported the database.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
      navigate('..')
    }
  }

  const onIgnore = async () => {
    // when clicking ignore, it triggers a delete. which causes a rtk query cache invalidation,
    // which triggers an immediate refetch of the db (which returns a 404 because its been deleted
    // and ignored). because of the global error handler, it would pop up an error toast when it shouldnt
    // because of how fast react performs state changes. so to get around this, before we kick off the ignore
    // call, we set a skip query flag which tells rtk query to not query for this database again.
    // if the request fails, we flip it back. otherwise if the request succeeds, we navigate pages anyways
    // so it doesnt matter.
    setSkipQuery(true)
    setSubmitting(true)
    const resp = await ignoreDatabase(database.id)
    setSubmitting(false)
    if (resp.error) {
      setSkipQuery(false)
      toast({
        title: 'Failed to ignore the database',
        description: resp.error.data?.error ?? resp.error.data,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {

      toast({
        title: 'Successfully ignored the database.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
      navigate('..')
    }
  }

  const onSave = async (values, actions) => {
    const resp = await updatePendingDatabase({ id: database.id, ...values })
    actions.setSubmitting(false)
    if (resp.error) {
      toast({
        title: 'Failed to save the pending database',
        description: resp.error.data.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Successfully updated the database.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
      actions.resetForm({ values: { app_name: resp.data.app_name, schema: resp.data.schema, app_user: resp.data.app_user } })
    }
  }

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Pending Databases</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id.substring(0, 30)}...</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex my={5} justifyContent='space-between'>
        {isLoadingDatabase ? (
            <Spinner />
        ) : (
            <Text fontSize="2xl" fontWeight="medium">
                {database.name}
            </Text>
        )}
      </Flex>

      {!isLoadingDatabase && !isLoadingInstance && (
        <Stack spacing="5">
            <Text>These fields cannot be modified</Text>

            <Grid templateColumns='repeat(1, 1fr)'>
                <Grid templateColumns='200px 1fr' gap={2}>
                    <GridItem as='b'><Text align='right' pr={5}>Project</Text></GridItem><GridItem><Text>{database.project}</Text></GridItem>
                    <GridItem as='b'><Text align='right' pr={5}>Environment</Text></GridItem><GridItem><Text>{database.environment}</Text></GridItem>
                    <GridItem as='b'><Text align='right' pr={5}>Instance</Text></GridItem><GridItem><Text>{instance.name}</Text></GridItem>
                </Grid>
            </Grid>

            <Stack spacing="5">
                <Text>These fields can be modified, and must be filled in before importing</Text>

                <Formik initialValues={{ app_name: database.app_name, schema: database.schema, app_user: database.app_user }} onSubmit={onSave}>
                {({ isSubmitting, dirty }) => (
                    <Form>
                        <Field name='app_name'>
                            {({ field, form }) => (
                            <FormControl isRequired mb={5} isInvalid={form.errors.app_name && form.touched.app_name}>
                                <FormLabel htmlFor='app_name'>App Name</FormLabel>
                                <Input {...field} type='text' id='app_name' />
                                <FormHelperText>The name of the application that owns this database.</FormHelperText>
                                <FormErrorMessage>{form.errors.app_name}</FormErrorMessage>
                            </FormControl>
                            )}
                        </Field>

                        <Field name='namespace'>
                            {({ field, form }) => (
                                <FormControl isRequired mb={5} isInvalid={form.errors.namespace && form.touched.namespace}>
                                    <FormLabel htmlFor='namespace'>Namespace</FormLabel>
                                    <Input {...field} type='text' id='namespace' />
                                    <FormHelperText>The namespace where the application lives in Kubernetes.</FormHelperText>
                                    <FormErrorMessage>{form.errors.namespace}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name='schema'>
                            {({ field, form }) => (
                            <FormControl isRequired mb={5} isInvalid={form.errors.schema && form.touched.schema}>
                                <FormLabel htmlFor='schema'>Schema</FormLabel>
                                <Input {...field} type='text' id='schema' />
                                <FormHelperText>The schema on this database used by the app.</FormHelperText>
                                <FormErrorMessage>{form.errors.schema}</FormErrorMessage>
                            </FormControl>
                            )}
                        </Field>

                        <Field name='app_user'>
                            {({ field, form }) => (
                            <FormControl isRequired mb={5} isInvalid={form.errors.app_user && form.touched.app_user}>
                                <FormLabel htmlFor='app_user'>App User</FormLabel>
                                <Input {...field} type='text' id='app_user' />
                                <FormHelperText>The postgres user the app uses to connect to this database.</FormHelperText>
                                <FormErrorMessage>{form.errors.app_user}</FormErrorMessage>
                            </FormControl>
                            )}
                        </Field>

                        <Flex align='center' mb={5}>
                            <Button
                                disabled={!dirty}
                                colorScheme={buttonColorScheme}
                                isLoading={isSubmitting}
                                type='submit'
                            >
                            Save
                            </Button>

                            <Icon mx={5} as={BsSlashLg} />

                            <Button
                                disabled={dirty || database.namespace === '' || database.app_name === '' || database.schema === '' || database.app_user === ''}
                                colorScheme={buttonColorScheme}
                                isLoading={isSubmitting}
                                onClick={() => setShowImportModal(true)}
                            >
                                Import Database
                            </Button>

                            <Icon mx={5} as={BsSlashLg} />

                            <Button
                                colorScheme='red'
                                isLoading={isSubmitting}
                                onClick={() => setShowIgnoreModal(true)}
                            >
                                Ignore Database
                            </Button>
                        </Flex>
                    </Form>
                )}
                </Formik>
            </Stack>
        </Stack>
      )}

      <AreYouSure
        content={
          <>
            <Text>You will not be able to modify the <Code>{database.name}</Code> database once it has been imported. Please confirm the information below:</Text>
            <Text>Namespace: <Code>{database.namespace}</Code></Text>
            <Text>App Name: <Code>{database.app_name}</Code></Text>
            <Text>Schema: <Code>{database.schema}</Code></Text>
            <Text>App User: <Code>{database.app_user}</Code></Text>
          </>
        }
        submitting={submitting}
        onConfirm={onImport}
        isOpen={showImportModal}
        onClose={() => setShowImportModal(false)}
        confirmButtonText='Confirm and Import'
      />
      <AreYouSure
        content={<Text>The <Code>{database.name}</Code> database will be hidden from all queries and cannot be viewed again.</Text>}
        submitting={submitting}
        onConfirm={onIgnore}
        isOpen={showIgnoreModal}
        onClose={() => setShowIgnoreModal(false)}
        confirmButtonText='Ignore'
      />
    </Box>
  )
}
