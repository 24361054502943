import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const HealthcheckCard = ({ innerRef, ...props }) => {
  return (
    <Formik initialValues={{ "deployment.readinessProbe.httpGet.path": "/actuator/health" }} innerRef={innerRef}>
      <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
        <Stack
          spacing="5"
          px={{ base: "4", md: "6" }}
          py={{ base: "5", md: "6" }}
        >
          <Field name="['deployment.readinessProbe.httpGet.path']">
            {({ field }) => (
              <FormControl>
                <FormLabel>Healthcheck Route</FormLabel>
                <Input {...field} type="text" />
              </FormControl>
            )}
          </Field>
        </Stack>
      </Box>
    </Formik>
  )
}
