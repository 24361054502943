import { useCallback, useState } from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Code,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Link,
  Select,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { parse } from 'yaml'

export const DeploymentYAMLTab = ({ onSubmit, projects }) => {
  const [yaml, setYaml] = useState('')
  const [yamlError, setYamlError] = useState('')
  const [appName, setAppName] = useState('')
  const [namespace, setNamespace] = useState('')
  const [dbName, setDbName] = useState('')
  const [schema, setSchema] = useState('')
  const [user, setUser] = useState('')
  const [instance, setInstance] = useState('')

  const buttonColorScheme = useColorModeValue('blue', 'green')

  const validateRequiredField = (value) => {
    let error
    if (!value || value.length < 1) {
      error = 'Required'
    }
    return error
  }

  const fetchHelmValues = (value) =>
  ({
    name: value?.name,
    namespace: value?.namespace,
    database: value?.database?.name,
    schema: value?.database?.schema,
    user: value?.database?.user
  })

  const fetchFdcValues = (value) =>
  ({
    name: value?.metadata?.name,
    namespace: value?.metadata?.namespace,
    database: value?.spec?.template?.spec?.deployment?.db?.name?.prod,
    schema: value?.spec?.template?.spec?.deployment?.db?.schema?.prod,
    user: value?.spec?.template?.spec?.deployment?.db?.user?.prod
  })

  const validateYAML = (value) => {
    let error
    if (!value || value.length < 1) {
      error = 'A valid deployment.yaml with all necessary fields is required'
    }
    return error
  }

  const setLivePreview = (name, namespace, dbName, schema, user, instance) => {
    setAppName(name)
    setNamespace(namespace)
    setDbName(dbName)
    setSchema(schema)
    setUser(user)
    setInstance(instance)
  }

  const resetLivePreview = (error) => {
    setAppName('')
    setNamespace('')
    setDbName('')
    setSchema('')
    setUser('')
    setInstance('')
    setYamlError(error || '')
  }

  const isValidValues = (values) => {
    var valid = true;
    Object.values(values).forEach(value => {
      if (!value) {
        valid = false;
      }
    })
    return valid;
  }

  const validate = useCallback((parsed, e, form, fieldName) => {
    var valid = false;

    [fetchHelmValues(parsed), fetchFdcValues(parsed)].forEach(values => {
      if (isValidValues(values)) {
        setLivePreview(values.name, values.namespace, values.database, values.schema, values.user, parsed?.spec?.template?.spec?.deployment?.db?.instance);
        form.setFieldValue(fieldName, e.target.value);
        valid = true;
      }
    });

    return valid;
  }, [])

  const onInputChange = (e, form, fieldName) => {
    setYaml(e.target.value)
    setYamlError('')
    resetLivePreview()

    try {
      const parsed = parse(e.target.value);

      if (!validate(parsed, e, form, fieldName)) {
        form.setFieldValue(fieldName, '');
      }
    } catch (e) {
      resetLivePreview(e.message);
      form.setFieldValue(fieldName, '');
    }
  }

  const submitForm = (values, actions) => {
    onSubmit({
      project: values.project,
      app_name: appName,
      name: dbName,
      namespace: namespace,
      schema: schema,
      user: user,
      data_classification: values.dataClassification
    }, actions)
  }

  return (
    <Formik initialValues={{ project: '', yaml: '', dataClassification: '' }} onSubmit={submitForm}>
      {({ isSubmitting }) => (
        <Form>
          <Field name='project' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.project && form.touched.project}>
                <FormLabel htmlFor='project'>Project</FormLabel>
                <Select {...field} id='project' placeholder='Select a project'>
                  {projects.map((p) => (
                    <option key={p.project_id} value={p.project_id}>{p.display_name}</option>
                  ))}
                </Select>
                <FormHelperText>Pick which project your application will run in</FormHelperText>
                <FormErrorMessage>{form.errors.project}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Grid templateColumns='repeat(5, 1fr)' gap={4}>

            <GridItem colSpan={3} >
              <Flex h='full'>
                <Field name='yaml' validate={validateYAML}>
                  {({ field, form }) => (
                    <FormControl isRequired mb={5} isInvalid={form.errors.yaml && form.touched.yaml}>
                      <FormLabel htmlFor='yaml'>Paste your <Code>deployment.yaml</Code> or <Code>values.test.yaml</Code> file</FormLabel>
                      <Textarea h='80%' {...field} id='yaml' resize='vertical' value={yaml} onChange={(e) => onInputChange(e, form, field.name)} />
                      <FormErrorMessage>{form.errors.yaml}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
            </GridItem>

            <GridItem colSpan={2} overflow={'hidden'}>
              <Text>Live preview</Text>

              <TableContainer>
                <Table variant='unstyled'>
                  <Tbody>
                    <Tr>
                      <Td><strong>App name</strong></Td>
                      <Td>{appName}</Td>
                    </Tr>

                    <Tr>
                      <Td><strong>Namespace</strong></Td>
                      <Td>{namespace}</Td>
                    </Tr>

                    <Tr>
                      <Td><strong>Database Name</strong></Td>
                      <Td>{dbName}</Td>
                    </Tr>

                    <Tr>
                      <Td><strong>User</strong></Td>
                      <Td>{user}</Td>
                    </Tr>

                    <Tr>
                      <Td><strong>Schema</strong></Td>
                      <Td>{schema}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </GridItem>
          </Grid>

          <Field name='dataClassification' validate={validateRequiredField}>
            {({ field, form }) => (
              <FormControl isRequired mb={5} isInvalid={form.errors.dataClassification && form.touched.dataClassification}>
                <FormLabel htmlFor='dataClassification'>Data Classification</FormLabel>
                <Select {...field} id='dataClassification' placeholder='Select a Data Classification'>
                  <option key='confidential' value='confidential'>Confidential</option>
                  <option key='internal' value='internal'>Internal</option>
                </Select>
                <FormHelperText>Please see the <Link href='https://www.notion.so/figuretech/Data-Classification-122c06bd5c2e80b189d4c3c325b84785?pvs=4'>Data Classification</Link> page for more information.</FormHelperText>
                <FormErrorMessage>{form.errors.dataClassification}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {instance && (
            <Alert status='warning' mb={5}>
              <AlertIcon />
              <AlertDescription>An <Code>instance</Code> was specified in your deployment.yaml. This value will need to be changed after your request is approved. The instance name will be provided to you.</AlertDescription>
            </Alert>
          )}

          <Text mb={5} color='red'>{yamlError}</Text>

          <Button
            colorScheme={buttonColorScheme}
            isLoading={isSubmitting}
            type='submit'
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}
