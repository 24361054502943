import { useState } from 'react'
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Center,
    Flex,
    Grid,
    GridItem,
    Icon,
    List,
    ListIcon,
    ListItem,
    Spinner,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useGetUsersQuery } from '../../apis/org-chart'
import { useGetRequestByIdQuery, useUpdateRequestMutation } from '../../apis/approval-engine'
import { FiCheckCircle, FiChevronRight, FiXCircle } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { approversTransform } from '../../utils/approversTransform'
import { DecideWithCommentModal } from '../../components/DecideWithCommentModal'

export const ApprovalDetails = () => {
  const { id } = useParams()
  const toast = useToast()
  const { data: approvalRequest = {}, isLoading: isLoadingApprovalRequest, isError: isErrorApprovalRequest } = useGetRequestByIdQuery(id)
  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersQuery()
  const [updateRequest] = useUpdateRequestMutation()
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showDenyModal, setShowDenyModal] = useState(false)

  const getApprovers = () => {
    if (isLoadingApprovalRequest || isLoadingUsers || isErrorApprovalRequest) {
      return {}
    }

    return approversTransform(approvalRequest, users)
  }

  const approveRequest = async (values, actions) => {
    await decideOnRequest('approve', values.comment)
    actions.setSubmitting(false)
  }

  const denyRequest = async (values, actions) => {
    await decideOnRequest('deny', values.comment)
    actions.setSubmitting(false)
  }

  const decideOnRequest = async (decision, comment) => {
    const resp = await updateRequest({ id, decision: decision, comment: comment })
    if (resp.error) {
      toast({
        title: `Failed to ${decision} the request.`,
        description: resp.error.data.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      setShowApproveModal(false)
      setShowDenyModal(false)
      toast({
        title: 'Successfully updated the request.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
    }
  }

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Approvals</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex mt={5} justifyContent='space-between'>
        <Text fontSize="2xl" fontWeight="medium">
            Details
        </Text>
      </Flex>

      {isLoadingApprovalRequest ? (
        <Spinner />
      ) : (
        <Box mt={5}>
          <Grid templateColumns='repeat(2, 1fr)'>
            <Grid templateColumns='200px 1fr' gap={2}>
              <GridItem as='b'><Text align='right' pr={5}>Requestor</Text></GridItem><GridItem><Text>{users.find((u) => u.id === approvalRequest.user_id)?.email || approvalRequest.user_id}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Status</Text></GridItem><GridItem><Text><Icon {...iconPropsFromStatus(approvalRequest.status)} /> {approvalRequest.status}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Type</Text></GridItem><GridItem><Text>{approvalRequest.type}</Text></GridItem>
              <GridItem as='b'><Text align='right' pr={5}>Business Justification</Text></GridItem><GridItem><Text>{approvalRequest.business_justification}</Text></GridItem>
            </Grid>

            <Grid templateColumns='200px 1fr' gap={2}>
              <GridItem as='b'><Text align='right' pr={5}>Requested Date</Text></GridItem><GridItem><Text>{new Date(approvalRequest.requested_date * 1000).toDateString()}</Text></GridItem>
              {approvalRequest.status === 'pending' && (
                <>
                  <GridItem as='b'>
                    <Text align='right' pr={5}>Expires</Text></GridItem><GridItem><Text>{new Date(approvalRequest.request_expiration * 1000).toDateString()}</Text>
                  </GridItem>
                </>
              )}
              {approvalRequest.error && (
                <>
                  <GridItem as='b'>
                    <Text align='right' pr={5}>Error</Text></GridItem><GridItem><Text>{approvalRequest.error}</Text>
                  </GridItem>
                </>
              )}
              <GridItem as='b'><Text align='right' pr={5}>Approvals</Text></GridItem>
              <GridItem>
                {Object.entries(getApprovers()).map(([groupName, approvers]) => (
                  <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(2, 1fr)' key={groupName}>
                    <GridItem rowSpan={2}>
                      <Text as='i'>{groupName}</Text>
                    </GridItem>
                    <GridItem>
                      <List spacing={3}>
                        {approvers.map((a) => (
                          <ListItem key={a.uid}>
                            <ListIcon {...iconPropsFromStatus(a.status)} /> {a.email} <i>{a.comment ? a.comment : a.status !== 'denied' ? '' : 'no comment was provided'}</i>
                          </ListItem>
                        ))}
                      </List>
                    </GridItem>
                  </Grid>
                ))}
              </GridItem>
            </Grid>
          </Grid>

          {approvalRequest.status === 'pending' && (
            <Stack spacing="2" mt="5">
              <Center>
                <Text as="b">Admin Override</Text>
              </Center>

              <Center>
                <Text color="fg.subtle">A comment will be required</Text>
              </Center>

              <Center>
                  <Button mr={5} colorScheme='green' aria-label='Approve the request' leftIcon={<FiCheckCircle />} onClick={() => setShowApproveModal(true)}>Approve</Button>
                  <Button colorScheme='red' aria-label='Deny the request' leftIcon={<FiXCircle />} onClick={() => setShowDenyModal(true)}>Deny</Button>
              </Center>
            </Stack>
          )}
        </Box>
      )}

      <DecideWithCommentModal action='approve' colorScheme='green' commentRequired={true} onSubmit={approveRequest} isOpen={showApproveModal} onClose={() => setShowApproveModal(false)} />
      <DecideWithCommentModal action='deny' colorScheme='red' commentRequired={true} onSubmit={denyRequest} isOpen={showDenyModal} onClose={() => setShowDenyModal(false)} />
    </Box>
  )
}
