import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  IconButton,
  Input,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  useSearchDeploymentsQuery,
  useRollbackDeploymentMutation,
  useRedeployDeploymentMutation,
  useScaleDeploymentMutation,
} from '../../apis/tops-service'
import { FiCheckCircle, FiChevronRight, FiMinus, FiPlus, FiRefreshCw, FiRotateCcw, FiXCircle } from 'react-icons/fi'
import { poorMansParse, poorMansFormat } from '../../utils/dateUtils'
import { DataTable } from '../../components/DataTable'
import { useMemo, useState } from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const DeploymentDetails = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const { data: deployments = [], isLoading } = useSearchDeploymentsQuery({search: id})
  const [submitRollback] = useRollbackDeploymentMutation()
  const [submitRedeploy] = useRedeployDeploymentMutation()
  const [submitScale] = useScaleDeploymentMutation()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [operation, setOperation] = useState('')
  const [operationId, setOperationId] = useState('')
  const [desiredReplicas, setDesiredReplicas] = useState(1)

  const uniqueClusterToDatadogEnvMapping = {
    "figure-dev": "development",
    "figure-prod": "production",
  }
  const allowableApmKinds = ['figuredeployment', 'provenancedeployment', 'deployment']

  const finishedCount = deployments.filter((d) => d.finished).length
  const successfulCount = deployments.filter((d) => d.successful).length
  const datadogServiceName = deployments.filter((d) => allowableApmKinds.includes(d.kind.toLowerCase()))?.[0]?.name
  const apmLink = datadogServiceName ? `https://app.datadoghq.com/apm/services/${datadogServiceName}/operations/servlet.request/resources?env=${uniqueClusterToDatadogEnvMapping[deployments[0].cluster] || deployments[0].cluster}` : ''

  const resourceOperation = async (id, op, replicas) => {
    let f
    if (op === 'rollback') {
      f = submitRollback
    } else if (op === 'redeploy') {
      f = submitRedeploy
    } else if (op === 'scale') {
      f = submitScale
    }
    const resp = await f({ id: id, replicas: replicas })
    onClose()
    if (resp.error && resp.error.data) {
      toast({
        title: `Failed to ${op}.`,
        description: resp.error.data || resp.error.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      toast({
        title: `${op} submitted successfully`,
        status: 'success',
        duration: 7000,
        isClosable: true
      })
      navigate(`../details/${resp.data.messageID}`)
    }
  }

  const columns = useMemo(
    () => [
        {
            Header: 'Status',
            Cell: ({ row }) => (
              <HStack>
                {row.original.successful ? (
                  <FiCheckCircle color='green' />
                ) : row.original.finished ? (
                  <FiXCircle color='red' />
                ) : (
                  <Spinner />
                )}
                <Text>{row.original.status}</Text>
              </HStack>
            ),
            id: 'status',
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Namespace',
            accessor: 'namespace',
        },
        {
            Header: 'Kind',
            accessor: 'kind',
        },
        {
            Header: 'Friendly Version',
            accessor: 'friendlyVersion',
        },
        {
            Header: 'User',
            accessor: 'user',
        },
        {
            Header: 'Created At',
            Cell: ({ row }) => (
              <Tooltip label={poorMansParse(row.original.createdAt).toLocaleString()}>
                {poorMansFormat(row.original.createdAt)}
              </Tooltip>
            ),
            id: 'createdAt',
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
              <HStack>
                <Tooltip label='Rollback this resource'>
                  <IconButton
                    variant='ghost'
                    colorScheme='blue'
                    aria-label='Rollback this resource'
                    icon={<FiRotateCcw />}
                    onClick={() => {
                      setOperationId(row.original.id)
                      setOperation('rollback')
                      onOpen()
                    }}
                  />
                </Tooltip>
                <Tooltip label='Redeploy this resource'>
                  <IconButton
                    variant='ghost'
                    colorScheme='blue'
                    aria-label='Redeploy this resource'
                    icon={<FiRefreshCw />}
                    onClick={() => {
                      setOperationId(row.original.id)
                      setOperation('redeploy')
                      onOpen()
                    }}
                  />
                </Tooltip>
                <Tooltip label='Scale this resource'>
                  <IconButton
                    variant='ghost'
                    colorScheme='blue'
                    aria-label='Scale this resource'
                    icon={
                      <div>
                        <FiPlus />
                        <FiMinus />
                      </div>
                    }
                    onClick={() => {
                      setOperationId(row.original.id)
                      setOperation('scale')
                      onOpen()
                    }}
                  />
                </Tooltip>
              </HStack>
            ),
            id: 'options',
        },
    ],
  [onOpen])

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Deployments</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Stack align="start" mt={5}>
        <Text fontSize="2xl" fontWeight="medium">
            {isLoading ? 'Details' : `${deployments[0].operation} in ${deployments[0].cluster}`}
        </Text>
        {apmLink !== '' && (
          <Button variant='text' as={ChakraLink} target='_blank' rel='noopener noreferrer' href={apmLink}>
            View Datadog APM &nbsp; <ExternalLinkIcon mx='2px' />
          </Button>
        )}
      </Stack>

      {isLoading ? (
        <Spinner />
      ) : (
        <Stack mt="5" spacing="5">
          <Progress
            hasStripe={finishedCount !== deployments.length}
            colorScheme={
              finishedCount === deployments.length
                ? successfulCount === deployments.length
                    ? 'green'
                    : 'red'
                : 'blue'
            }
            value={(finishedCount / deployments.length) * 100}
          />
          <Text>{`${finishedCount} of ${deployments.length} resources applied`}</Text>

          <DataTable
            data={deployments}
            columns={columns}
            sortByField='name'
            sortDesc={false}
            enableSearch={false}
            enableRowNavigation={false}
          />
        </Stack>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Are you sure you want to perform a {operation} operation?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{operation === 'scale' && (
            <>
              <Text>Desired replicas:</Text>
              <Input
                value={desiredReplicas}
                placeholder='Desired Replicas'
                size='xs'
                onChange={(event) => setDesiredReplicas(event.target.value)}
                type='number'
              />
            </>
          )}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={() => resourceOperation(operationId, operation, desiredReplicas)}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
