import { useMemo } from 'react'
import { useGetRequestsQuery } from '../../apis/approval-engine'
import { useGetUsersQuery, useGetUserByIdQuery } from '../../apis/org-chart'
import {
    Box,
    HStack,
    Icon,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DividerTitle } from '../../components/DividerTitle'
import { DataTable } from '../../components/DataTable'
import { AccessibleResource } from '../../components/AccessibleResource'

export const TeamAccess = () => {
  const { data: user, isLoading: isLoadingSelf } = useGetUserByIdQuery('me')

  const { data: requests = [], isLoading: isLoadingRequests } = useGetRequestsQuery({forTeam: user?.team_id}, { skip: isLoadingSelf || !user?.team_id })

  const { data: rawUsers = [], isLoading: isLoadingUsers } = useGetUsersQuery()

  const users = useMemo(() => {
    const users = {}
    rawUsers.forEach((user) => {
      users[user.id] = user
    })
    return users
  }, [rawUsers])

  const filteredRequests = useMemo(() => {
    return requests.filter((r) => r.type === "Database Access" || r.type === "Vault Access")
  }, [requests])

  const hasPending = filteredRequests.filter((r) => r.status === 'pending').length > 0

  const columns = useMemo(
    () => [
        {
            Header: 'User',
            accessor: (row, _) => `${users[row.user_id]?.first_name || 'Unknown'} ${users[row.user_id]?.last_name}`,
            id: 'user',
        },
        {
            Header: 'Requested Date',
            accessor: 'requested_date',
            Cell: ({ row }) => (
                <Text>{new Date(row.original.requested_date * 1000).toDateString()}</Text>
            ),
            id: 'requested_date',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Resource',
            Cell: ({ row }) => (
                <AccessibleResource request={row.original} />
            ),
            id: 'resource',
        },
        {
            Header: 'Approvals',
            accessor: (row, _) => row.required_approvals[0],
            Cell: ({ row }) => (
                <VStack>
                    {Object.keys(row.original.required_approvals).map((groupName) => (
                        <Text key={groupName} as='i'>{groupName}</Text>
                    ))}
                </VStack>
            ),
            id: 'approvals',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => {
                return <HStack>
                    <Icon {...iconPropsFromStatus(row.original.status)} />
                    <Text>{row.original.status}</Text>
                </HStack>
            },
            id: 'status',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [users])

  const pendingColumns = useMemo(
    () => {
        const cols = columns.slice()
        cols.splice(4, 1, {
            Header: 'Expires',
            accessor: 'request_expiration',
            Cell: ({ row }) => (
                <Text>{new Date(row.original.request_expiration * 1000).toDateString()}</Text>
            ),
            id: 'request_expiration',
        })
        return cols
    }, [columns])

  return (
    <Box>
        <Stack px={{ base: '4', md: '6' }} py="5">
            <Text fontSize="2xl" fontWeight="medium">
                My Team's Access
            </Text>
            <Text>This is where you (as a team lead) can view and manage the access your team member's have. You can manage this access even if the resources aren't owned by your team.</Text>
        </Stack>
        {isLoadingRequests || isLoadingSelf || isLoadingUsers ? (
            <LoadingSpinner />
        ) : (
            <>
                {hasPending && (
                <>
                    <DividerTitle title='Awaiting your decision' />

                    <DataTable
                        data={filteredRequests.filter((req) => req.status === 'pending')}
                        columns={pendingColumns}
                        enableSearch={false}
                        sortByField='requested_date'
                        sortDesc={false}
                    />

                    <DividerTitle title='All Access' />
                </>
                )}

                <DataTable
                    data={filteredRequests.filter((req) => req.status === 'approved')}
                    columns={columns}
                    enableSearch={false}
                    sortByField='requested_date'
                    sortDesc={false}
                />
            </>
        )}
    </Box>
  )
}
