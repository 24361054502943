import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Code,
    Flex,
    Spinner,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Text,
    useToast,
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useCreateDatabaseMutation, useGetProjectsQuery } from '../../apis/database-builder'
import { FiChevronRight } from 'react-icons/fi'
import { ManualInputTab } from './ManualInputTab'
import { DeploymentYAMLTab } from './DeploymentYAMLTab'

export const RequestDatabase = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { data: projects = [], isLoading: isLoadingProjects } = useGetProjectsQuery()

  const [requestNewDatabase] = useCreateDatabaseMutation()

  const onSubmit = async (values, actions) => {
    values.environment = projects.filter((p) => p.project_id === values.project)[0].environment
    const resp = await requestNewDatabase({ ...values })
    actions.setSubmitting(false)
    if (resp.error) {
      toast({
        title: 'Failed to submit your request',
        description: resp.error.data.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Successfully submitted your request.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
      navigate(`../details/${resp.data.id}`)
    }
  }

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Databases</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>New</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex mt={5} justifyContent='space-between'>
        <Text fontSize="2xl" fontWeight="medium">
            Request A New Database
        </Text>
      </Flex>

      <Text mb={5}>To request a new database, we need a few pieces of info. You can provide your <Code>values.(test|prod).yaml</Code> or <Code>deployment.yaml</Code>
        where the information will be grabbed automatically, or you can input it manually.
      </Text>

      <Text mb={5}>Your request will be reviewed by the DevOps team. If approved, your database will automatically be created and
      you will be notified via Slack when it's ready to use.</Text>

      <Text mb={5}>Please note: the name of the instance will be provided to you once the request is approved and provisioning has begun.
        This will require you to update your <Code>values.(test|prod).yaml</Code> or <Code>deployment.yaml</Code> with the assigned instance name.
      </Text>

      <Tabs>
        <TabList>
          <Tab>YAML</Tab>
          <Tab>Manual</Tab>
        </TabList>

        {isLoadingProjects
          ? (
            <Spinner />
            )
          : (
            <TabPanels>
              <TabPanel>
                <DeploymentYAMLTab onSubmit={onSubmit} projects={projects} />
              </TabPanel>
              <TabPanel>
                <ManualInputTab onSubmit={onSubmit} projects={projects} />
              </TabPanel>
            </TabPanels>
            )}
      </Tabs>
    </Box>
  )
}
