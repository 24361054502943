import {
    Tag,
} from '@chakra-ui/react'
import { useColorSchemes } from "../utils/color";

export const AppTypeTag = ({ isHelmChart }) => {
    const { helmChartColorScheme, fdColorScheme } = useColorSchemes();
    return (
        <Tag colorScheme={isHelmChart ? helmChartColorScheme : fdColorScheme}>{isHelmChart ? 'Helm Chart' : 'FD'}</Tag>
    )
}
