import { Button, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, VStack, Input, Text } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const TeamSlackChannelsModal = ({ team = {}, colorScheme, onSubmit, isOpen, onClose }) => {
  return (
    <Modal size='xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Updating {team.name} Slack channels</ModalHeader>
        <ModalCloseButton />
        <Formik initialValues={{ slack_channel: team.slack_channel, alerting_slack_channel: team.alerting_slack_channel, rollbar_slack_channel: team.rollbar_slack_channel }} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <VStack alignItems='flex-start' spacing='15px'>
                  <Text>Do not include the '#' character in these channel names.</Text>

                  <Field name='slack_channel'>
                  {({ field }) => (
                      <FormControl>
                          <FormLabel>General</FormLabel>
                          <Input {...field} variant='filled' type="text" />
                      </FormControl>
                  )}
                  </Field>

                  <Field name='alerting_slack_channel'>
                  {({ field }) => (
                      <FormControl>
                          <FormLabel>Alerts</FormLabel>
                          <Input {...field} variant='filled' type="text" />
                      </FormControl>
                  )}
                  </Field>

                  <Field name='rollbar_slack_channel'>
                  {({ field }) => (
                      <FormControl>
                          <FormLabel>Rollbar</FormLabel>
                          <Input {...field} variant='filled' type="text" />
                      </FormControl>
                  )}
                  </Field>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme={colorScheme} isLoading={isSubmitting} type="submit">
                  Save
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
