import { createApi } from '@reduxjs/toolkit/query/react'
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from './retry'


export const databaseBuilderApi = createApi({
  reducerPath: 'databaseBuilderApi',
  baseQuery: baseQueryWithUnAuthRetry(config.databaseBuilderApiUrl),
  tagTypes: ['DatabaseBuilderDatabase', 'PendingDatabase', 'DatabaseStatus', 'Instance', 'InstanceStatus', 'Project', 'Backup', 'BackupStatus'],
  endpoints: (builder) => ({
    // databases
    getDatabases: builder.query({
      query: () => '/databases',
      providesTags: (result = [], error, arg) => [
        'DatabaseBuilderDatabase',
        ...result.map(({ id }) => ({ type: 'DatabaseBuilderDatabase', id }))
      ]
    }),
    getDatabaseById: builder.query({
      query: (id) => `/databases/${id}`,
      providesTags: (result, error, arg) => [{ type: 'DatabaseBuilderDatabase', id: arg }]
    }),
    getDatabasesByInstanceId: builder.query({
      query: (instanceId) => `/databases/instance/${instanceId}`,
      providesTags: (result = [], error, arg) => [
        'DatabaseBuilderDatabase',
        ...result.map(({ id }) => ({ type: 'DatabaseBuilderDatabase', id }))
      ]
    }),
    createDatabase: builder.mutation({
      query: ({ ...body }) => ({
        url: '/databases',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['DatabaseBuilderDatabase']
    }),
    getDatabasesByStatus: builder.query({
      query: (status) => `/databases/status/${status}`,
      providesTags: (result = [], error, arg) => [
        'DatabaseBuilderDatabase',
        ...result.map(({ id }) => ({ type: 'DatabaseBuilderDatabase', id }))
      ]
    }),

    // pending dbs
    getPendingDatabases: builder.query({
      query: () => '/import-databases',
      providesTags: (result = [], error, arg) => [
        'PendingDatabase',
        ...result.map(({ id }) => ({ type: 'PendingDatabase', id }))
      ]
    }),
    getPendingDatabaseById: builder.query({
      query: (id) => `/import-databases/${id}`,
      providesTags: (result, error, arg) => [{ type: 'PendingDatabase', id: arg }]
    }),
    updatePendingDatabase: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/import-databases/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['PendingDatabase']
    }),
    importPendingDatabase: builder.mutation({
      query: (id) => ({
        url: `/import-databases/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['PendingDatabase']
    }),
    ignorePendingDatabase: builder.mutation({
      query: (id) => ({
        url: `/import-databases/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['PendingDatabase']
    }),

    // instances
    getInstanceById: builder.query({
      query: (id) => `/instances/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Instance', id: arg }]
    }),
    getInstances: builder.query({
      query: () => '/instances',
      providesTags: (result = [], error, arg) => [
        'Instance',
        ...result.map(({ id }) => ({ type: 'Instance', id }))
      ]
    }),
    getInstancesByStatus: builder.query({
      query: (status) => `/instances/status/${status}`,
      providesTags: (result = [], error, arg) => [
        'Instance',
        ...result.map(({ id }) => ({ type: 'Instance', id }))
      ]
    }),

    // statuses
    getBackupStatuses: builder.query({
      query: () => '/statuses/backup',
      providesTags: (result = [], error, arg) => [
        'BackupStatus',
        ...result.map(({ status }) => ({ type: 'BackupStatus', status }))
      ]
    }),
    getDatabaseStatuses: builder.query({
      query: () => '/statuses/database',
      providesTags: (result = [], error, arg) => [
        'DatabaseStatus',
        ...result.map(({ status }) => ({ type: 'DatabaseStatus', status }))
      ]
    }),
    getInstanceStatuses: builder.query({
      query: () => '/statuses/instance',
      providesTags: (result = [], error, arg) => [
        'InstanceStatus',
        ...result.map(({ status }) => ({ type: 'InstanceStatus', status }))
      ]
    }),

    // projects
    getProjects: builder.query({
      query: () => '/projects',
      providesTags: (result = [], error, arg) => [
        'Project',
        ...result.map(({ project_id }) => ({ type: 'Project', id: project_id })) // eslint-disable-line camelcase
      ]
    }),

    // backups
    getBackupById: builder.query({
      query: (id) => `/backups/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Backup', id: arg }]
    }),
    getBackups: builder.query({
      query: () => '/backups',
      providesTags: (results = [], error, arg) => [
          'Backup',
        ...results.map(({ backup_id }) => ({ type: 'Backup', id: backup_id })) // eslint-disable-line camelcase
      ]
    }),
    getBackupsByDatabaseId: builder.query({
      query: (id) => `/backups/database/${id}`,
      providesTags: (results = [], error, arg ) => [
          'Backup',
        ...results.map(({ backup_id }) => ({ type: 'Backup', id: backup_id })) // eslint-disable-line camelcase
      ]
    }),
    getBackupsByStatus: builder.query({
      query: (status) => `/backups/status/${status}`,
      providesTags: (result = [], error, arg) => [
          'Backup',
        ...result.map(({ id }) => ({ type: 'Backup', id })) // eslint-disable-line camelcase
      ]
    }),
    postBackup: builder.mutation({
      query: (id) => ({
        url: '/backups',
        method: 'POST',
        body: {database_id: id}
      }),
      invalidatesTags: ['Backup']
    })
  })
})

export const {
  useGetDatabasesQuery,
  useGetDatabaseByIdQuery,
  useGetDatabasesByInstanceIdQuery,
  useCreateDatabaseMutation,
  useGetDatabasesByStatusQuery,

  useGetPendingDatabasesQuery,
  useGetPendingDatabaseByIdQuery,
  useUpdatePendingDatabaseMutation,
  useImportPendingDatabaseMutation,
  useIgnorePendingDatabaseMutation,

  useGetInstanceByIdQuery,
  useGetInstancesQuery,
  useGetInstancesByStatusQuery,

  useGetBackupStatusesQuery,
  useGetDatabaseStatusesQuery,
  useGetInstanceStatusesQuery,

  useGetProjectsQuery,

  useGetBackupByIdQuery,
  useGetBackupsQuery,
  useGetBackupsByDatabaseIdQuery,
  useGetBackupsByStatusQuery,
  usePostBackupMutation

} = databaseBuilderApi
