import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Stack,
  Switch,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const MiscCard = ({ innerRef, ...props }) => {
  return (
    <Formik initialValues={{ "redis.enabled": true, "vault.enabled": true, "datadog.enabled": true, "crossCutter.enabled": false }} innerRef={innerRef}>
      <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
        <Stack
          spacing="5"
          px={{ base: "4", md: "6" }}
          py={{ base: "5", md: "6" }}
        >
          <Field name="['redis.enabled']">
            {({ field }) => (
              <FormControl id="redis">
                  <HStack spacing="3">
                    <Switch {...field} isChecked={field.value} />
                    <FormLabel>Redis</FormLabel>
                  </HStack>

                  <FormHelperText color="fg.subtle">If your app connects to redis, keep this checked. Redis connection details will be added to your configs.</FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name="['vault.enabled']">
            {({ field }) => (
              <FormControl id="vault">
                  <HStack spacing="3">
                    <Switch {...field} isChecked={field.value} />
                    <FormLabel>Vault</FormLabel>
                  </HStack>

                  <FormHelperText color="fg.subtle">Enable injecting Vault secrets. You don't need to create any secrets ahead of time, an empty secret will be created for you.</FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name="['datadog.enabled']">
            {({ field }) => (
              <FormControl>
                  <HStack spacing="3">
                    <Switch {...field} isChecked={field.value} />
                    <FormLabel>Datadog</FormLabel>
                  </HStack>
                  <FormHelperText color="fg.subtle">Enable injecting the Datadog tracing library.</FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name="['crossCutter.enabled']">
            {({ field }) => (
              <FormControl>
                  <HStack spacing="3">
                    <Switch {...field} isChecked={field.value} />
                    <FormLabel>Cross Cutter</FormLabel>
                  </HStack>
                  <FormHelperText color="fg.subtle">Enable cross cutter.</FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>
      </Box>
    </Formik>
  )
}
