import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  ChakraProvider,
  ColorModeScript,
} from '@chakra-ui/react'
import { theme } from './utils/theme'
import { RouterProvider } from "react-router-dom";
import { router } from './router'
import { store } from './apis/store'
import { Provider } from 'react-redux'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { config } from './config'

const oktaAuth = new OktaAuth(config.okta)

const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  // now that the user has successfully logged in, put the token in the redux store.
  // this allows rtk query to be able to grab it and inject it into all requests
  // sent to the backend.
  // the user parameter here will eventually be used once I figure out how to get that info from _oktaAuth.
  // if we put the user info in the redux store, we dont need to have a loading indicator on the /home screen.
  // okta is slow at decoding the id token for some reason
  // const accessToken = _oktaAuth.getAccessToken()
  // oktaAuth?.getUser().then((u) => {
  //   datadogRum.setUser({ id: u.sub }) // you can replace u.sub with u.email if you want that instead
  // })
  // dispatch(setCredentials({ user: {}, token: accessToken }))
  window.location.replace(toRelativeUrl(originalUri))
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Provider store={store}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />

        <ChakraProvider theme={theme}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </Provider>
    </Security>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
