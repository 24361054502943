import { useRef, useMemo } from 'react'
import {
    Box,
    Button,
    Container,
    Flex,
    Spacer,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import { DatabaseCard } from './DatabaseCard'
import { useGetDatabasesQuery, useGetInstancesQuery } from '../../../apis/database-builder'

export const Database = ({ setYamlField, deleteYamlField, goToNextStep, goToPrevStep }) => {
  const { data: databases = [], isLoading: isLoadingDatabases } = useGetDatabasesQuery()
  const { data: instances = [], isLoading: isLoadingInstances } = useGetInstancesQuery()

  const databaseRef = useRef()

  const instancesMap = useMemo(() => {
    const instancesMap = {}
    instances.forEach(inst => {
      instancesMap[inst.id] = inst
    })
    return instancesMap
  }, [instances])

  const databasesMap = useMemo(() => {
    const databasesMap = {}
    databases.forEach(db => {
      databasesMap[db.id] = db
    })
    return databasesMap
  }, [databases])

  const sortedDatabases = useMemo(() => {
    const sortedDatabases = databases.slice()
    sortedDatabases.sort((a, b) => {
      // sort by database name first, instance name second
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      } else if (instancesMap[a.instance_id || '']?.name?.toLowerCase() > instancesMap[b.instance_id || '']?.name?.toLowerCase()) {
        return 1
      } else if (instancesMap[a.instance_id || '']?.name?.toLowerCase() < instancesMap[b.instance_id || '']?.name?.toLowerCase()) {
        return -1
      } else {
        return 0
      }
    })
    return sortedDatabases.map((d) => ({ value: d.id, label: `${d.name} (${instancesMap[d.instance_id || '']?.name || 'database is not ready'}/${d.project})` }))
  }, [databases, instancesMap])

  const changePage = (change) => {
    setYamlField("database.type", databaseRef.current.values["database.type"])

    const db = databasesMap[databaseRef.current.values["databaseId"]] || {}
    const instance = instancesMap[db.instance_id] || {}

    switch (databaseRef.current.values["database.type"]) {
      case "proxy":
        deleteYamlField("database.pod")
        deleteYamlField("database.connector")

        setYamlField("database.name", db.name || "unknown")
        setYamlField("database.schema", db.schema || "unknown")
        setYamlField("database.user", db.app_user || "unknown")

        setYamlField("database.proxy.instance", instance.name || "unknown")
        setYamlField("database.proxy.project", instance.project || "unknown")
        setYamlField("database.proxy.region", instance.region || "unknown")
        break
      case "connector":
        deleteYamlField("database.pod")
        deleteYamlField("database.proxy")

        setYamlField("database.name", db.name || "unknown")
        setYamlField("database.schema", db.schema || "unknown")
        setYamlField("database.user", db.app_user || "unknown")

        setYamlField("database.connector.instance", instance.name || "unknown")
        setYamlField("database.connector.project", instance.project || "unknown")
        setYamlField("database.connector.region", instance.region || "unknown")
        break
      default:
    }
    if (databaseRef.current.values["database.migrations.enabled"]) {
      setYamlField("database.migrations.enabled", true)
    } else {
      setYamlField("database.migrations", {enabled: false})
    }
    change()
  }

  return (
    <Container
        py={{
          base: '4',
          md: '8',
        }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction={{
              base: 'column',
              lg: 'row',
            }}
            spacing={{
              base: '5',
              lg: '8',
            }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Database
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Choose how you'll connect to your database.
              </Text>
            </Box>
            <DatabaseCard
              innerRef={databaseRef}
              databases={sortedDatabases}
              isLoadingDatabases={isLoadingDatabases}
              instances={instances}
              isLoadingInstances={isLoadingInstances}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

            <Flex>
                <Button variant="primary" onClick={() => changePage(goToPrevStep)}>
                    Previous
                </Button>
                <Spacer />
                <Button variant="primary" onClick={() => changePage(goToNextStep)}>
                    Next
                </Button>
            </Flex>
        </Stack>
      </Container>
    )
}
