import { useMemo } from 'react'
import { Icon } from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { useGetInstancesQuery, useGetPendingDatabasesQuery } from '../../apis/database-builder'
import { DataTable } from '../../components/DataTable'
import { LoadingSpinner } from '../../components/LoadingSpinner'

export const PendingDatabases = () => {
  const { data: rawInstances = [], isLoading: isLoadingInstances } = useGetInstancesQuery()
  const { data: pendingDatabases = [], isLoading: isLoadingPendingDatabases } = useGetPendingDatabasesQuery()

  const instances = useMemo(() => {
    const instances = {}
    rawInstances.forEach(instance => { instances[instance.id] = instance })
    return instances
  }, [rawInstances])

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'App',
            accessor: 'app_name',
        },
        {
            Header: 'Namespace',
            accessor: 'namespace',
        },
        {
            Header: 'Schema',
            accessor: 'schema',
        },
        {
            Header: 'User',
            accessor: 'app_user',
        },
        {
            Header: 'Instance',
            accessor: (row, _) => instances[row.instance_id]?.name || 'pending',
            id: 'instance',
        },
        {
            Header: 'Project',
            accessor: 'project',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [instances])

  return (
    isLoadingPendingDatabases || isLoadingInstances ? (
        <LoadingSpinner />
    ) : (
        <DataTable
            data={pendingDatabases}
            columns={columns}
            title='Pending Databases'
            description="These databases exist in GCP, but are not being tracked by database-builder. In order to import them into database-builder,
                you need to verify the information for each one (such as the app name, namespace, schema, etc.), updating it if necessary. Once the
                information has been verified, the database can be imported and made available for viewing and access requests. You can also ignore
                the database if you never want to import it."
            sortByField='name'
            sortDesc={false}
        />
    )
  )
}
