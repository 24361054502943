import {useColorModeValue} from "@chakra-ui/react";

export const gradientBackgroundDark = 'linear-gradient(-45deg, #1C4532, #065666, #322659, #2D3748)'

// useColorModeValue "cannot be called at the top level" so we need to wrap it in a function
export const useColorSchemes = () => {
  const helmChartColorScheme = useColorModeValue('green', 'whatsapp');
  const fdColorScheme = useColorModeValue('blue', 'cyan');

  return { helmChartColorScheme, fdColorScheme };
}
