import { createApi } from '@reduxjs/toolkit/query/react'
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from './retry'

export const dbAccessProviderApi = createApi({
  reducerPath: 'dbAccessProviderApi',
  baseQuery: baseQueryWithUnAuthRetry(config.dbAccessProviderApiUrl),
  tagTypes: ['DBAccessRequest', 'DBRole', 'DBMetadata'],
  endpoints: (builder) => ({
    // roles
    getRoles: builder.query({
      query: () => '/roles',
      providesTags: (result = [], error, arg) => ['DBRole']
    }),

    // access requests
    submitRequest: builder.mutation({
      query: ({ ...body }) => ({
        url: '/requests',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['DBAccessRequest']
    }),
    getAllRequests: builder.query({
      query: () => '/requests',
      providesTags: (result = [], error, arg) => [
        'DBAccessRequest',
        ...result.map(({ id }) => ({ type: 'DBAccessRequest', id }))
      ]
    }),
    getRequestById: builder.query({
      query: (id) => `/requests/${id}`,
      providesTags: (result, error, arg) => [{ type: 'DBAccessRequest', id: arg }]
    }),
    getRequestByApprovalId: builder.query({
      query: (id) => `/requests/by-approval/${id}`,
      providesTags: (result, error, arg) => [{ type: 'DBAccessRequest', id: result?.id || arg }]
    }),
    getCredentials: builder.mutation({
      query: (id) => `/requests/${id}/credentials`
    }),

    // db metadata
    getMetadataById: builder.query({
      query: (id) => `/databases/${id}`,
      providesTags: (result, error, arg) => [{ type: 'DBMetadata', id: arg }]
    }),
    updateMetadataById: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/databases/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result, error, arg) => [
        'DBMetadata',
        { type: 'DBMetadata', id: arg.id }
      ]
    }),
    deleteMetadataById: builder.mutation({
      query: (id) => ({
        url: `/databases/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['DBMetadata']
    })
  })
})

export const {
  useGetRolesQuery,

  useSubmitRequestMutation,
  useGetAllRequestsQuery,
  useGetRequestByIdQuery,
  useGetRequestByApprovalIdQuery,
  useGetCredentialsMutation,

  useGetMetadataByIdQuery,
  useUpdateMetadataByIdMutation,
  useDeleteMetadataByIdMutation
} = dbAccessProviderApi
