import { DeleteIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Code,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Stack,
    Switch,
    Text,
    HStack,
    IconButton,
} from '@chakra-ui/react'
import { Formik, Form, Field, FieldArray } from 'formik'

export const HostnameCard = ({ innerRef, ...props }) => {
  return (
    <Formik initialValues={{ "routing.enableDefaultHosts": true, "routing.hosts": [] }} innerRef={innerRef}>
        {({ values }) => (
        <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
            <Stack
                spacing="5"
                px={{ base: "4", md: "6" }}
                py={{ base: "5", md: "6" }}
            >
                <Field name="['routing.enableDefaultHosts']">
                    {({ field }) => (
                        <FormControl>
                            <HStack spacing="3">
                                <Switch {...field} isChecked={field.value} />
                                <FormLabel>Default Host</FormLabel>
                            </HStack>
                            <FormHelperText color="fg.subtle">Every cluster has a default hostname (e.g. <Code>figure-test</Code> is <Code>test.figure.com</Code>). Enable this to use this hostname.</FormHelperText>
                        </FormControl>
                    )}
                </Field>

                <Text>Additional Hosts</Text>
                <Text fontSize="sm" color="fg.subtle">In addition to the default host, you can define more hosts for this route.</Text>
                <FieldArray name="['routing.hosts']">
                    {({ remove, push }) => (
                        <>
                            {values['routing.hosts'].map((host, index) => (
                                <Field key={`routing.hosts.${index}`} name={`['routing.hosts'].${index}`}>
                                    {({ field }) => (
                                        <FormControl>
                                            <HStack spacing="2">
                                                <Input {...field} type="text" />
                                                <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={() => remove(index)} />
                                            </HStack>
                                        </FormControl>
                                    )}
                                </Field>
                            ))}
                            <Button onClick={() => push('')}>Add a host</Button>
                        </>
                    )}
                </FieldArray>
            </Stack>
        </Box>
        )}
    </Formik>
  )
}
