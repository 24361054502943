import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Formik, Form, Field, FieldArray } from 'formik'

export const KafkaCard = ({ innerRef, ...props }) => {
  return (
    <Formik initialValues={{ "kafka.topics": [], "kafka.consumers": [] }} innerRef={innerRef}>
      {({ values }) => (
        <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Text>Topics</Text>
            <Text fontSize="sm" color="fg.subtle">List the topic names that your application produces to.</Text>
            <FieldArray name="['kafka.topics']">
              {({ remove, push }) => (
                <>
                  {values['kafka.topics'].map((topic, index) => (
                    <Field key={`kafka.topics.${index}`} name={`['kafka.topics'].${index}.topicName`}>
                      {({ field }) => (
                        <FormControl>
                          <HStack spacing="2">
                            <Input {...field} type="text" />
                            <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={() => remove(index)} />
                          </HStack>
                        </FormControl>
                      )}
                    </Field>
                  ))}
                  <Button onClick={() => push({topicName: ''})}>Add a topic</Button>
                </>
              )}
            </FieldArray>

            <Text>Consumers</Text>
            <Text fontSize="sm" color="fg.subtle">List your consumer IDs. You'll specify which topics they consume from later.</Text>
            <FieldArray name="['kafka.consumers']">
              {({ remove, push }) => (
                <>
                  {values['kafka.consumers'].map((consumer, index) => (
                    <Field key={`kafka.consumers.${index}`} name={`['kafka.consumers'].${index}.consumerGroupId`}>
                      {({ field }) => (
                        <FormControl>
                          <HStack spacing="2">
                            <Input {...field} type="text" />
                            <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={() => remove(index)} />
                          </HStack>
                        </FormControl>
                      )}
                    </Field>
                  ))}
                  <Button onClick={() => push({consumerGroupId: ''})}>Add a consumer</Button>
                </>
              )}
            </FieldArray>
          </Stack>
        </Box>
      )}
    </Formik>
  )
}
