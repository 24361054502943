import { useMemo } from 'react'
import {
    Box,
    HStack,
    Icon,
    Text,
    Tooltip,
} from '@chakra-ui/react'
import { FiAlertTriangle, FiChevronRight } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { useGetInstancesQuery } from '../../apis/database-builder'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'

export const Instances = () => {
  const { data: instances = [], isLoading: isLoadingInstances } = useGetInstancesQuery()

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Project',
            accessor: 'project',
        },
        {
            Header: 'Region',
            accessor: 'region',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => {
                return <HStack>
                    <Icon {...iconPropsFromStatus(row.original.status)} />
                    <Text>{row.original.status}</Text>
                    {row.original.error && (
                    <Tooltip label='An error occurred processing this request. Click for more details'>
                        <Box>
                            <FiAlertTriangle color='yellow' />
                        </Box>
                    </Tooltip>
                    )}
                </HStack>
            },
            id: 'status',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [])

  return (
    isLoadingInstances ? (
        <LoadingSpinner />
    ) : (
        <DataTable
            data={instances}
            columns={columns}
            title='Instances'
            sortByField='name'
            sortDesc={false}
        />
    )
  )
}
