import { useMemo } from 'react'
import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { FaGithub, FaSlack } from 'react-icons/fa'
import { useGetTeamsQuery, useGetUserByIdQuery } from '../../apis/org-chart'
import { Link, useParams } from 'react-router-dom'
import { DataTable } from '../../components/DataTable'

export const UserDetails = () => {
    const { id } = useParams()

    const { data: user = {}, isLoading: isLoadingUser } = useGetUserByIdQuery(id)
    const { data: teams = [], isLoading: isLoadingTeams } = useGetTeamsQuery()

    const columns = useMemo(
      () => [
          {
              Header: 'Name',
              accessor: 'name',
          },
          {
              Header: '',
              Cell: () => <Icon as={FiChevronRight} />,
              id: 'details_link',
          },
      ],
    [])

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Users</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>


      {isLoadingUser || isLoadingTeams ? (
        <Spinner />
      ) : (
        <Box>
          <Center py="12">
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              spacing={{
                base: '4',
                md: '10',
              }}
            >
              <Avatar size="2xl" name={user.first_name + ' ' + user.last_name} src={user.slack_avatar_url} />
              <Box width="full">
                <Flex justifyContent="space-between" alignItems="center">
                  <Heading size="md" fontWeight="extrabold" letterSpacing="tight" marginEnd="6">
                    {user.first_name + ' ' + user.last_name}
                  </Heading>
                </Flex>
                <Text mt="1" fontWeight="medium">
                  {user.email}
                </Text>
                <Stack spacing="1" mt="2">
                  <HStack fontSize="sm">
                    <Icon as={FaGithub} color="gray.500" />
                    {user.github_username === "" ? (
                      <Text>unknown</Text>
                    ) : (
                      <Button as={Link} variant='text' color='gray.500' to={`https://github.com/${user.github_username}`} target='_blank' rel='noopener noreferrer' label="Open user in GitHub">{user.github_username || 'unknown'}</Button>
                    )}
                  </HStack>
                  <HStack fontSize="sm">
                    <Icon as={FaSlack} color="gray.500" />
                    <Text>{user.slack_username || 'unknown'}</Text>
                  </HStack>
                </Stack>
              </Box>
            </Stack>
          </Center>

          <Box mx={{ base: '-4', md: '-6' }}>
            <DataTable
                title="Member of Teams"
                data={teams.filter((t) => t.members.filter((m) => m.user.id === user.id).length > 0)}
                columns={columns}
                sortByField='type'
                sortDesc={false}
                enableSearch={false}
                enableRowNavigation={true}
                navigationUrlPrefix='/teams/details/'
              />
          </Box>
        </Box>
      )}
    </Box>
  )
}
