import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Radio,
    RadioGroup,
    Spinner,
    Stack,
    Switch,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { Select as ReactSelect } from 'chakra-react-select'

export const DatabaseCard = ({ innerRef, databases, isLoadingDatabases, instances, isLoadingInstances, ...props }) => {
  return (
    <Formik initialValues={{ "database.type": "proxy", "database.name": "", "database.schema": "", "database.user": "", "database.migrations.enabled": false, "databaseId": "" }} innerRef={innerRef}>
        {({ values }) => (
            <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
                <Stack
                    spacing="5"
                    px={{ base: "4", md: "6" }}
                    py={{ base: "5", md: "6" }}
                >
                    <Field name="['database.type']">
                        {({ field }) => (
                            <FormControl>
                                <FormLabel>Type</FormLabel>
                                <RadioGroup {...field}>
                                    <Stack spacing="5" direction="row">
                                        <Radio {...field} value="proxy">
                                            Proxy
                                        </Radio>
                                        <Radio {...field} value="connector">
                                            Connector
                                        </Radio>
                                    </Stack>
                                </RadioGroup>
                                <FormHelperText color="fg.subtle">You can choose "Proxy" or "Connector". 
                                Proxy creates a DB proxy pod that manages the connection for you. Connector requires
                                you to make a direct connection from your app to the Cloud SQL instance using one of Google's connector
                                libraries. This requires code changes and migration container changes, so please refer to the Chart
                                documentation for more information.</FormHelperText>
                            </FormControl>
                        )}
                    </Field>
                    {(
                        <Field name="databaseId">
                            {({ field, form }) => (
                                <FormControl>
                                    <FormLabel>Cloud SQL Database</FormLabel>
                                    {isLoadingDatabases || isLoadingInstances ? (
                                        <Spinner />
                                    ) : (
                                        <ReactSelect
                                            onChange={(option) => {
                                                form.setFieldValue(field.name, option.value)
                                            }}
                                            options={databases}
                                            isClearable={false}
                                        />
                                    )}
                                    <FormHelperText color="fg.subtle">You must choose which Cloud SQL database your app will connect to. The instance name and project are in parenthesis.</FormHelperText>
                                </FormControl>
                            )}
                        </Field>
                    )}

                    <Field name="['database.migrations.enabled']">
                        {({ field }) => (
                            <FormControl>
                                <HStack spacing="3">
                                    <Switch {...field} />
                                    <FormLabel>Migrations</FormLabel>
                                </HStack>
                                <FormHelperText color="fg.subtle">Enable migrations if they run from an init container. Disable migrations if they run as part of your normal app startup.</FormHelperText>
                            </FormControl>
                        )}
                    </Field>
                </Stack>
            </Box>
        )}
    </Formik>
  )
}
