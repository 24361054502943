import { createApi } from '@reduxjs/toolkit/query/react'
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from './retry'

export const vaultAccessProviderApi = createApi({
  reducerPath: 'vaultAccessProviderApi',
  baseQuery: baseQueryWithUnAuthRetry(config.vaultAccessProviderApiUrl),
  tagTypes: ['Namespace', 'VaultAccessRequest'],
  endpoints: (builder) => ({
    // namespace
    getNamespaces: builder.query({
      query: () => ({
        url: '/namespaces',
        method: 'GET'
      }),
      providesTags: (result = [], error, arg) => [
        'Namespace',
        ...result.map(({ id }) => ({ type: 'Namespace', id }))
      ]
    }),
    getNamespaceById: builder.query({
      query: (id) => ({
        url: `/namespaces/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => [{ type: 'Namespace', id: arg }]
    }),
    getNamespacesByTeam: builder.query({
      query: (teamId) => ({
        url: `/namespaces/by-team/${teamId}`,
        method: 'GET'
      }),
      providesTags: (result = [], error, arg) => [
        'Namespace',
        ...result.map(({ id }) => ({ type: 'Namespace', id }))
      ]
    }),
    createNamespace: builder.mutation({
      query: ({ ...body }) => ({
        url: '/namespaces',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Namespace']
    }),
    updateNamespace: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/namespaces/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['Namespace']
    }),

    // requests
    getRequests: builder.query({
      query: () => ({
        url: '/requests',
        method: 'GET'
      }),
      providesTags: (result = [], error, arg) => [
        'VaultAccessRequest',
        ...result.map(({ id }) => ({ type: 'VaultAccessRequest', id }))
      ]
    }),
    submitRequest: builder.mutation({
      query: ({ ...body }) => ({
        url: '/requests',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['VaultAccessRequest']
    }),
    getRequestByApprovalId: builder.query({
      query: (id) => `/requests/by-approval/${id}`,
      providesTags: (result, error, arg) => [{ type: 'VaultAccessRequest', id: result?.id || arg }]
    }),
    getRequestsForTeam: builder.query({
      query: (id) => ({
        url: `/requests/by-team/${id}`,
        method: 'GET'
      }),
      providesTags: (result = [], error, arg) => [
        'VaultAccessRequest',
        ...result.map(({ id }) => ({ type: 'VaultAccessRequest', id }))
      ]
    }),
  })
})

export const {
  useGetNamespacesQuery,
  useGetNamespaceByIdQuery,
  useGetNamespacesByTeamQuery,
  useCreateNamespaceMutation,
  useUpdateNamespaceMutation,

  useGetRequestsQuery,
  useSubmitRequestMutation,
  useGetRequestByApprovalIdQuery,
  useGetRequestsForTeamQuery,
} = vaultAccessProviderApi
