import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const ResourcesCard = ({ innerRef, ...props }) => {
  return (
    <Formik initialValues={{ "deployment.resources.requests.cpu": "0.5", "deployment.resources.requests.memory": "1024Mi", "deployment.resources.limits.cpu": "3", "deployment.resources.limits.memory": "1536Mi" }} innerRef={innerRef}>
      <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
        <Stack
          spacing="5"
          px={{ base: "4", md: "6" }}
          py={{ base: "5", md: "6" }}
        >
            <Stack
                spacing="6"
                direction={{ base: 'column', md: 'row' }}
            >
              <Field name="['deployment.resources.requests.cpu']">
                {({ field }) => (
                  <FormControl>
                      <FormLabel>CPU Request</FormLabel>
                      <Input {...field} type="text" />
                  </FormControl>
                )}
              </Field>

              <Field name="['deployment.resources.requests.memory']">
                {({ field }) => (
                  <FormControl>
                      <FormLabel>Memory Request</FormLabel>
                      <Input {...field} type="text" />
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack
                spacing="6"
                direction={{ base: 'column', md: 'row' }}
            >
              <Field name="['deployment.resources.limits.cpu']">
                {({ field }) => (
                  <FormControl>
                      <FormLabel>CPU Limit</FormLabel>
                      <Input {...field} type="text" />
                  </FormControl>
                )}
              </Field>

              <Field name="['deployment.resources.limits.memory']">
                {({ field }) => (
                  <FormControl>
                      <FormLabel>Memory Limit</FormLabel>
                      <Input {...field} type="text" />
                  </FormControl>
                )}
              </Field>
            </Stack>
        </Stack>
      </Box>
    </Formik>
  )
}
