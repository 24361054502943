import { useRef } from 'react'
import {
    Box,
    Button,
    Container,
    Flex,
    Spacer,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import { RoutingCard } from './RoutingCard'
import { LinkerdCard } from './LinkerdCard'
import { HostnameCard } from './HostnameCard'

export const Routing = ({ setYamlField, goToNextStep, goToPrevStep }) => {
  const hostnameRef = useRef()
  const routingRef = useRef()
  const linkerdRef = useRef()

  const getJWTPlugin = () => {
    return {
      "type": "jwt",
      "config": {
        "claims_to_include": ["uuid", "roles", "email", "groups", "org", "memberships", "puid", "addr", "grp"],
        "claims_to_verify": ["exp"],
        "cookie_name": "test-jwt",
        "fail_on_no_cookie": false,
        "include_claims_downstream": true,
        "oauth_login_redirect_url": "https://test.figure.com/identity/api/v1/oauth",
        "required_groups": ["team@figure.com"],
        "required_roles": "{}",
      }
    }
  }

  const getIPRestrictionPlugin = () => {
    return {
      "type": "ip-restriction",
      "config": {
        "allow": ["FIGURE"],
      },
    }
  }

  const pluginGenerators = {
    "jwt": getJWTPlugin,
    "ip-restriction": getIPRestrictionPlugin,
  }

  const changePage = (change) => {
    for (const field in hostnameRef.current.values) {
      // a toggle to change a double negative seemed pretty confusing. if you turn
      // a switch on, its common that you are turning something on, not disabling it.
      // so the form has "enable", but the helm chart wants "disable".
      if (field === "routing.enableDefaultHosts") {
        setYamlField("routing.disableDefaultHosts", !hostnameRef.current.values[field])
      } else {
        setYamlField(field, hostnameRef.current.values[field])
      }
    }

    setYamlField("routing.routes", [
      {
        "name": routingRef.current.values["name"],
        "external": {
          "path": routingRef.current.values["externalPath"],
          "protocols": "https",
        },
        "internal": {
          "path": routingRef.current.values["internalPath"],
          "protocols": "http",
        },
        "plugins": routingRef.current.values["plugins"].map((pluginName) => pluginGenerators[pluginName]()),
      },
    ])

    for (const field in linkerdRef.current.values) {
      setYamlField(field, linkerdRef.current.values[field])
    }
    change()
  }

  return (
    <Container
        py={{ base: '4', md: '8' }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Hostnames
              </Text>
              <Text color="fg.muted" fontSize="sm">
                This is where you list the hostnames your app uses. These apply to all routes.
              </Text>
            </Box>
            <HostnameCard
              innerRef={hostnameRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Routing
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Define how you access your app from the internet. You'll define only one route here, but you can add more later.
              </Text>
            </Box>
            <RoutingCard
              innerRef={routingRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0} w={{ base: "xs", lg: "sm" }}>
              <Text fontSize="lg" fontWeight="medium">
                Linkerd
              </Text>
              <Text color="fg.muted" fontSize="sm">
                Configure your service mesh integration
              </Text>
            </Box>
            <LinkerdCard
              innerRef={linkerdRef}
              maxW={{ lg: '3xl' }}
            />
          </Stack>

            <Flex>
                <Button variant="primary" onClick={() => changePage(goToPrevStep)}>
                    Previous
                </Button>
                <Spacer />
                <Button variant="primary" onClick={() => changePage(goToNextStep)}>
                    Next
                </Button>
            </Flex>
        </Stack>
      </Container>
    )
}
