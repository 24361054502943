import { useState } from 'react'
import { Input } from '@chakra-ui/react'
import { useAsyncDebounce } from 'react-table'

export const Search = ({ value, onValueChange, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState(value)
  const debouncedSearchTerm = useAsyncDebounce(search => {
    onValueChange(search)
  }, 500)

  return (
    <Input colorScheme='gray' value={searchTerm} onChange={(e) => {
      setSearchTerm(e.target.value)
      debouncedSearchTerm(e.target.value)
    }} {...rest} />
  )
}
