import {
    Box,
    Button,
    ButtonGroup,
    useColorModeValue,
    VStack,
    List,
    ListItem,
    ListIcon,
    Stack,
    Text,
} from '@chakra-ui/react'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useListAPIKeysQuery } from '../../apis/api-key-portal'
import { DataTable } from '../../components/DataTable'

export const ApiKeys = () => {
  const buttonColorScheme = useColorModeValue('blue', 'green')
  const { data: apiKeys, error, isLoading } = useListAPIKeysQuery()

  const columns = [
    {
      Header: 'Cluster',
      accessor: 'cluster',
    },
    {
      Header: 'Consumer',
      accessor: 'consumer',
    },
    {
      Header: 'Namespace',
      accessor: 'namespace',
    },
    {
      Header: 'API Key Name',
      accessor: 'name',
    },
  ]

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      
      {isLoading ? (
        <Text>Loading...</Text>
      ) : error ? (
        <Text color="red.500">Error loading API keys</Text>
      ) : (
        <DataTable
          data={Array.isArray(apiKeys) ? apiKeys.map(apiKey => ({
              ...apiKey,
              navigationId: `${apiKey.cluster}_${apiKey.namespace}_${apiKey.consumer}`
          })) : []}
          columns={columns}
          title='API Keys'
          description={
            <Stack spacing="5">
              <Text fontSize="lg" fontWeight="medium">When do I need an API Key?</Text>
              <List mt={5} spacing={2}>
                <ListItem><ListIcon as={FiCheckCircle} color='green.500' />Third party access to Figure API's.</ListItem>
                <ListItem><ListIcon as={FiXCircle} color='red.500' />Service to service communication within the same cluster.</ListItem>
                <ListItem><ListIcon as={FiXCircle} color='red.500' />Service to service communication across different clusters.</ListItem>
              </List>
              <VStack alignItems='flex-start' mb={5}>
                <Text>
                  To create a new key for an existing consumer, please visit the 
                  <Link to="../consumers" style={{ textDecoration: 'none' }}>
                    <Button variant="link" color="blue.500" size="lg" ml={1}>Consumer page</Button>
                  </Link>
                </Text>
                <ButtonGroup spacing={4} mb={5} colorScheme={buttonColorScheme}>
                  <Link to={`../consumers/new`}><Button>New Consumer</Button></Link>
                </ButtonGroup>
              </VStack>
            </Stack>
          }
          sortByField='consumer'
          sortDesc={false}
          navigationIdField='navigationId'
          navigationUrlPrefix='../consumers/details/'
        />
      )}
    </Box>
  )
}
