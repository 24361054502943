import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

export const baseQueryWithUnAuthRetry = (url) => (
    retry(
        async (args, api, extraOptions) => {
            const result = await fetchBaseQuery({
                baseUrl: url,
                prepareHeaders: (headers, { getState }) => {
                    const token = getState()?.auth?.token
                    if (token) {
                        headers.set('authorization', `Bearer ${token}`)
                    }
                    return headers
                }
            })(args, api, extraOptions)

            // by default, rtk query will retry any request that failed. however,
            // we only want to perform that retry if it failed because it got a
            // 401 response (because okta can be slow at token refreshes). so if
            // theres a non-401 error, bail out of retries immediately. otherwise
            // let the retry function handle it.
            if (result.error && result.error?.status !== 401) {
                retry.fail(result.error)
            }

            return result
        },
        {
            maxRetries: 3,
        }
    )
)
