import { useState } from 'react'
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Select,
    Stack,
    Switch,
    Text,
    useToast,
} from '@chakra-ui/react'
import { Dropzone } from './Dropzone'
import { Manifest } from '../builder/Manifest'
import { useConvertDeploymentMutation } from '../../../apis/tops-service'
import { parseDocument, stringify } from 'yaml'

export const AppBuilderConvert = () => {
    const toast = useToast()
    const [convertDeployment] = useConvertDeploymentMutation()

    const [selectedFile, setSelectedFile] = useState()
    const [testData, setTestData] = useState({})
    const [sandboxData, setSandboxData] = useState({})
    const [prodData, setProdData] = useState({})
    const [showManifest, setShowManifest] = useState(false)

    const [project, setProject] = useState("figure")
    const [usesRedis, setUsesRedis] = useState(true)

    const readFileFinished = async (e) => {
        const resp = await convertDeployment({ yaml: e.target.result, project: project, usesRedis: usesRedis })
        if (resp.error) {
          toast({
            title: resp.error.data?.title || 'Error',
            description: resp.error.data?.description || `Unknown error - status ${resp.error.status}`,
            status: 'error',
            duration: 7000,
            isClosable: true
          })
        } else {
          setTestData(resp.data.test)
          setSandboxData(resp.data.sandbox)
          setProdData(resp.data.prod)
          setShowManifest(true)
        }
    }

    const convert = async () => {
        // file reading is asynchronous, so tell it to read the file,
        // but call readFileFinished when its done.
        let fileReader = new FileReader()
        fileReader.onloadend = readFileFinished
        fileReader.readAsText(selectedFile)
    }

    const getYamlField = (path) => {
        const valuesYamlDoc = parseDocument(testData.yaml)
        return stringify(valuesYamlDoc.getIn(path.split('.')))
    }

    const stringifyYaml = (yamlString) => {
        if (yamlString.length === 0) {
            return ""
        }
        // the backend does some weird formatting on the yaml (like using 4 spaces)
        // parsing the string as yaml, and then converting it back to a string here,
        // will help guarantee consistent results when displaying these files.
        return stringify(parseDocument(yamlString))
    }

    const shouldShowEnv = (envData) => {
        return envData.yaml.length > 0 || envData.errors?.length > 0 || envData.warnings?.length > 0
    }

    const getData = () => {
        const data = {}
        if (shouldShowEnv(testData)) {
            data["test"] = { ...testData, yaml: stringifyYaml(testData.yaml) }
        }
        if (shouldShowEnv(sandboxData)) {
            data["sandbox"] = { ...sandboxData, yaml: stringifyYaml(sandboxData.yaml) }
        }
        if (shouldShowEnv(prodData)) {
            data["prod"] = { ...prodData, yaml: stringifyYaml(prodData.yaml) }
        }
        return data
    }

    return (
        <Box
            px={{ base: '4', md: '6' }}
            py="5"
            bg="bg.surface"
        >
            <Container
                py={{ base: '4', md: '8' }}
            >
                {showManifest ? (
                    <Manifest
                        name={getYamlField("name")}
                        data={getData()}
                        isConversion={true}
                    />
                ) : (
                    <Stack spacing="5">
                        <Text fontSize="2xl" fontWeight="medium">
                            Convert a deployment.yaml
                        </Text>

                        <FormControl>
                            <FormLabel>Which project does this app get deployed to?</FormLabel>
                            <Select defaultValue={project} onChange={(e) => setProject(e.target.value)}>
                                <option value="figure">Figure</option>
                                <option value="figure-tech">Figure Tech</option>
                                <option value="figure-pay">Figure Pay</option>
                                <option value="figure-dataeng">Data Eng</option>
                            </Select>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Does your app use redis?</FormLabel>
                            <Switch defaultChecked={usesRedis} onChange={(e) => setUsesRedis(e.target.checked)} />
                        </FormControl>

                        <FormControl id="file">
                            <FormLabel>Upload your file, then click convert</FormLabel>
                            <Dropzone selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
                        </FormControl>

                        <Button colorScheme="blue" onClick={() => convert()}>Convert</Button>
                    </Stack>
                )}
            </Container>
        </Box>
    )
}
