import { useMemo } from 'react'
import {
    Box,
    HStack,
    Icon,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react'
import { FiAlertTriangle, FiChevronRight } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { useGetRequestsQuery } from '../../apis/approval-engine'
import { useGetUsersQuery } from '../../apis/org-chart'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'

export const Approvals = () => {
  const { data: requests = [], isLoading: isLoadingRequests } = useGetRequestsQuery({})
  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersQuery()

  const columns = useMemo(
    () => [
        {
            Header: 'Requestor',
            accessor: (row, _) => users.find(u => u.id === row.user_id)?.email || row.user_id,
            id: 'requestor',
        },
        {
            Header: 'Requested Date',
            accessor: 'requested_date',
            Cell: ({ row }) => (
                <Text>{new Date(row.original.requested_date * 1000).toDateString()}</Text>
            ),
            id: 'requested_date',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Business Justification',
            accessor: 'business_justification',
            Cell: ({ row }) => (
                <Tooltip label={row.original.business_justification}>
                    <Text>{row.original.business_justification.slice(0, 40)}...</Text>
                </Tooltip>
            ),
            id: 'business_justification',
        },
        {
            Header: 'Approvals',
            accessor: (row, _) => row.required_approvals[0],
            Cell: ({ row }) => (
                <VStack>
                    {Object.keys(row.original.required_approvals).map((groupName) => (
                        <Text key={groupName} as='i'>{groupName}</Text>
                    ))}
                </VStack>
            ),
            id: 'approvals',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => {
                return <HStack>
                    <Icon {...iconPropsFromStatus(row.original.status)} />
                    <Text>{row.original.status}</Text>
                    {row.original.error && (
                    <Tooltip label='An error occurred processing this request. Click for more details'>
                        <Box>
                            <FiAlertTriangle color='yellow' />
                        </Box>
                    </Tooltip>
                    )}
                </HStack>
            },
            id: 'status',
        },
        {
            Header: '',
            Cell: () => <Icon as={FiChevronRight} />,
            id: 'details_link',
        }
    ],
  [users])

  return (
    isLoadingUsers || isLoadingRequests ? (
      <LoadingSpinner />
    ) : (
      <DataTable
        data={requests}
        columns={columns}
        title='Approvals'
        sortByField='requested_date'
        sortDesc={true}
      />
    )
  )
}
