import { isRejectedWithValue } from '@reduxjs/toolkit'
import { createStandaloneToast } from '@chakra-ui/toast'
const { toast } = createStandaloneToast()

export const errorHandler = (api) => (next) => (action) => {
    // only show a generic error toast for queries. the other option ("mutation")
    // is a purposeful API call made by each component, so its up to them to do
    // error handling
    if (isRejectedWithValue(action) && action?.meta?.arg?.type === "query") {
        console.log(action.payload)
        // TODO check for other types of fields aside from data.error
        toast({
            title: 'Failed to fetch data',
            description: action?.payload?.data?.error || 'Unknown error',
            status: 'error',
            duration: 7000,
            isClosable: true,
        })
    }

    return next(action)
}
