import {
    Avatar,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Flex,
    HStack,
    Icon,
    IconButton,
    List,
    ListItem,
    SimpleGrid,
    Spacer,
    Text,
    Tooltip,
    useColorModeValue,
    useDisclosure,
    VStack,
    useToast,
    Tag,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useGetAppsQuery, useGetTeamByIdQuery, useGetTeamsQuery, useUpdateTeamMemberRoleMutation, useUpdateTeamMutation } from '../../apis/org-chart'
import { useGetNamespacesByTeamQuery } from '../../apis/vault-access-provider'
import { FiChevronRight } from 'react-icons/fi'
import { FaGithub, FaPencilAlt, FaSlack } from 'react-icons/fa'
import { DataTable } from '../../components/DataTable'
import { useMemo, useState } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { useOktaAuth } from '@okta/okta-react'
import { TeamRoleModal } from '../../components/TeamRoleModal'
import { TeamSlackChannelsModal } from '../../components/TeamSlackChannelsModal'

export const TeamDetails = () => {
  const { id } = useParams()
  const toast = useToast()

  const { data: team = {}, isLoading: isLoadingTeam } = useGetTeamByIdQuery(id)
  const { data: namespaces = [], isLoading: isLoadingNamespaces } = useGetNamespacesByTeamQuery(id)
  const { data: apps = [], isLoading: isLoadingApps } = useGetAppsQuery()
  const { data: allTeams = [], isLoading: isLoadingAllTeams } = useGetTeamsQuery()
  const [updateTeamMemberRole] = useUpdateTeamMemberRoleMutation()
  const [updateTeam] = useUpdateTeamMutation()

  const highlightColor = useColorModeValue('#23d5ab', '#973ce7')

  const { authState } = useOktaAuth()
  const isAdmin = authState?.accessToken?.claims.admin === true
  const isTeamLead = team?.members?.find((tm) => tm.user.email === authState?.accessToken?.claims.sub)?.role === 'LEAD'
  const { isOpen: roleModalIsOpen, onOpen: onOpenRoleModal, onClose: onCloseRoleModal } = useDisclosure()
  const [modifyingTeamMember, setModifyingTeamMember] = useState({})

  const { isOpen: slackModalIsOpen, onOpen: onOpenSlackModal, onClose: onCloseSlackModal } = useDisclosure()

  const changeRole = async (values, actions) => {
    const resp = await updateTeamMemberRole({id: team.id, userId: values.user_id, role: values.role})
    actions.setSubmitting(false)

    if (resp.error && resp.error.data) {
      toast({
        title: `Team update failed`,
        description: resp.error.data || resp.error.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      onCloseRoleModal()
      toast({
        title: `Team updated successfully`,
        status: 'success',
        duration: 7000,
        isClosable: true
      })
    }
  }

  const updateTeamSlackChannels = async (values, actions) => {
    const resp = await updateTeam({id: team.id, ...values})
    actions.setSubmitting(false)

    if (resp.error && resp.error.data) {
      toast({
        title: `Team update failed`,
        description: resp.error.data || resp.error.error,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      onCloseSlackModal()
      toast({
        title: `Team updated successfully`,
        status: 'success',
        duration: 7000,
        isClosable: true
      })
    }
  }

  const sortedMembers = useMemo(() => {
    if (!team.members) {
      return []
    }
    const members = team.members.slice()
    members.sort((a, b) => {
      // always put the team leads first
      if (a.role === 'LEAD') {
        return -1
      } else if (b.role === 'LEAD') {
        return 1
      }
      if (a.user.first_name.toLowerCase() > b.user.first_name.toLowerCase()) {
        return 1
      } else if (a.user.first_name.toLowerCase() < b.user.first_name.toLowerCase()) {
        return -1
      } else {
        return 0
      }
    })
    return members
  }, [team])

  const columns = useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Owned By',
            accessor: (row, _) => allTeams.filter((team) => row.owned_by.includes(team.id))?.map((m) => m.name).join(', '),
            id: 'owned_by',
        },
    ],
  [allTeams])

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb
        spacing='8px'
        separator={<FiChevronRight color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'>
            <Button variant='text'>Teams</Button>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{isLoadingTeam ? id : team.slug}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Avatar mt={5} size='2xl' name={team?.name} src={team?.avatar_url} />

      <Flex mt={5} justifyContent='space-between'>
        <Text fontSize="2xl" fontWeight="medium">
          {isLoadingTeam ? 'Details' : team.name}
        </Text>
        <Button as={Link} to={team?.url} target='_blank' rel='noopener noreferrer' label="Open team in GitHub" leftIcon={<FaGithub />}>View in GitHub</Button>
      </Flex>

      {team?.description && (
        <Text as='i'>{team.description}</Text>
      )}

      <Flex mt={5} direction='column'>
        <HStack>
          <FaSlack />
          <HStack><Text>Slack channels</Text>{(isAdmin || isTeamLead) && (<IconButton variant='tertiary' icon={<FaPencilAlt />} onClick={() => onOpenSlackModal()} /> )}</HStack>
        </HStack>
        <Text color='fg.muted' fontSize='sm'>These Slack channels are unique to this team. The general channel is where you can get in contact with this team.
            The alerts channel is where automated alerts are sent from tools like Datadog. The rollbar channel is for rollbar errors.
            These channels can be modified by team leads and will apply to all of the team's apps shortly after.</Text>
        <Flex mt={3} direction='row'>
          <SimpleGrid columns={2} gap='3'>
            <Text>General</Text>{team.slack_channel ? <Tag>#{team.slack_channel}</Tag> : <Text as='i'>not specified</Text>}
            <Text>Alerts</Text>{team.alerting_slack_channel ? <Tag>#{team.alerting_slack_channel}</Tag> : <Text as='i'>not specified</Text>}
            <Text>Rollbar</Text>{team.rollbar_slack_channel ? <Tag>#{team.rollbar_slack_channel}</Tag> : <Text as='i'>not specified</Text>}
          </SimpleGrid>
          <Spacer />
        </Flex>
      </Flex>

      <Box mt={5}>
        {isLoadingTeam ? (
          <LoadingSpinner />
        ) : (
          <>
              <Text fontWeight="medium">
                Members
              </Text>

              <SimpleGrid minChildWidth='250px' spacing='50px' mt={10}>
                  {sortedMembers.length > 0 ? (
                      sortedMembers.map((member) =>
                      <VStack key={member.user.id} spacing='10px'>
                          <Avatar size='xl' name={member.user.first_name + ' ' + member.user.last_name} src={member.user.slack_avatar_url} />
                          <Text>{member.user.first_name + ' ' + member.user.last_name}</Text>
                          <Text fontSize='xs'>{member.user.email}</Text>
                          {member.user.slack_username && (
                            <Text fontSize='xs'>{member.user.slack_username}</Text>
                          )}
                          <Box {...((isAdmin || isTeamLead) ? {as: Button, onClick: () => {setModifyingTeamMember(member); onOpenRoleModal()}} : {})} variant='ghost'>
                            <HStack color={member.role === 'LEAD' ? highlightColor : 'fg.subtle'}>
                              <Text fontSize='xs'>{member.role === 'LEAD' ? 'TEAM LEAD' : 'MEMBER'}</Text>
                              {(isAdmin || isTeamLead) && (<Icon as={FaPencilAlt} boxSize='3' />)}
                            </HStack>
                          </Box>
                      </VStack>
                      )
                  ) : (
                      <Text>This team has no members</Text>
                  )}
              </SimpleGrid>
          </>
        )}

        {isLoadingAllTeams || isLoadingNamespaces || isLoadingApps ? (
            <LoadingSpinner />
        ) : (
            <List mt={5} spacing={5}>
              <ListItem>
                  <HStack><Text fontWeight="medium">Resources</Text>
                  <Tooltip label='These are the various resources that this team owns. Any requests to access these resources will be sent to all team leads of the owning teams.'>
                    <QuestionOutlineIcon />
                  </Tooltip>
                  </HStack>
              </ListItem>
              <ListItem mx={{ base: '-4', md: '-6' }}>
                <DataTable
                  data={
                    namespaces.map((n) => ({name: n.name, type: 'Namespace', owned_by: n.owned_by})).concat(
                    apps.filter((a) => a.owning_team_id === id).map((a) => ({name: a.name, type: 'App', owned_by: [id]}))
                  )}
                  columns={columns}
                  sortByField='type'
                  sortDesc={false}
                  enableSearch={false}
                  enableRowNavigation={false}
                />
              </ListItem>
            </List>
        )}

      </Box>

      <TeamRoleModal teamMember={modifyingTeamMember} colorScheme='green' onSubmit={changeRole} isOpen={roleModalIsOpen} onClose={onCloseRoleModal} />
      <TeamSlackChannelsModal team={team} colorScheme='green' onSubmit={updateTeamSlackChannels} isOpen={slackModalIsOpen} onClose={onCloseSlackModal} />
    </Box>
  )
}
