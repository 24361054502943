import {
    Container,
    Icon,
    Square,
    Stack,
    Text,
    Heading,
    SimpleGrid,
    Code,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { IoCreateOutline, IoSwapHorizontal } from 'react-icons/io5'

export const AppBuilderIntro = () => {
  const features = [{
    name: 'New Applications',
    link: 'start',
    description: 'Start fresh by building a new application definition. You\'ll fill out a few forms and download your yaml file at the end.',
    icon: IoCreateOutline,
  },
  {
    name: 'Convert a deployment.yaml',
    link: 'convert',
    description: 'Have a FigureDeployment, but want to use the new deployment pipeline? Start here. You\'ll upload your existing yaml file and it will be converted automatically.',
    icon: IoSwapHorizontal,
  }]

  return (
    <Container
      py={{
        base: '16',
        md: '24',
      }}
    >
      <Stack
        spacing={{
          base: '12',
          md: '16',
        }}
      >
        <Stack
          spacing={{
            base: '4',
            md: '5',
          }}
          align="center"
          textAlign="center"
        >
          <Stack spacing="3">
            <Heading
              size={{
                base: 'sm',
                md: 'md',
              }}
            >
              App Builder
            </Heading>
          </Stack>
          <Text
            color="fg.muted"
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            maxW="3xl"
          >
            The App Builder is a quick start tool to help generate a new application definition.
            This will create a Helm Chart <Code>values.yaml</Code> file for use with the new
            ArgoCD GitOps pipeline. If you've got a <Code>deployment.yaml</Code>, you'll need to
            convert it first.
          </Text>
        </Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
          }}
          columnGap={8}
          rowGap={{
            base: 10,
            md: 16,
          }}
        >
          {features.map((feature) => (
            <Stack
              boxShadow="md"
              border="1px"
              borderColor="gray.100"
              borderRadius="md"
              padding="5"
              as={Link}
              to={feature.link}
              key={feature.name}
              spacing={{
                base: '4',
                md: '5',
              }}
              align="center"
              textAlign="center"
            >
              <Square
                size={{
                  base: '10',
                  md: '12',
                }}
                bg="accent"
                color="fg.inverted"
                borderRadius="lg"
              >
                <Icon
                  as={feature.icon}
                  boxSize={{
                    base: '5',
                    md: '6',
                  }}
                />
              </Square>
              <Stack
                spacing={{
                  base: '1',
                  md: '2',
                }}
              >
                <Text
                  fontSize={{
                    base: 'lg',
                    md: 'xl',
                  }}
                  fontWeight="medium"
                >
                  {feature.name}
                </Text>
                <Text color="fg.muted">{feature.description}</Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  )
}
