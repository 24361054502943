import { createSlice } from '@reduxjs/toolkit'

// this file was copied nearly verbatim from https://redux-toolkit.js.org/rtk-query/usage/examples#dispatching-an-action-to-set-the-user-state
const slice = createSlice({
  name: 'auth',
  initialState: { token: null },
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }
    ) => {
      state.token = token
    },
  },
})

export const { setCredentials } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state) => state.auth.user
