// this entire file should be deleted after the tops-service API

import { Text } from "@chakra-ui/react"

// stops sending date strings and sends integers instead.
export const poorMansParse = (dateTimeStr) => {
    // I spent quite a bit of time reading about people arguing over date time parsers in javascript.
    // Instead of doing any date parsing in javascript, the devops API should return milliseconds
    // since epoch and not strings for dates. Or javascript needs to be a lot less terrible, which
    // won't happen.
    const parts = dateTimeStr.split('T')
    const ymd = parts[0].split('-')
    const y = ymd[0]
    const mo = ymd[1] - 1 // 0 based months
    const d = ymd[2]
    const hms = parts[1].split(':')
    const h = hms[0]
    const m = hms[1]
    const s = hms[2].split('.')[0]
    return new Date(Date.UTC(y, mo, d, h, m, s))
}

export const poorMansFormat = (dateTimeStr) => {
    const dateTime = poorMansParse(dateTimeStr)
    const units = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: 24 * 60 * 60 * 1000 * 365 / 12,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000
    }

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

    const elapsed = dateTime - Date.now()

    for (const u in units) {
        if (Math.abs(elapsed) > units[u] || u === 'second') {
        return rtf.format(Math.round(elapsed / units[u]), u)
        }
    }
    return dateTime.toLocaleString()
}

export const Duration = ({now, startTime, endTime}) => {
    let timeSince = ''

    if (startTime !== 0) {
      let diff = endTime - startTime
      if (endTime === 0) {
        diff = now - startTime
      }
      let units = 'seconds'
      if (diff > 60) {
        units = 'minutes'
        diff = Math.floor(diff / 60)
      } else if (diff > 3600) {
        units = 'hours'
        diff = Math.floor(diff / 3600)
      }
      timeSince = diff + ' ' + units
    }

    return <Text>{timeSince}</Text>
}
