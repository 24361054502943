import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from "./retry";

export const operationalReadinessApi = createApi({
  reducerPath: "operationalReadinessApi",
  baseQuery: baseQueryWithUnAuthRetry(config.operationalReadinessUrl),
  endpoints: (builder) => ({
    GetResults: builder.query({
      query: () => "/",
    }),
  }),
});

export const { useGetResultsQuery } = operationalReadinessApi;
