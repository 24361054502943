import {
    Box,
    Button,
    ButtonGroup,
    useColorModeValue,
    VStack,
    Stack,
    Text,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useListConsumersQuery } from '../../apis/api-key-portal'
import { DataTable } from '../../components/DataTable'

export const Consumers = () => {
  const buttonColorScheme = useColorModeValue('blue', 'green')
  const { data: consumers, error, isLoading } = useListConsumersQuery()

  const columns = [
    {
      Header: 'Cluster',
      accessor: 'cluster',
    },
    {
      Header: 'Consumer',
      accessor: 'name',
    },
    {
      Header: 'Namespace',
      accessor: 'namespace',
    },
    {
      Header: 'ACLs',
      accessor: 'acls.length',
    },
    {
      Header: 'Keys',
      accessor: 'keys.length',
    },
  ]

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      
      {isLoading ? (
        <Text>Loading...</Text>
      ) : error ? (
        <Text color="red.500">Error loading API keys</Text>
      ) : (
        <DataTable
          data={Array.isArray(consumers) ? consumers : []}
          columns={columns}
          title='Kong Consumers'
          description={
            <Stack spacing="5">
              <VStack alignItems='flex-start' mb={5}>
                <Text>A consumer refers to an entity that consumes or uses the APIs managed by Kong Gateway. 
                    <br/>
                    Consumers can be applications, services, or users who interact with your APIs. 
                    Since they are not always human, Kong Gateway calls them consumers, because they “consume” the service. 
                    Kong Gateway allows you to define and manage consumers, apply access control policies, and monitor their API usage.
                </Text>
                
                <ButtonGroup spacing={4} mb={5} colorScheme={buttonColorScheme}>
                  <Link to={`new`}><Button>New Consumer</Button></Link>
                </ButtonGroup>
              </VStack>
            </Stack>
          }
          sortByField='cluster'
          sortDesc={false}
        />
      )}
    </Box>
  )
}
