import { Button, FormControl, FormLabel, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Text, VStack, FormErrorMessage } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const DecideWithCommentModal = ({ action, colorScheme, allowComment = true, commentRequired = false, onSubmit, isOpen, onClose }) => {
  const validateRequiredField = (value) => {
    let error
    if (commentRequired && (!value || value.length < 1)) {
      error = 'Required'
    }
    return error
  }

  const uppercaseFirst = (s) => {
    return s[0].toUpperCase() + s.slice(1)
  }

  return (
    <Modal size='xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{uppercaseFirst(action)} the Request</ModalHeader>
        <ModalCloseButton />
        <Formik initialValues={{ comment: '' }} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <VStack alignItems='flex-start' spacing='15px'>
                  {allowComment ? (
                    <>
                      {commentRequired ? (
                        <Text>A comment is required describing your decision.
                          This will be shown to the requestor.
                        </Text>
                      ) : (
                        <Text>You may optionally leave a comment describing your decision.
                          This will be shown to the requestor.
                        </Text>
                      )}

                      <Field name='comment' validate={validateRequiredField}>
                        {({ field, form }) => (
                          <FormControl isRequired={commentRequired} isInvalid={form.errors.comment && form.touched.comment}>
                            <FormLabel>Comment</FormLabel>
                            <Input {...field} type="text" />
                            <FormErrorMessage>{form.errors.comment}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </>
                  ) : (
                    <Text>Are you sure you want to {action} the request?</Text>
                  )}
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme={colorScheme} isLoading={isSubmitting} type="submit">
                  {uppercaseFirst(action)}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
