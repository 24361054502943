import { useMemo } from 'react'
import {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Spinner,
    useToast,
    useColorModeValue,
    Text,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { useGetRolesQuery, useSubmitRequestMutation } from '../../apis/db-access-provider'
import { useGetDatabasesQuery, useGetInstancesQuery } from '../../apis/database-builder'
import { Select } from 'chakra-react-select'

export const RequestDatabaseAccessForm = ({ callback }) => {
  const toast = useToast()

  const { data: roles = [], isLoading: isLoadingRoles } = useGetRolesQuery()
  const { data: databases = [], isLoading: isLoadingDatabases } = useGetDatabasesQuery()
  const { data: instances = [], isLoading: isLoadingInstances } = useGetInstancesQuery()

  const [submitAccessRequest] = useSubmitRequestMutation()

  const buttonColorScheme = useColorModeValue('blue', 'green')

  const instancesMap = useMemo(() => {
    const instancesMap = {}
    instances.forEach(inst => {
      instancesMap[inst.id] = inst
    })
    return instancesMap
  }, [instances])

  const sortedDatabases = useMemo(() => {
    const sortedDatabases = databases.slice()
    sortedDatabases.sort((a, b) => {
      // sort by database name first, instance name second
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      } else if (instancesMap[a.instance_id || '']?.name?.toLowerCase() > instancesMap[b.instance_id || '']?.name?.toLowerCase()) {
        return 1
      } else if (instancesMap[a.instance_id || '']?.name?.toLowerCase() < instancesMap[b.instance_id || '']?.name?.toLowerCase()) {
        return -1
      } else {
        return 0
      }
    })
    return sortedDatabases.map((d) => ({ value: d.id, label: `${d.name} (${instancesMap[d.instance_id || '']?.name || 'database is not ready'}/${d.project})` }))
  }, [databases, instancesMap])

  const onSubmit = async (values, actions) => {
    const resp = await submitAccessRequest(values)
    actions.setSubmitting(false)
    if (resp.error && resp.error.data) {
      toast({
        title: 'Failed to create the access request.',
        description: resp.error.data.error || resp.error.data,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Successfully created your access request.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
      callback()
    }
  }

  const validateRequiredField = (value) => {
    let error
    if (!value || value.length < 1) {
      error = 'Required'
    }
    return error
  }

  return (
    <Formik initialValues={{ database_id: '', role: '', business_justification: '' }} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
            <Form>

            <Field name='database_id' validate={validateRequiredField}>
                {({ field, form }) => (
                <FormControl isRequired mb={5} isInvalid={form.errors.database_id && form.touched.database_id}>
                    <FormLabel htmlFor='database_id'>Database</FormLabel>
                    {isLoadingDatabases || isLoadingInstances ? (
                        <Spinner />
                    ) : (
                    <Select
                        id='database_id'
                        onChange={(option) => {
                            form.setFieldValue(field.name, option.value)
                        }}
                        options={sortedDatabases}
                        isClearable={false}
                    />
                    )}
                    <FormHelperText>The database instance name and project are listed in parenthesis</FormHelperText>
                    <FormErrorMessage>{form.errors.database_id}</FormErrorMessage>
                </FormControl>
                )}
            </Field>

            <Field name='role' validate={validateRequiredField}>
                {({ field, form }) => (
                <FormControl isRequired mb={5} isInvalid={form.errors.role && form.touched.role}>
                    <FormLabel htmlFor='role'>Access level</FormLabel>
                    {isLoadingRoles ? (
                        <Spinner />
                    ) : (
                    <Select
                        id='role'
                        onChange={(option) => {
                            form.setFieldValue(field.name, option.value)
                        }}
                        options={roles.map((role) => ({ value: role, label: role }))}
                        isClearable={false}
                    />
                    )}
                    <FormHelperText><b>read</b> will provide read access to all existing data. <b>write</b> will provide the ability to insert data into existing tables. <b>admin</b> will grant the ability to make DDL and schema changes</FormHelperText>
                    <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                </FormControl>
                )}
            </Field>

            <Field name='business_justification' validate={validateRequiredField}>
                {({ field, form }) => (
                <FormControl isRequired mb={5} isInvalid={form.errors.business_justification && form.touched.business_justification}>
                    <FormLabel htmlFor='business_justification'>Reason for access</FormLabel>
                    <Input {...field} type='text' id='business_justification' />
                    <FormHelperText>Please provide a valid reason that access to this database is necessary. This will be sent to all request approvers.</FormHelperText>
                    <FormErrorMessage>{form.errors.business_justification}</FormErrorMessage>
                </FormControl>
                )}
            </Field>

            <Text my="5">This request will be sent to your manager for approval. If approved, this access will be valid for 12 months.</Text>

            <Button
                colorScheme={buttonColorScheme}
                isLoading={isSubmitting}
                type='submit'
            >
                Submit
            </Button>
            </Form>
        )}
    </Formik>
  )
}
