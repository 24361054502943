import { useMemo } from 'react'
import {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Spinner,
    useToast,
    useColorModeValue,
    Text,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { useGetNamespacesQuery, useSubmitRequestMutation } from '../../apis/vault-access-provider'
import { Select } from 'chakra-react-select'

export const RequestVaultAccessForm = ({ callback }) => {
  const toast = useToast()

  const { data: namespaces = [], isLoading: isLoadingNamespaces } = useGetNamespacesQuery()

  const [submitAccessRequest] = useSubmitRequestMutation()

  const buttonColorScheme = useColorModeValue('blue', 'green')

  const sortedNamespaces = useMemo(() => {
    const sortedNamespaces = namespaces.slice()
    sortedNamespaces.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      } else {
        return 0
      }
    })
    return sortedNamespaces.map((n) => ({ value: n.id, label: n.name }))
  }, [namespaces])

  const onSubmit = async (values, actions) => {
    const resp = await submitAccessRequest(values)
    actions.setSubmitting(false)
    if (resp.error && resp.error.data) {
      toast({
        title: 'Failed to create the access request.',
        description: resp.error.data.error || resp.error.data,
        status: 'error',
        duration: 7000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Successfully created your access request.',
        status: 'success',
        duration: 7000,
        isClosable: true
      })
      callback()
    }
  }

  const validateRequiredField = (value) => {
    let error
    if (!value || value.length < 1) {
      error = 'Required'
    }
    return error
  }

  return (
    <Formik initialValues={{ namespace_id: '', business_justification: '' }} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
            <Form>

            <Field name='namespace_id' validate={validateRequiredField}>
                {({ field, form }) => (
                <FormControl isRequired mb={5} isInvalid={form.errors.namespace_id && form.touched.namespace_id}>
                    <FormLabel htmlFor='namespace_id'>Namespace</FormLabel>
                    {isLoadingNamespaces ? (
                        <Spinner />
                    ) : (
                    <Select
                        id='namespace_id'
                        onChange={(option) => {
                            form.setFieldValue(field.name, option.value)
                        }}
                        options={sortedNamespaces}
                        isClearable={false}
                    />
                    )}
                    {/* <FormHelperText>The database instance name and project are listed in parenthesis</FormHelperText> */}
                    <FormErrorMessage>{form.errors.namespace_id}</FormErrorMessage>
                </FormControl>
                )}
            </Field>

            <Field name='business_justification' validate={validateRequiredField}>
                {({ field, form }) => (
                <FormControl isRequired mb={5} isInvalid={form.errors.business_justification && form.touched.business_justification}>
                    <FormLabel htmlFor='business_justification'>Reason for access</FormLabel>
                    <Input {...field} type='text' id='business_justification' />
                    <FormHelperText>Please provide a valid reason that access to this namespace is necessary. This will be sent to all request approvers.</FormHelperText>
                    <FormErrorMessage>{form.errors.business_justification}</FormErrorMessage>
                </FormControl>
                )}
            </Field>

            <Text my="5">This request will be sent to all team leads that own the requested namespace. If approved, this access will be valid for 1 year.</Text>

            <Button
                colorScheme={buttonColorScheme}
                isLoading={isSubmitting}
                type='submit'
            >
                Submit
            </Button>
            </Form>
        )}
    </Formik>
  )
}
