import { useState } from 'react'
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Stack,
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { Select } from 'chakra-react-select'
import { FiChevronRight } from 'react-icons/fi'
import { RequestDatabaseAccessForm } from './RequestDatabaseAccessForm'
import { RequestVaultAccessForm } from './RequestVaultAccessForm'

export const RequestNewAccess = () => {
  const navigate = useNavigate()
  const [system, setSystem] = useState('database')

  const callback = () => {
    navigate('..')
  }

  const systems = [
    {value: 'database', label: 'Databases'},
    {value: 'vault', label: 'Vault'},
  ]

  return (
    <Box
        px={{ base: '4', md: '6' }}
        py="5"
    >
      <Breadcrumb spacing='8px' separator={<FiChevronRight color='gray.500' />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='..'><Button variant='text'>My Access</Button></BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>New</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Stack mt="5" spacing="5">
        <Heading size="md">Request New Access</Heading>

        <FormControl>
          <FormLabel>System</FormLabel>
          <Select
              onChange={(option) => setSystem(option.value)}
              options={systems}
              defaultValue={systems[0]}
              isClearable={false}
          />
        </FormControl>

        {system === 'database' ? (
          <RequestDatabaseAccessForm callback={callback} />
        ) : system === 'vault' ? (
          <RequestVaultAccessForm callback={callback} />
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  )
}
