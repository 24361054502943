import { createApi } from '@reduxjs/toolkit/query/react'
import { config } from '../config'
import { baseQueryWithUnAuthRetry } from './retry'

export const ghaQueueApi = createApi({
  reducerPath: 'ghaQueueApi',
  baseQuery: baseQueryWithUnAuthRetry(config.ghaQueueApiUrl),
  tagTypes: ['GHARunner'],
  endpoints: (builder) => ({
    getRunners: builder.query({
      query: () => '/runners',
      providesTags: (result = [], error, arg) => [
        'GHARunner',
        ...result.map(({ id }) => ({ type: 'GHARunner', id: id }))
      ]
    })
  })
})

export const {
  useGetRunnersQuery
} = ghaQueueApi
