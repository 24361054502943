import {
    Flex,
    Heading,
    Stack,
    Text,
} from '@chakra-ui/react'
import { useRouteError } from "react-router-dom";

export const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);
    return (
        <Flex h="100vh" direction="column" align="center" justify="center">
            <Stack spacing="5">
                <Heading>Ooops!</Heading>
                <Text>
                    An unexpected error occurred
                </Text>
                <Text color="fg.muted" fontSize="sm">
                    {error.statusText || error.message}
                </Text>
            </Stack>
        </Flex>
    )
}
