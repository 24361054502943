import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Stack,
    Switch,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'

export const LinkerdCard = ({ innerRef, ...props }) => {
  return (
    <Formik initialValues={{ "routing.exposeCrossCluster": false }} innerRef={innerRef}>
        <Box as={Form} bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" {...props}>
            <Stack
                spacing="5"
                px={{ base: "4", md: "6" }}
                py={{ base: "5", md: "6" }}
            >
                <Field name="['routing.exposeCrossCluster']">
                    {({ field }) => (
                        <FormControl>
                            <HStack spacing="3">
                                <Switch {...field} isChecked={field.value} />
                                <FormLabel>Cross Cluster</FormLabel>
                            </HStack>
                            <FormHelperText color="fg.subtle">Enable this to advertise your app across clusters through the service mesh. This allows apps in other clusters to talk directly to your app.</FormHelperText>
                        </FormControl>
                    )}
                </Field>
            </Stack>
        </Box>
    </Formik>
  )
}
