import React, { useState, useEffect } from 'react';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Flex,
    Grid,
    GridItem,
    Input,
    List,
    ListItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    UnorderedList,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Button as ChakraButton,
    VStack,
    Textarea,
    Tag,
    TagCloseButton
} from '@chakra-ui/react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { FiChevronRight, FiEye, FiEyeOff } from 'react-icons/fi'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useGetConsumerByIdQuery, useDeleteConsumerMutation, useUpdateRequestTransformerMutation, useDeleteAPIKeyMutation, useDeleteACLMutation, useCreateACLMutation, useCreateAPIKeyMutation, useGetAPIKeyByIdQuery } from '../../apis/api-key-portal'
import Code from './Code'
import { stringify } from 'yaml'
import { parse } from 'yaml'

export const ConsumerDetails = () => {
    const { id } = useParams()
    const { data: consumer, isLoading } = useGetConsumerByIdQuery(id)
    const [isOpen, setIsOpen] = useState(false)
    const [consumerToDelete, setConsumerToDelete] = useState(null)
    const [deleteConsumer] = useDeleteConsumerMutation()
    const [updateRequestTransformer] = useUpdateRequestTransformerMutation()
    const [deleteKey] = useDeleteAPIKeyMutation(); 
    const [deleteACL] = useDeleteACLMutation();
    const [createACL] = useCreateACLMutation();
    const [createKey] = useCreateAPIKeyMutation();
    const navigate = useNavigate()

    const [transformerConfig, setTransformerConfig] = useState('')
    const [editingTransformer, setEditingTransformer] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');

    const [isOpenKey, setIsOpenKey] = useState(false);
    const [keyToDelete, setKeyToDelete] = useState(null);
    const [isOpenACL, setIsOpenACL] = useState(false);
    const [aclToDelete, setAclToDelete] = useState(null);
    const [newACL, setNewACL] = useState('');
    const [newKey, setNewKey] = useState('');
    const [showKeys, setShowKeys] = useState(false);
    const [keyToFetch, setKeyToFetch] = useState(null);

    const { data: realKeyData, error } = useGetAPIKeyByIdQuery({id, keyid: keyToFetch}, { skip: !showKeys || !keyToFetch });
    console.log("Fetched key data:", realKeyData);
    if (error) {
        console.error("Error fetching API key:", error);
    }

    const handleShowKey = (keyId) => {
        console.log("Fetching key for ID:", keyId);
        if (keyToFetch === keyId) {
            setShowKeys(false);
            setKeyToFetch(null);
        } else {
            setKeyToFetch(keyId);
            setShowKeys(true);
        }
    };

    useEffect(() => {
        if (consumer && consumer.request_transformer) {
            setTransformerConfig(
                stringify(consumer.request_transformer.config, { defaultKeyType: 'PLAIN', defaultStringType: 'QUOTE_SINGLE' })
            );
        }
    }, [consumer]);

    const handleDeleteConsumer = (name) => {
        setConsumerToDelete(name)
        setIsOpen(true)
    }

    const confirmDelete = () => {
        deleteConsumer(id)
        setIsOpen(false)
        navigate("../..", { relative: "path" })
    }

    const handleDeleteKey = (keyName) => {
        setKeyToDelete(keyName);
        setIsOpenKey(true);
    }

    const handleDeleteACL = (aclName) => {
        setAclToDelete(aclName);
        setIsOpenACL(true);
    }

    const saveOrEditTransformer = () => {
        if (!editingTransformer) {
            setEditingTransformer(true);
        } else {
            try {
                const configObject = parse(transformerConfig);
                if (Object.keys(configObject).length > 0) {
                    updateRequestTransformer({ id, body: configObject });
                    setErrorMessage('');
                    setEditingTransformer(false);
                } else {
                    setErrorMessage("Transformer config cannot be empty.");
                }
            } catch (error) {
                console.error("Failed to parse transformer config:", error);
                setErrorMessage("Invalid YAML format. Please check your configuration.");
            }
        }
    }

    const confirmDeleteKey = () => {
        deleteKey({ id, keyid: keyToDelete });
        setIsOpenKey(false);
    }

    const confirmDeleteACL = () => {
        deleteACL({ id, aclid: aclToDelete });
        setIsOpenACL(false);
    }

    const handleAddACL = () => {
        if (newACL) {
            createACL({ id, body: { group: newACL } });
            setNewACL('');
        }
    };

    const handleAddKey = () => {
        if (newKey) {
            createKey({ id, body: { name: newKey } });
            setNewKey('');
        }
    };

    return (
        <Box px={{ base: '4', md: '6' }} py="5">
            <Breadcrumb
            spacing='8px'
            separator={<FiChevronRight color='gray.500' />}
            >
            <BreadcrumbItem>
                <BreadcrumbLink as={Link} to='..'>
                <Button variant='text'>Consumers</Button>
                </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{id}</BreadcrumbLink>
            </BreadcrumbItem>
            </Breadcrumb>

            <Flex mt={5} justifyContent='space-between'>
            <Text fontSize="2xl" fontWeight="bold">
                {isLoading ? 'Details' : consumer.name}
            </Text>
            </Flex>

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <Box mt={5}>
                    <Grid templateColumns='repeat(2, 1fr)' mt={5}>
                        <Grid templateColumns='repeat(2, 1fr)' mt={5}>
                            <GridItem><Text align='right' pr={5} fontWeight="bold">Cluster</Text></GridItem><GridItem><Text>{consumer.cluster}</Text></GridItem>
                            <GridItem><Text align='right' pr={5} fontWeight="bold">Name</Text></GridItem><GridItem><Text>{consumer.name}</Text></GridItem>
                            <GridItem><Text align='right' pr={5} fontWeight="bold">Namespace</Text></GridItem><GridItem><Text>{consumer.namespace}</Text></GridItem>
                            <GridItem><Text align='right' pr={5} fontWeight="bold">Description</Text></GridItem><GridItem><Text>{consumer.description}</Text></GridItem>
                            <GridItem><Text align='right' pr={5} fontWeight="bold">Figure Contact</Text></GridItem><GridItem><Text>{consumer.internalContact}</Text></GridItem>
                            <GridItem><Text align='right' pr={5} fontWeight="bold">Third Party Contact</Text></GridItem><GridItem><Text>{consumer.externalContact}</Text></GridItem>
                        </Grid>
                        <VStack>
                            <Text fontWeight="bold">ACLs</Text>
                            <List>
                                {consumer.acls.map((acl) => (
                                    <ListItem key={acl.name}>
                                        <Tag variant="solid" colorScheme="gray" mr={2} mb={2}>
                                            {acl.group}
                                            <TagCloseButton 
                                                onClick={() => handleDeleteACL(acl.name)} 
                                            />
                                        </Tag>
                                    </ListItem>
                                ))}
                                <ListItem>
                                    <Tag>
                                        <Input 
                                            value={newACL} 
                                            onChange={(e) => setNewACL(e.target.value)} 
                                            placeholder="New ACL Name" 
                                            size="sm" 
                                            variant="filled" 
                                        />
                                        <Button 
                                            colorScheme="blue" 
                                            onClick={handleAddACL} 
                                            ml={2}
                                            isDisabled={!newACL}
                                        >Add ACL</Button>
                                    </Tag>
                                </ListItem>
                            </List>
                        </VStack>
                    </Grid>
                    
                    <List mt={5} spacing={5}>
                        <Text align='left' fontWeight="bold">Keys</Text>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Key</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {consumer.keys.map((key) => (
                                    <Tr key={key.name}>
                                        <Td>{key.name}</Td>
                                        <Td style={{ width: '700px' }}>
                                            <Button 
                                                colorScheme="blue" 
                                                onClick={() => handleShowKey(key.name)}
                                                mr={3}
                                                >
                                                {showKeys && keyToFetch === key.name ? <FiEyeOff /> : <FiEye />}
                                            </Button>
                                            {showKeys && keyToFetch === key.name ? realKeyData?.key : key.key}
                                        </Td>
                                        <Td>
                                            <Button colorScheme="red" onClick={() => handleDeleteKey(key.name)}>Delete</Button>
                                        </Td>
                                    </Tr>
                                ))}
                                <Tr>
                                    <Td>
                                        <Input 
                                            value={newKey} 
                                            onChange={(e) => setNewKey(e.target.value)} 
                                            placeholder="New Key Name" 
                                        />
                                    </Td>
                                    <Td></Td> {/* Empty cell for alignment */}
                                    <Td>
                                        <Button 
                                            colorScheme='blue'
                                            onClick={handleAddKey}
                                            isDisabled={!newKey}
                                        >Add Key</Button>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </List>
                    
                    <List mt={5} spacing={5}>
                        <Text align='left' fontWeight="bold">Transformer</Text>
                        {editingTransformer ?
                        <VStack>
                            <Textarea
                            id="config"
                            name="config"
                            size="lg"
                            minHeight="300px"
                            placeholder="Config as YAML"
                            value={transformerConfig}
                            onChange={(event) => { 
                                setTransformerConfig(event.target.value);
                                setErrorMessage(''); 
                            }}
                            />
                            <UnorderedList>
                            <ListItem>
                                <Text>Headers and query strings in the add section must be in key:value form.</Text>
                            </ListItem>
                            <ListItem>
                                <Text>Make sure to surround all items in the headers and querystring lists with quotes in both add and remove sections.</Text>
                            </ListItem>
                            </UnorderedList>
                            {errorMessage && (
                                <Text color='red.500' mt={2}>{errorMessage}</Text>
                            )}
                        </VStack>
                        :
                        <Box border="1px" borderColor="gray.300" borderRadius="md" p={2}>
                            <Code language="yaml" code={transformerConfig} />
                        </Box>
                        }
                    </List>
                </Box>
            )}
            <Grid templateColumns='repeat(2, 1fr)' mt={4}>
                <GridItem>
                    <Button colorScheme="blue" onClick={saveOrEditTransformer}>
                        {editingTransformer ? 'Save Transformer' : 'Edit Transformer'}
                    </Button>
                </GridItem>
                <GridItem>
                    <Flex justifyContent="flex-end">
                        <Button colorScheme="red" onClick={() => handleDeleteConsumer(consumer.name)} mt={4}>
                            Delete Consumer
                        </Button>
                    </Flex>
                </GridItem>
            </Grid>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete this Consumer?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        This will permanently delete the {consumerToDelete} consumer and it will instantly stop working.
                    </ModalBody>
                    <ModalFooter>
                        <ChakraButton colorScheme="red" onClick={confirmDelete}>
                            Delete
                        </ChakraButton>
                        <ChakraButton onClick={() => setIsOpen(false)} ml={3}>
                            Cancel
                        </ChakraButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenKey} onClose={() => setIsOpenKey(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete this Key?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        This will permanently delete the {keyToDelete} key and it will instantly stop working.
                    </ModalBody>
                    <ModalFooter>
                        <ChakraButton colorScheme="red" onClick={confirmDeleteKey}>
                            Delete
                        </ChakraButton>
                        <ChakraButton onClick={() => setIsOpenKey(false)} ml={3}>
                            Cancel
                        </ChakraButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenACL} onClose={() => setIsOpenACL(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete this ACL?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        This will permanently delete the {aclToDelete} ACL and it will instantly stop working.
                    </ModalBody>
                    <ModalFooter>
                        <ChakraButton colorScheme="red" onClick={confirmDeleteACL}>
                            Delete
                        </ChakraButton>
                        <ChakraButton onClick={() => setIsOpenACL(false)} ml={3}>
                            Cancel
                        </ChakraButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}
