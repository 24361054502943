import { useMemo } from 'react'
import {
    Box,
    HStack,
    Icon,
    Text,
    Tooltip,
} from '@chakra-ui/react'
import { FiAlertTriangle, FiChevronRight } from 'react-icons/fi'
import { iconPropsFromStatus } from '../../utils/status'
import { useGetBackupsQuery, useGetDatabasesQuery } from '../../apis/database-builder'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'

export const Backups = () => {
    const { data: backups = [], isLoading: isLoadingBackups } = useGetBackupsQuery()
    const { data: databases = [], isLoading: isLoadingDatabases } = useGetDatabasesQuery()

    const columns = useMemo(
        () => [
            {
                Header: 'Database Name',
                accessor: (row, _) => databases.find(i => i.id === row.database_id)?.name || 'unknown',
                id: 'name',
            },
            {
                Header: 'Backup ID',
                accessor: 'id'
            },
            {
                Header: 'Started Timestamp',
                accessor: (row, _) => new Date(row.started_at * 1000).toLocaleString(),
                id: 'started_at'
            },
            {
                Header: 'Completed Timestamp',
                accessor: (row, _) => row.completed_at? new Date(row.completed_at * 1000).toLocaleString() : "",
                id: 'completed_at'
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ row }) => {
                    return <HStack>
                        <Icon {...iconPropsFromStatus(row.original.status)} />
                        <Text>{row.original.status}</Text>
                        {row.original.error && (
                            <Tooltip label='An error occurred processing this request. Click for more details'>
                                <Box>
                                    <FiAlertTriangle color='yellow' />
                                </Box>
                            </Tooltip>
                        )}
                    </HStack>
                },
                id: 'status',
            },
            {
                Header: '',
                Cell: () => <Icon as={FiChevronRight} />,
                id: 'details_link',
            }
        ],
        [databases])

    return (
        isLoadingBackups || isLoadingDatabases ? (
            <LoadingSpinner />
        ) : (
            <DataTable
                data={backups}
                columns={columns}
                title='Backups'
                sortByField='started_at'
                sortDesc={true}
            />
        )
    )
}
