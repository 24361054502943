import { useEffect } from 'react'
import { Box, Container, Flex, Heading, Spinner, Stack, useBreakpointValue } from '@chakra-ui/react'
import { useOktaAuth } from '@okta/okta-react'
import { Logo } from '../../components/Logo'
import { gradientBackgroundDark } from '../../utils/color'

export const Logout = () => {
  const { oktaAuth } = useOktaAuth()

  useEffect(() => {
    oktaAuth.signOut('/logout/success')
  })

  return (
    <Box bgGradient={{ sm: gradientBackgroundDark }} h='100vh' w='100vw' py={{ base: '12', md: '24' }}>
      <Flex h='full' w='full' align='center'>
          <Container
              maxW="md"
              py={{ base: '0', sm: '8' }}
              px={{ base: '4', sm: '10' }}
              bg={useBreakpointValue({ base: 'transparent', sm: 'bg.surface' })}
              boxShadow={{ base: 'none', sm: 'xl' }}
              borderRadius={{ base: 'none', sm: 'xl' }}
              >
              <Stack spacing="8">
                  <Stack spacing="6" align="center">
                      <Logo />
                      <Stack spacing="3" textAlign="center">
                          <Heading size="xs">Logging you out... <Spinner /></Heading>
                      </Stack>
                  </Stack>
              </Stack>
          </Container>
      </Flex>
    </Box>
  )
}
